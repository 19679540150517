/*
  Usage:
    src/ui/TheNavigation.vue
    src/features/workspace/Workplace.vue
    src/features/weekly/components/DayColumn.vue
*/
import moment from "moment";

export function calculateWorkplaceRouterPath(existingWeek?: number, existingDay?: string) {
  const week = existingWeek ? Math.abs(existingWeek) : moment().weeks();
  const day = existingDay ? "/" + existingDay : "";
  return "/workplace/" + week + day;
}
