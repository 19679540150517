import { BookingDay, BookingDayPosition } from "@/data-types";
import moment from "moment";

function chooseState(entry: BookingDay) {
  if (entry.error) {
    entry.fixed = entry.error;
  } else if (entry.booked && entry.state === "Absent") {
    entry.fixed = "Als abwesend gebucht";
  } else if (entry.state === "On Leave") {
    entry.fixed = "Urlaub";
  } else if (entry.state === "Sick") {
    entry.fixed = "Krank " + entry.totalWorkHours.toFixed(2) + "h";
  } else if (entry.state === "Future") {
    entry.fixed = "Zukünftige Tage nicht buchbar";
  }
}

export function prepareWeek(entries: BookingDay[]): BookingDay[] {
  const bps = [...new Set(entries.flatMap((x: BookingDay) => x.positionHours).map((x: BookingDayPosition) => x.name))];
  for (const entry of entries) {
    entry.currentDate && (entry.date = new Date(entry.currentDate));
    chooseState(entry);

    const newHoursList = [];
    for (const bpName of bps) {
      const hourEntry = entry.positionHours.find((x: any) => x.name == bpName);
      if (hourEntry) newHoursList.push(hourEntry);
      else
        newHoursList.push({
          name: bpName,
          hours: "00:00",
          comment: "",
        });
    }
    entry.positionHours = newHoursList;
  }
  return entries;
}

export function getColorOfActiveButton(active: boolean, value: number) {
  if (active && value === 5) return "var(--pink)";
  else if (active) return "var(--primary)";
  else return "var(--gray-500)";
}

// Helper.ts
export function calculateDayHash(date: Date | undefined) {
  return moment(date).format("yyyy-MM-DD");
}

export function randomInRange(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

export function isBookingDayRelevant(bookingDay: BookingDay): boolean {
  const { state, booked } = bookingDay;
  const isDaySickOrInFuture = state === "On Leave" || state === "Future";
  if (isDaySickOrInFuture) return false;
  return !booked || state !== "Absent";
}
