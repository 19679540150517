<template>
  <BaseCard>
    <ProgressSpinner v-if="isLoading" class="p-d-flex p-jc-center" />
    <div v-if="!isLoading" class="visitor-container">
      <div class="visitor-header">
        <div v-if="visitorLog.length <= 0" id="no-visitors">Bisher keine Besucher</div>
        <div v-else id="visitor" class="infoHeader">Besucher im Büro</div>
        <Button @click="isPreregisterDialogVisible = !isPreregisterDialogVisible">Vorab anmelden</Button>
        <DialogPreregisterVisitor
          :dialog-visibility="isPreregisterDialogVisible"
          @change-dialog-visibility="isPreregisterDialogVisible = $event"
          @pre-registered-successfully="showPreRegisterSuccess"
          @pre-register-failed="showPreRegisterError"
        ></DialogPreregisterVisitor>
      </div>
      <DataTable v-if="visitorLog.length > 0" :value="visitorLog" class="visitor-table" scrollable>
        <Column field="date" header="Datum" sortable>
          <template #body="slotProps">
            {{ convertDateToGerDateString(convertDateStringToDate(slotProps.data.date)) }}
          </template>
        </Column>
        <Column field="date" header="Start">
          <template #body="slotProps">
            {{ convertDateToGerTimeString(convertDateStringToDate(slotProps.data.date)) }}
          </template>
        </Column>
        <Column field="visitor_name" header="Name" sortable />
        <Column field="company_name" header="Firma" sortable />
        <Column field="email" header="E-Mail" />
        <Column field="phone" header="Telefon" />
        <Column field="reason" header="Grund" />
        <Column field="left" header="An-/Abmeldung">
          <template #body="slotProps">
            <span v-if="slotProps.data.left">
              {{ convertDateToGerDateString(convertDateStringToDate(slotProps.data.left)) }}
              {{ convertDateToGerTimeString(convertDateStringToDate(slotProps.data.left)) }}
            </span>
            <div v-else-if="slotProps.data.registration_code">
              <InputGroup>
                <InputGroupAddon v-tooltip="'Anmeldecode des Besuchenden zur Registrierung'" class="code-display">
                  {{ slotProps.data.registration_code }}
                </InputGroupAddon>
                <Button
                  v-tooltip="'Anmeldecode in die Zwischenablage kopieren'"
                  class="code-copy"
                  icon="pi pi-copy"
                  outlined
                  type="button"
                  @click="copyCodeToClipboard(slotProps.data.registration_code)"
                />
              </InputGroup>
            </div>
            <Button v-else class="logout-button" @click="initLogoutSequence(slotProps.data)">
              <span>Abmelden</span>
            </Button>
          </template>
        </Column>
      </DataTable>
      <base-pop-up v-if="entryToConfirm" :pop-up-message="`Den Besucher ${entryToConfirm.visitor_name} abmelden?`">
        <template #content>
          <div class="base-pop-up-content">
            <span>Zeitpunkt der Abmeldung:</span>
            <TimePicker v-model="timeString" :autofocus="true" :can-clear="true" :disabled="false" class="time-input" />
          </div>
        </template>
        <Button outlined @click="entryToConfirm = undefined">Nein</Button>
        <Button id="confirm-button" @click="confirmVisitorLogout">Ja</Button>
      </base-pop-up>
    </div>
  </BaseCard>
</template>

<script lang="ts">
import BaseCard from "@/ui/BaseCard.vue";
import { erpNextVisitorLog } from "@/data-types";
import { useToast } from "primevue/usetoast";
import BasePopUp from "@/ui/BasePopUp.vue";
import { defineComponent, ref, watch } from "vue";
import ProgressSpinner from "primevue/progressspinner";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import { showErrorToast, showSuccessToast } from "@/utils/Toast";
import {
  calculateMinDate,
  convertDateStringToDate,
  convertDateToGerDateString,
  convertDateToGerTimeString,
  dateRange,
  formatToLocalDate,
  isCurrentDateInRange,
} from "@/features/visitor/utils/visitor-util";
import TimePicker from "@/ui/TimePicker.vue";
import DialogPreregisterVisitor from "@/features/visitor/components/DialogPreregisterVisitor.vue";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import erpnextApi from "@/rest/erpnext-api";

export default defineComponent({
  name: "VisitorLog",
  components: {
    BaseCard,
    BasePopUp,
    Button,
    Column,
    DataTable,
    DialogPreregisterVisitor,
    InputGroup,
    InputGroupAddon,
    ProgressSpinner,
    TimePicker,
  },
  setup() {
    const toast = useToast();
    const visitorLog = ref<erpNextVisitorLog[]>([]);
    const isLoading = ref(true);
    const entryToConfirm = ref<erpNextVisitorLog>();
    const datetime24h = ref<Date>();
    const isPreregisterDialogVisible = ref(false);
    const timeString = ref<string>("");

    watch(
      timeString,
      (newTimeString) => {
        if (!newTimeString) {
          datetime24h.value = undefined;
          return;
        }
        const [hours, minutes] = newTimeString.split(":").map(Number);
        if (isNaN(hours) || isNaN(minutes) || !entryToConfirm.value) return;
        const newTimestamp = new Date().setHours(hours, minutes);
        if (!isCurrentDateInRange(newTimestamp, entryToConfirm.value.date)) {
          datetime24h.value = new Date();
          timeString.value = convertDateToGerTimeString(datetime24h.value);
        } else datetime24h.value = new Date(newTimestamp);
      },
      { flush: "post" },
    );

    function initLogoutSequence(_erpNextVisitorLog: erpNextVisitorLog) {
      datetime24h.value = undefined;
      timeString.value = "";
      entryToConfirm.value = _erpNextVisitorLog;
    }

    function getVisitorLogs() {
      isLoading.value = true;
      erpnextApi
        .getVisitorLogs()
        .then((res: erpNextVisitorLog[]) => {
          visitorLog.value = res;
          isLoading.value = false;
        })
        .catch((err: Error) => showErrorToast(toast, err.message));
    }

    async function confirmVisitorLogout() {
      if (!entryToConfirm.value) {
        showErrorToast(toast, "Fehler: Keinen Besucher ausgewählt!");
        return;
      }
      isLoading.value = true;
      if (typeof entryToConfirm.value.date === "string") entryToConfirm.value.date = convertDateStringToDate(entryToConfirm.value.date);
      if (!isCurrentDateInRange(datetime24h.value, entryToConfirm.value.date)) datetime24h.value = undefined;
      await erpnextApi
        .logoutVisitor(entryToConfirm.value.name, formatToLocalDate(datetime24h.value))
        .then(() => {
          showSuccessToast(toast, "Besucher erfolgreich abgemeldet");
          getVisitorLogs();
        })
        .catch((err: Error) => showErrorToast(toast, err.message))
        .finally(() => {
          isLoading.value = false;
          entryToConfirm.value = undefined;
        });
    }

    function showPreRegisterSuccess(toastMsg: string) {
      isPreregisterDialogVisible.value = false;
      showSuccessToast(toast, toastMsg);
      getVisitorLogs();
    }

    function showPreRegisterError(toastMsg: string) {
      showErrorToast(toast, toastMsg);
    }

    getVisitorLogs();

    function copyCodeToClipboard(code: string) {
      navigator.clipboard.writeText(code);
      showSuccessToast(toast, `Code "${code}" in die Zwischenablage kopiert`);
    }

    return {
      calculateMinDate,
      confirmVisitorLogout,
      convertDateStringToDate,
      convertDateToGerDateString,
      convertDateToGerTimeString,
      copyCodeToClipboard,
      dateRange,
      datetime24h,
      entryToConfirm,
      getVisitorLogs,
      initLogoutSequence,
      isLoading,
      isPreregisterDialogVisible,
      showPreRegisterError,
      showPreRegisterSuccess,
      timeString,
      visitorLog,
    };
  },
});
</script>

<style scoped>
.visitor-container {
  overflow: auto;
}

.visitor-header {
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.infoHeader {
  font-size: 18px;
}

.code-display {
  flex: 1;
  color: var(--white);
  border-color: var(--borderColor);
}

.code-copy {
  border-color: var(--borderColor) !important;
}

.logout-button {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.base-pop-up-content {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 12px;
}

.time-input {
  padding: 11px;
  border-radius: var(--border-radius);
  border: 1px solid;

  &:focus-visible {
    outline: unset;
  }
}
</style>
