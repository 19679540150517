<template>
  <div class="title">
    <div class="todayDate">
      <p>{{ getTodaysDateFormatted() }}</p>
    </div>
  </div>
  <div class="widgetContainer">
    <div v-if="!isExternal">
      <mood-widget />
    </div>
    <div v-if="!isExternal">
      <office-stat-widget />
    </div>
    <div>
      <office-widget />
    </div>
    <div>
      <day-booking-widget />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import OfficeWidget from "./components/OfficeWidget.vue";
import DayBookingWidget from "./components/DayBookingWidget.vue";
import MoodWidget from "./components/MoodWidget.vue";
import OfficeStatWidget from "./components/OfficeStatWidget.vue";
import { BookingPositionDescription } from "@/data-types";
import { useStore } from "vuex";
import { getTodaysDateFormatted } from "@/features/startpage/utils/StartPage";

export default defineComponent({
  name: "StartPage",
  components: { OfficeWidget, DayBookingWidget, MoodWidget, OfficeStatWidget },
  setup() {
    const store = useStore();

    const bookingPositions = ref(new Map<string, BookingPositionDescription>());
    const isExternal = computed(() => store.getters.isExternal);
    {
      return {
        getTodaysDateFormatted,
        bookingPositions,
        isExternal,
      };
    }
  },
});
</script>

<style scoped>
.title {
  display: flex;
  justify-content: center;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: var(--dark);
  margin-left: 1rem;
  margin-right: 1rem;
}

.todayDate {
  font-size: 35px;
  color: var(--primary);
}

.widgetContainer {
  display: flex;
  flex-direction: row;
}

.officeWidgetBaseWidget {
  height: 150px;
}

@media (max-width: 700px) {
  .widgetContainer {
    display: flex;
    flex-direction: column;
  }

  .todayDate {
    font-size: 25px;
  }
}
</style>
