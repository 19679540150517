<template>
  <div class="card container">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseCard",
});
</script>

<style scoped>
.card {
  border-radius: 3px;
  padding: 1rem 0.75rem;
  border: 1px solid var(--cardBorderBolor);
  background-color: var(--dark);
  min-height: auto;
}
</style>
