<template>
  <base-widget v-if="!isDayAlreadyBooked">
    <ProgressSpinner v-if="!loadedContent" class="p-d-flex p-jc-center" />
    <div v-if="workplaceBookings && loadedContent" class="dayBookingWidget">
      <div class="daytitle">Tagesbuchung</div>
      <div class="timeBooking">
        <div class="text">Start</div>
        <div>
          <TimePicker v-model="startTimeValue" :disabled="isDisabled" :autofocus="isCurrentDay" :can-clear="true" />
        </div>
        <div>
          <Button v-if="!isMobile" @click="fillInCurrentStartTime"> Aktuelle Zeit nehmen</Button>
          <Button v-else icon="pi pi-clock" @click="fillInCurrentStartTime" />
        </div>
      </div>
      <div class="timeBooking">
        <div class="text">Pausenbeginn</div>
        <div>
          <TimePicker v-model="breakStartTimeValue" :disabled="isDisabled" :autofocus="isCurrentDay" :can-clear="true" />
        </div>
        <div>
          <Button v-if="!isMobile" @click="fillInCurrentBreakStartTime"> Aktuelle Zeit nehmen</Button>
          <Button v-else icon="pi pi-clock" @click="fillInCurrentBreakStartTime" />
        </div>
      </div>
      <div class="timeBooking">
        <div class="text">Pausenende</div>
        <div>
          <TimePicker v-model="breakEndTimeValue" :disabled="isDisabled" :autofocus="isCurrentDay" :can-clear="true" />
        </div>
        <div>
          <Button v-if="!isMobile" @click="fillInCurrentBreakEndTime"> Aktuelle Zeit nehmen</Button>
          <Button v-else icon="pi pi-clock" @click="fillInCurrentBreakEndTime" />
        </div>
      </div>

      <div class="timeBooking">
        <div class="text">Ende</div>
        <div>
          <TimePicker v-model="endTimeValue" :disabled="isDisabled" :autofocus="isCurrentDay" :can-clear="true" />
        </div>
        <div>
          <Button v-if="!isMobile" @click="fillInCurrentEndTime"> Aktuelle Zeit nehmen</Button>
          <Button v-else icon="pi pi-clock" @click="fillInCurrentEndTime" />
        </div>
      </div>
    </div>
  </base-widget>
</template>

<script lang="ts">
import { computed, onMounted, ref } from "vue";
import { BookingDay, erpNextUserProfile } from "@/data-types";
import erpnextApi from "@/rest/erpnext-api";
import TimePicker from "@/ui/TimePicker.vue";
import BaseWidget from "@/ui/BaseWidget.vue";
import ProgressSpinner from "primevue/progressspinner";
import { useStore } from "vuex";
import { buildTimeString, roundTime, splitTimeString } from "@/utils/Helper";
import { onBeforeRouteLeave } from "vue-router";
import Button from "primevue/button";
import { createTimeStringFromDate } from "@/features/startpage/utils/StartPage";

export default {
  name: "DayBookingWidget",
  components: { TimePicker, BaseWidget, ProgressSpinner, Button },
  setup() {
    const store = useStore();
    const userProfile = computed((): erpNextUserProfile => store.getters.getUserProfile);
    const startTimeValue = computed({
      get: () => store.getters.getStartTimeValue,
      set: (value) => store.dispatch("setStartTimeValue", userProfile.value.calculate_end_of_working_day ? roundTime(value) : value),
    });
    const endTimeValue = computed({
      get: () => store.getters.getEndTimeValue,
      set: (value) => store.dispatch("setEndTimeValue", userProfile.value.calculate_end_of_working_day ? roundTime(value) : value),
    });
    const breakStartTimeValue = computed({
      get: () => store.getters.getBreakStartTimeValue,
      set: (value) => store.dispatch("setBreakStartTimeValue", userProfile.value.calculate_end_of_working_day ? roundTime(value) : value),
    });
    const breakEndTimeValue = computed({
      get: () => store.getters.getBreakEndTimeValue,
      set: (value) => store.dispatch("setBreakEndTimeValue", userProfile.value.calculate_end_of_working_day ? roundTime(value) : value),
    });
    const breakTime = computed({
      get: () => store.getters.getBreakTime,
      set: (value) => store.dispatch("setBreakTime", userProfile.value.calculate_end_of_working_day ? roundTime(value) : value),
    });
    const today = new Date();
    const isDisabled = false;
    const isCurrentDay = false;
    const workplaceBookings = ref<BookingDay[]>();
    const loadedContent = ref(false);
    const isDayAlreadyBooked = ref(false);
    const currentTime = createTimeStringFromDate(today);
    const width = window.innerWidth;
    const isMobile = ref(false);
    onMounted(() => {
      if (width < 1400) {
        isMobile.value = true;
      }
      const startTime = localStorage.getItem("startTimeValue");
      const breakStartTime = localStorage.getItem("breakStartTimeValue");
      const breakEndTime = localStorage.getItem("breakEndTimeValue");
      const endTime = localStorage.getItem("endTimeValue");
      endTimeValue.value = endTime;
      breakStartTimeValue.value = breakStartTime;
      breakEndTimeValue.value = breakEndTime;
      startTimeValue.value = startTime;
    });

    function fillInCurrentStartTime() {
      startTimeValue.value = currentTime;
    }

    function fillInCurrentEndTime() {
      endTimeValue.value = currentTime;
    }

    function fillInCurrentBreakStartTime() {
      breakStartTimeValue.value = currentTime;
    }

    function fillInCurrentBreakEndTime() {
      breakEndTimeValue.value = currentTime;
    }

    function calcBreakTime() {
      const breakStartHours = ref(0);
      const breakStartMinutes = ref(0);
      const breakEndHours = ref(0);
      const breakEndMinutes = ref(0);
      const calcHours = ref(0);
      const calcMinutes = ref(0);
      const breakStart = splitTimeString(store.getters.getBreakStartTimeValue);
      const breakEnd = splitTimeString(store.getters.getBreakEndTimeValue);

      breakStartHours.value += breakStart.hours;
      breakStartMinutes.value += breakStart.minutes;
      breakEndHours.value += breakEnd.hours;
      breakEndMinutes.value += breakEnd.minutes;

      if (store.getters.getBreakStartTimeValue && store.getters.getBreakEndTimeValue) {
        calcHours.value = breakEndHours.value - breakStartHours.value;
        calcMinutes.value = breakEndMinutes.value - breakStartMinutes.value;
      }
      return buildTimeString(calcHours.value, calcMinutes.value);
    }

    if (import.meta.env.NODE_ENV !== "test") {
      onBeforeRouteLeave((to, from, next) => {
        if (breakStartTimeValue.value && breakEndTimeValue.value) {
          breakTime.value = calcBreakTime();
        }
        next();
      });
    }

    async function getToday() {
      await erpnextApi.getTimesheetEntriesBetween(new Date(), 1).then((res) => {
        workplaceBookings.value = res;
        loadedContent.value = true;
        if (workplaceBookings.value[0].totalWorkHours) {
          isDayAlreadyBooked.value = true;
        }
      });
    }

    getToday();

    return {
      isDisabled,
      isCurrentDay,
      workplaceBookings,
      loadedContent,
      startTimeValue,
      breakStartTimeValue,
      breakEndTimeValue,
      currentTime,
      fillInCurrentStartTime,
      fillInCurrentBreakStartTime,
      fillInCurrentBreakEndTime,
      isDayAlreadyBooked,
      isMobile,
      fillInCurrentEndTime,
      endTimeValue,
    };
  },
};
</script>

<style scoped>
.dayBookingWidget {
  margin: 1rem;
}

.timeBooking {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  padding: 10px;
  justify-content: space-between;
  background-color: var(--greystep);
  border-radius: 5px;
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px; */
}

.time-input {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  min-width: 60%;
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  display: flex;
  font-size: 16px;
}

.text {
  font-size: 18px;
  color: var(--primary);
  display: flex;
  align-items: center;
  width: 130px;
}

.daytitle {
  font-size: 24px;
  margin-bottom: 1rem;
}

.time-input:focus {
  outline: 2px solid var(--primary);
  width: calc(100%);
}

@media (max-width: 700px) {
  .daytitle {
    font-size: 20px;
  }
}
</style>
