<template>
  <div class="weeklyViewport">
    <WeekControl
      id="weekControl"
      title="Zeiterfassung"
      :year="erpWeek[0]?.date?.getFullYear()"
      :week="calendarWeek"
      :disable-previous-week="disablePreviousWeekButton"
      :disable-next-week="disableNextWeekButton"
      :is-swipe-deactivated="isSwipeDeactivated"
      @change-week-backward="changeWeekBackward"
      @change-week-forward="changeWeekForward"
      @change-week="changeWeek"
      @touchstart.passive="activateSwipe"
    ></WeekControl>
    <div class="flexBoxingWeekly">
      <weekly-table
        v-if="loadedContent"
        :days="erpWeek"
        :booking-positions="bookingPositions"
        :booking-data="bookingData"
        @touchstart.passive="deactivateSwipe"
      ></weekly-table>

      <ProgressSpinner v-if="!loadedContent" class="p-d-flex p-jc-center" />
      <div class="flexBoxingWeeklyBudget">
        <left-budget v-if="loadedContent && !isExternal" :days="currentMonthData" :booking-positions="bookingPositions" @touchstart.passive="activateSwipe">
        </left-budget>
        <current-budget v-if="loadedContent && !isExternal" :annual-statement="annualStatement as any" @touchstart.passive="activateSwipe"></current-budget>
        <monthly-total
          v-if="loadedContent"
          :booking-positions="bookingPositions"
          :month-data="currentMonthData"
          @touchstart.passive="activateSwipe"
        ></monthly-total>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AnnualHours, BookingDay, BookingPositionDescription } from "@/data-types";
import CurrentBudget from "@/features/weekly/components/CurrentBudget.vue";
import LeftBudget from "@/features/weekly/components/LeftBudget.vue";
import MonthlyTotal from "@/features/weekly/components/MonthlyTotal.vue";
import WeeklyTable from "@/features/weekly/components/WeeklyTable.vue";
import { calculateDayHash, prepareWeek } from "@/features/weekly/utils/Weekly";
import { week } from "@/keys";
import erpnextApi from "@/rest/erpnext-api";
import store from "@/store";
import WeekControl from "@/ui/WeekControl.vue";
import moment from "moment";
import ProgressSpinner from "primevue/progressspinner";
import { computed, provide, reactive, Ref, ref } from "vue";

const today = moment();
const bookingData = ref(new Map<string, BookingDay>());
const currentMonthData = reactive([] as BookingDay[]);
const calendarWeek = ref();
provide(week, calendarWeek);
const annualStatement = ref<AnnualHours>();

const bookingPositions = ref(new Map<string, BookingPositionDescription>());
const erpWeek = ref<BookingDay[]>([]);
const changeWeekMultiplier = ref(0);

const loadedContent = ref(false);
const disableNextWeekButton = ref(true);
const disablePreviousWeekButton = ref(true);
const isSwipeDeactivated = ref(false);
const isExternal = computed(() => store.getters.isExternal);
store.dispatch("fetchTransportationProfiles");

const loadAnnualStatement = async () => {
  annualStatement.value = await erpnextApi.getAnnualHours(today.year());
};

function deactivateSwipe() {
  isSwipeDeactivated.value = true;
}

function activateSwipe() {
  isSwipeDeactivated.value = false;
}

/**
 * Init method of the vue class.
 */
const initializeCurrentWeek = async () => {
  const currentMonday = today.toDate();
  let offset = currentMonday.getDay() - 1;
  if (offset == -1) offset = 6;
  currentMonday.setDate(currentMonday.getDate() - offset - 14);
  await loadBookingPositionNames();
  await loadMissingBookingDescriptions();
  await loadTimesheetEntriesStartingWith(currentMonday, 21);
  await loadAnnualStatement();

  // No await, as we do not want to wait for this update-call
  // noinspection ES6MissingAwait
  updateData();
};

/**
 * Load the booking positions from the erp api
 */
const loadBookingPositionNames = async () => {
  const positions = await erpnextApi.getAvailableBookingPositions();
  for (const position of positions) {
    if (!bookingPositions.value.has(position.name)) {
      bookingPositions.value.set(position.name, {
        number: "",
        closed: false,
        description: "",
        fullname: "",
        has_comment: false,
        left_hours: 0,
        name: position.name,
        project: "",
        requires_comment: false,
        total_hours: 0,
        used_hours: 0,
        missing: true,
        changeable_from: null,
        changeable_till: null,
      });
    }
  }
};

/**
 * This method is used to load the timesheet entries of the month from the backend and sort
 * the booking positions
 *
 * @param startDate for the timesheet entries
 * @param days how many days shall be loaded from the start date
 */
async function loadTimesheetEntriesStartingWith(startDate: Date, days = 7) {
  const timesheetEntries = await erpnextApi.getTimesheetEntriesBetween(startDate, days);
  const week = prepareWeek(timesheetEntries);

  for (const entry of week) {
    entry.positionHours.sort((x, y) => {
      const bpX = bookingPositions.value.get(x.name);
      const bpY = bookingPositions.value.get(y.name);

      if (bpX && bpY) {
        // Sort external projects before internal projects
        if (!bpX.project.startsWith("Intern") && bpY.project.startsWith("Intern")) return -1;
        if (bpX.project.startsWith("Intern") && !bpY.project.startsWith("Intern")) return 1;

        // Sort alphabetically by the project name
        if (bpX.project < bpY.project) return -1;
        if (bpX.project > bpY.project) return 1;

        // Sort alphabetically by the booking position
        if (bpX.description < bpY.description) return -1;
        if (bpX.description > bpY.description) return 1;
      }
      return 0;
    });
    bookingData.value.set(calculateDayHash(entry.date), entry);
  }
}

/**
 * This function is used to load the details of the booking descriptions and store them into bookingPositions
 */
async function loadMissingBookingDescriptions() {
  const missingDescriptions = [];
  for (const desc of bookingPositions.value.values()) {
    if (desc.missing) {
      missingDescriptions.push(desc.name);
    }
  }

  /* If there are no missing descriptions return */
  if (missingDescriptions.length == 0) return;

  const descriptions = await erpnextApi.getBookingPositionDescriptions(missingDescriptions);
  for (const description of descriptions) {
    const existingEntry = bookingPositions.value.get(description.name);
    if (existingEntry) {
      existingEntry.project = description.project;
      existingEntry.closed = description.closed;
      existingEntry.has_comment = description.has_comment;
      existingEntry.description = description.description;
      existingEntry.left_hours = description.left_hours;
      existingEntry.requires_comment = description.requires_comment;
      existingEntry.total_hours = description.total_hours;
      existingEntry.used_hours = description.used_hours;
      existingEntry.changeable_from = description.changeable_from;
      existingEntry.changeable_till = description.changeable_till;

      const seperatedName = existingEntry.name.split("-");
      existingEntry.number = seperatedName[0] + "-" + seperatedName[1];
      existingEntry.fullname = existingEntry.project + ": " + existingEntry.description;
      existingEntry.missing = false;
    } else {
      description.missing = false;
      bookingPositions.value.set(description.name, description);
    }
  }
}

const addBookingPositionsFromLastWeeks = () => {
  const currentWeek = erpWeek.value[0]?.date;
  if (!currentWeek) return;
  const twoWeeksAgo = new Date(currentWeek);
  twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
  const endOfTheWeek = new Date(erpWeek.value[erpWeek.value.length - 1].date!);

  const entriesLastTwoWeeks = [...bookingData.value.values()].filter((x: BookingDay) => x.date && x.date >= twoWeeksAgo && x.date < currentWeek);
  const entriesThisWeek = [...bookingData.value.values()].filter((x: BookingDay) => x.date && x.date >= currentWeek && x.date <= endOfTheWeek);
  const usedBps = [
    ...new Set(
      entriesLastTwoWeeks
        .flatMap((x: BookingDay) => x.positionHours)
        .filter((x) => x.hours !== "00:00")
        .map((x) => x.name),
    ),
  ];

  const bPsToKeep = new Set();
  const bPsToKeep2 = new Set();

  for (const bp of usedBps) {
    for (const entry of entriesThisWeek) {
      if (!entry.positionHours.find((x) => x.name === bp)) {
        entry.positionHours.push({
          name: bp,
          hours: "00:00",
          comment: "",
        });
        bPsToKeep2.add(bp);
      }
    }
  }

  for (const entry of entriesThisWeek) {
    entry.positionHours = entry.positionHours.filter((x) => {
      const bPosition = bookingPositions.value.get(x.name);
      if (bPosition === undefined) {
        return true;
      }
      if (bPosition?.changeable_till === null) {
        return true;
      }
      return erpWeek.value[0].date! <= new Date(bPosition?.changeable_till);
    });
    for (const positionHour of entry.positionHours) {
      const bPosition = bookingPositions.value.get(positionHour.name);
      if (positionHour.hours !== "00:00" || !bPosition?.closed) {
        bPsToKeep.add(positionHour.name);
      } else {
        bPsToKeep2.delete(positionHour.name);
      }
    }
  }

  for (const entry of entriesThisWeek) {
    entry.positionHours = entry.positionHours.filter((x) => bPsToKeep.has(x.name) || bPsToKeep2.has(x.name));
  }
  loadedContent.value = true;
};

function calculateFirstWeekday() {
  const currDate = new Date();
  const firstWeekDay = new Date();
  firstWeekDay.setHours(0, 0, 0, 0);
  let offset = currDate.getDay() - 1;
  if (offset == -1) offset = 6;
  firstWeekDay.setDate(firstWeekDay.getDate() + changeWeekMultiplier.value * 7 - offset);
  return firstWeekDay;
}

function UpdateErpNextWeekProperties(combinedMonthlyEntries: Ref) {
  erpWeek.value = [];
  const firstWeekDay = calculateFirstWeekday();

  const lastWeekDay = new Date(firstWeekDay);
  lastWeekDay.setDate(lastWeekDay.getDate() + 7);
  combinedMonthlyEntries.value.forEach((entry: BookingDay) => {
    if (entry.date && entry.date >= firstWeekDay && entry.date < lastWeekDay) erpWeek.value.push(entry);
  });

  calendarWeek.value = moment(firstWeekDay).week();
}

/**
 * This method is used to load the booking data of the current month
 * and the week before and after the current month
 *
 * @param currentDate of today
 */
async function loadSurroundingWeeks(currentDate: Date) {
  const dataLoaded = [];
  const startOfCurrentWeek = moment(currentDate);

  /* First week of the month */
  const startOfFirstWeek = moment(currentDate);
  startOfFirstWeek.startOf("month");
  startOfFirstWeek.startOf("isoWeek");

  /* Last week of the month */
  const startOfLastWeek = moment(currentDate);
  startOfLastWeek.endOf("month");
  startOfLastWeek.startOf("isoWeek");

  if (startOfFirstWeek.isSame(startOfCurrentWeek, "week")) startOfFirstWeek.subtract(1, "week");
  if (startOfLastWeek.isSame(startOfCurrentWeek, "week")) startOfLastWeek.add(1, "week");

  const startOfInterestWeek = startOfFirstWeek.clone();
  let loadMissingWeekSince = null;
  while (startOfInterestWeek.isSameOrBefore(startOfLastWeek, "week")) {
    if (!bookingData.value.has(calculateDayHash(startOfInterestWeek.toDate()))) {
      if (loadMissingWeekSince == null) {
        loadMissingWeekSince = startOfInterestWeek.clone();
      }
    } else {
      if (loadMissingWeekSince != null) {
        const days = startOfInterestWeek.diff(loadMissingWeekSince, "days");
        dataLoaded.push(await loadTimesheetEntriesStartingWith(loadMissingWeekSince.toDate(), days + 7));
        loadMissingWeekSince = null;
      }
    }
    startOfInterestWeek.add(1, "week");
  }
  if (loadMissingWeekSince != null) {
    const days = startOfLastWeek.diff(loadMissingWeekSince, "days");
    dataLoaded.push(await loadTimesheetEntriesStartingWith(loadMissingWeekSince.toDate(), days + 7));
  }

  const promise = Promise.all(dataLoaded).then(() => loadMissingBookingDescriptions());
  return { dataLoading: dataLoaded.length > 0, promise: promise };
}

function updatePrevNextButtons(currentDate: Date) {
  const lastWeek = moment(currentDate);
  const nextWeek = moment(currentDate);
  lastWeek.subtract(1, "week");
  nextWeek.add(1, "week");
  disablePreviousWeekButton.value = !bookingData.value.has(calculateDayHash(lastWeek.toDate()));
  disableNextWeekButton.value = !bookingData.value.has(calculateDayHash(nextWeek.toDate()));
}

const updateMonthData = async () => {
  loadedContent.value = false;
  const currentDate = erpWeek.value[0]?.date;
  if (!currentDate) return;
  disablePreviousWeekButton.value = disableNextWeekButton.value = true;
  const loadingState = await loadSurroundingWeeks(currentDate);
  updatePrevNextButtons(currentDate);
  if (currentMonthData.length > 0 && currentMonthData[0].date && currentMonthData[0].date.getMonth() != currentDate.getMonth()) currentMonthData.length = 0;
  await loadingState.promise;
  updatePrevNextButtons(currentDate);

  if (currentMonthData.length == 0) {
    for (const day of bookingData.value.values()) {
      if (day.date && day.date.getMonth() == currentDate.getMonth() && day.date.getFullYear() == currentDate.getFullYear()) {
        currentMonthData.push(day);
      }
    }
  }
};

const changeWeekForward = async () => {
  changeWeekMultiplier.value++;
  await updateData();
};

const changeWeekBackward = async () => {
  changeWeekMultiplier.value--;
  await updateData();
};

const changeWeek = async (date: Date | undefined) => {
  if (!date) return;
  const currentWeekStart = calculateFirstWeekday();
  const Difference_In_Time = date.getTime() - currentWeekStart.getTime();
  const Difference_In_Weeks = Math.trunc(Difference_In_Time / (1000 * 3600 * 24 * 7));
  changeWeekMultiplier.value += Difference_In_Weeks;
  await loadTimesheetEntriesStartingWith(calculateFirstWeekday(), 21);
  await updateData();
};

async function updateData() {
  erpnextApi.limitWB.clearQueue();
  UpdateErpNextWeekProperties(bookingData);
  await updateMonthData();
  addBookingPositionsFromLastWeeks();
  UpdateErpNextWeekProperties(bookingData);
}

initializeCurrentWeek();
</script>
