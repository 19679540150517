<template>
  <Dialog v-model:visible="displayDialog" :dismissable-mask="true" :modal="true">
    <template #header>
      <h3>{{ title }}</h3>
    </template>
    <InputText id="note" v-model="textInput" :disabled="isLoading" autofocus placeholder="Kommentar" @keydown.enter="saveNote" />
    <template #footer>
      <Button :disabled="isLoading" label="Löschen" icon="pi pi-trash" @click="deleteNote" />
      <Button :disabled="isLoading" label="Speichern" icon="pi pi-check" @click="saveNote" />
    </template>
  </Dialog>
  <Button
    v-for="(note, key) in currentBooking.notes"
    :key="key"
    class="p-my-2 p-mr-2 workplace-comment p-button-sm"
    :disabled="dayDisabled"
    :label="note.note"
    :class="{ otherNote: note.employee !== employee }"
    @click="editNote(note)"
  />
  <Button v-if="canAddNote(dayDisabled, currentBooking, employee)" class="p-button-link p-button-sm" icon="pi pi-plus" @click="addNote" />
</template>

<script lang="ts">
import { computed, defineComponent, PropType, reactive, ref } from "vue";
import { Note, WorkplaceBooking } from "@/data-types";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import erpnextApi from "@/rest/erpnext-api";
import { useStore } from "vuex";
import { useToast } from "primevue/usetoast";
import InputText from "primevue/inputtext";
import { isNoteAddable } from "../utils/Workplace";
import { showErrorToast } from "@/utils/Toast";

export default defineComponent({
  name: "WorkplaceComment",
  components: { Button, Dialog, InputText },
  setup(props) {
    const store = useStore();
    const toast = useToast();
    const employeeName = computed(() => store.getters.getEmployee?.name);
    const currentBooking = reactive(props.currentBooking as WorkplaceBooking);
    const displayDialog = ref(false);
    const textInput = ref("");
    const title = ref("");
    const isLoading = ref(false);

    function addNote() {
      title.value = "Hinzufügen";
      textInput.value = "";
      displayDialog.value = true;
    }

    function saveNote() {
      isLoading.value = true;
      erpnextApi
        .updateWorkplaceBooking(currentBooking.date, currentBooking.workplace, currentBooking.desk, textInput.value, currentBooking.transportation_profile)
        .then(() => {
          if (isNoteAddable(props.dayDisabled, props.currentBooking, employeeName.value)) {
            currentBooking.notes.unshift({ note: textInput.value, employee: employeeName.value });
          } else {
            currentBooking.notes.find((note, key) => {
              if (note.employee === employeeName.value)
                currentBooking.notes[key] = {
                  note: textInput.value,
                  employee: employeeName.value,
                };
            });
          }
        })
        .finally(() => {
          displayDialog.value = false;
          isLoading.value = false;
        });
    }

    function deleteNote() {
      isLoading.value = true;
      erpnextApi
        .updateWorkplaceBooking(currentBooking.date, currentBooking.workplace, currentBooking.desk, "", currentBooking.transportation_profile)
        .then(() => {
          currentBooking.notes = currentBooking.notes.filter((note) => note.employee !== employeeName.value);
        })
        .finally(() => {
          displayDialog.value = false;
          isLoading.value = false;
        });
    }

    function editNote(note: Note) {
      if (note.employee === employeeName.value) {
        title.value = "Bearbeiten";
        textInput.value = note.note;
        displayDialog.value = true;
      } else {
        showErrorToast(toast, "Fremde Kommentare können nicht bearbeitet werden.");
      }
    }

    return {
      deleteNote,
      saveNote,
      addNote,
      editNote,
      displayDialog,
      textInput,
      isLoading,
      title,
      canAddNote: isNoteAddable,
      employee: employeeName,
    };
  },
  props: {
    currentBooking: { type: Object as PropType<WorkplaceBooking>, default: () => ({}), required: true },
    dayDisabled: { type: Boolean, default: false },
  },
});
</script>

<style scoped>
#note {
  width: 100%;
}

.workplace-comment {
  padding: 0;
}

.workplace-comment.p-button.otherNote {
  background: var(--pink);
  border-color: var(--pink);
  box-shadow: none;
  cursor: default;
}

.workplace-comment.p-button.otherNote:hover {
  background: var(--pink);
  border-color: var(--pink);
}

.workplace-comment.p-button.otherNote:focus {
  border-color: var(--pink);
  box-shadow: none;
}
</style>
