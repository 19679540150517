<template>
  <Dialog
    id="dialog-block-desks-in-future"
    v-model:visible="isDialogVisible"
    :content-props="dialogContentProps"
    :draggable="false"
    :pt="dialogPassThroughOptions"
    header="Tisch Blockieren"
    modal
    class="dialog-container"
    @show="resetDeskBlockingDialog"
  >
    <form id="dialog-block-desks-in-future-form" class="dialog-content-container" @submit.prevent="blockSelectedDesk" @reset.prevent="closeDialog">
      <div class="dialog-content">
        <div class="input-container">
          <label class="input-label" for="reason">
            <span>Grund</span>
            <span v-tooltip="'Feld ist erforderlich!'" :class="{ 'p-error': v$.reason.$error }">*</span>
          </label>
          <InputGroup class="dialog-input-group">
            <InputGroupAddon :class="{ 'border-color': getBorderColor(v$.reason.$error) }" class="input-group-addon-icon">
              <i :class="{ 'p-error': v$.reason.$error }" class="pi pi-user"></i>
            </InputGroupAddon>
            <InputText
              id="reason"
              v-model.trim="blockDesksInFuture.reason"
              v-tooltip.top="deskToBlockReason"
              :invalid="v$.reason.$error"
              class="input-text"
              type="text"
              @blur="v$.reason.$validate"
            />
          </InputGroup>
        </div>

        <div class="input-container">
          <label class="input-label" for="date">
            <span>Blockierzeit</span>
            <span v-tooltip="'Feld ist erforderlich!'" :class="{ 'p-error': v$.date.$error }">*</span>
          </label>
          <Calendar
            id="date"
            v-model="blockDesksInFuture.date"
            v-tooltip.top="calendarTooltip"
            :invalid="v$.date.$error"
            :min-date="currentTime"
            :pt="calendarPassThroughOptions"
            :touch-u-i="useMobileView"
            class="input-text"
            show-icon
            show-week
            show-button-bar
            @blur="v$.date.$validate"
            @hide="v$.date.$validate"
          />
        </div>
      </div>

      <div class="desk-selection-container">
        <img class="office-img" src="/src/assets/img/office.png" alt="office_plan" />
        <div v-for="deskToRender in desksToRender" :key="deskToRender.desk.name">
          <Button
            class="desk-button"
            :class="{ deskBlockedByUserEmployee: deskToRender.selected, freeDesk: !deskToRender.selected, deskUnavailable: deskToRender.blocked }"
            :style="getDeskPos(deskToRender.desk)"
            :disabled="deskToRender.blocked"
            @click="selectDesk(deskToRender)"
          >
            {{ deskToRender.desk.title }}
          </Button>
        </div>
      </div>
    </form>

    <template #footer>
      <div class="dialog-button-container">
        <Button form="dialog-block-desks-in-future-form" label="Abbrechen" text type="reset" />
        <Button form="dialog-block-desks-in-future-form" label="Blockieren" type="submit" />
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts" setup>
import { computed, defineEmits, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import Dialog, { DialogPassThroughOptions, DialogProps } from "primevue/dialog";
import InputText from "primevue/inputtext";
import Calendar, { CalendarPassThroughOptions } from "primevue/calendar";
import Button from "primevue/button";
import { Desk, DeskBlocking, DeskState, DeskToRender, ErpNextVisitorRegistration } from "@/data-types.ts";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { helpers, minLength, required } from "@vuelidate/validators";
import { currentDate } from "@/features/visitor/utils/visitor-util.ts";
import { TooltipOptions } from "primevue/tooltip";
import erpnextApi from "@/rest/erpnext-api.ts";
import { PassThrough } from "primevue/ts-helpers";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import moment from "moment";

const props = defineProps<{ desks: Desk[] }>();
watch(
  () => props.desks,
  () => (desksToRender.value = getDesksToRender()),
);

const windowWidth = ref(window.innerWidth);
const onWidthChange = () => (windowWidth.value = window.innerWidth);

onMounted(() => window.addEventListener("resize", onWidthChange));
onUnmounted(() => window.removeEventListener("resize", onWidthChange));
const useMobileView = computed(() => windowWidth.value < 1024);

const emit = defineEmits<{
  (e: "showDeskBlockingSuccess", toastMsg: string): void;
  (e: "showDeskBlockingError", toastMsg: string): void;
}>();

const currentTime = ref<Date>(currentDate());
const isDialogVisible = defineModel<boolean>("isDialogVisible", {
  required: true,
});
const blockDesksInFuture = reactive<{ reason: string; date: Date }>(blockDesksInFutureInitial());
const desksToRender = ref<DeskToRender[]>(getDesksToRender());

watch(
  () => blockDesksInFuture.date,
  async (newDate) => {
    const deskStates = await erpnextApi.getDeskStatus(newDate);
    desksToRender.value.forEach((desk) => {
      resetDeskBlockedAndSelectedProps(desk);
      const deskState = findDeskStateByName(deskStates, desk.desk.name);
      if (!deskState?.available) desk.blocked = true;
    });
  },
);

function getDesksToRender() {
  return props.desks.map((desk) => ({
    desk,
    selected: false,
    blocked: false,
  }));
}

function resetDeskBlockedAndSelectedProps(desk: DeskToRender) {
  desk.blocked = false;
  desk.selected = false;
}

function findDeskStateByName(deskStates: DeskState[], deskName: string) {
  return deskStates.find((state) => state.name === deskName);
}

function setSelectionOfAllTablesToFalse() {
  desksToRender.value.map((desk) => (desk.selected = false));
}

function selectDesk(desk: DeskToRender) {
  setSelectionOfAllTablesToFalse();
  desk.selected = true;
}

const validations: ValidationArgs<{ reason: string; date: Date }> = {
  reason: {
    required: helpers.withMessage("Begründung ist erforderlich!", required),
    minLength: helpers.withMessage("Eingabe ist zu kurz!", minLength(3)),
  },
  date: { required: helpers.withMessage("Datum ist erforderlich!", required) },
};

const v$ = useVuelidate<{
  reason: string;
  date: Date;
}>(validations, blockDesksInFuture, { $rewardEarly: true });

const deskToBlockReason = computed(() => generateTooltipErrorMessages(v$.value.reason, "Hier bitte Grund für die Tisch Blockierung angeben."));
const calendarTooltip = computed(() => generateTooltipErrorMessages(v$.value.date, "Hier bitte den Zeitpunkt des Besuches auswählen."));

function blockDesksInFutureInitial() {
  return {
    reason: "",
    date: currentTime.value,
  };
}

function resetDeskBlockingDialog() {
  currentTime.value = currentDate();
  Object.assign(blockDesksInFuture, blockDesksInFutureInitial());
  desksToRender.value.forEach((desk) => (desk.selected = false));
  v$.value.$reset();
}

function setSelectedDeskToBlocked() {
  const selectedDesk = desksToRender.value.find((desk) => desk.selected === true);
  if (!selectedDesk) return;
  selectedDesk.blocked = true;
}

async function blockDesk(deskBlocking: DeskBlocking, title: string) {
  try {
    await erpnextApi.addDeskBlocking(deskBlocking);
    setSelectedDeskToBlocked();
    emit("showDeskBlockingSuccess", `${title} wurde erfolgreich blockiert.`);
  } catch (err: Error | any) {
    emit("showDeskBlockingError", err.message);
  }
}

async function blockSelectedDesk() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;
  const date = moment(blockDesksInFuture.date).format("YYYY-MM-DD");
  if (!date) return;

  const deskToBlockName = desksToRender.value.find((desk) => desk.selected === true)?.desk;
  if (!deskToBlockName) {
    emit("showDeskBlockingError", "Bitte wähle einen Tisch aus.");
    return;
  }

  const requestObject: DeskBlocking = {
    date: date,
    desk: deskToBlockName.name,
    reason: blockDesksInFuture.reason,
  };
  await blockDesk(requestObject, deskToBlockName.title);
}

function closeDialog() {
  isDialogVisible.value = false;
}

// styling of the input fields
function getBorderColor(useErrorBorderStyle: boolean) {
  return useErrorBorderStyle ? "#ef9a9a" : "var(--borderColor)";
}

// styling of the tooltip and message of tooltip
function generateTooltipErrorMessages(
  validator: Validation<ValidationArgs<unknown>, ErpNextVisitorRegistration>,
  defaultTooltipMessage: string,
): TooltipOptions | string {
  const error = validator.$error;
  if (!error) return defaultTooltipMessage;
  const errors = validator.$silentErrors;
  return {
    pt: {
      arrow: { class: ["p-message", "p-message-error"], style: { borderRadius: 0 } },
      text: { class: ["p-message", "p-message-error"], style: { margin: 0, borderRadius: 0 } },
    },
    value: errors.map((error) => error.$message).join("\n"),
  };
}

// styling of the calendar and dropdown button
const calendarPassThroughOptions = computed(() => {
  return {
    input: { style: { borderBottomRightRadius: "unset", borderTopRightRadius: "unset" } },
    dropdownButton: {
      root: {
        class: { "p-button-outlined": true, "p-button-danger": v$.value.date.$error },
        style: {
          borderBottomLeftRadius: "unset",
          borderTopLeftRadius: "unset",
          borderColor: getBorderColor(v$.value.date.$error),
          borderLeft: "unset",
        },
      },
    },
  } as PassThrough<CalendarPassThroughOptions>;
});

// styling of the dialog
const dialogContentProps: DialogProps = { style: { paddingBottom: "6px" } };
const dialogPassThroughOptions: PassThrough<DialogPassThroughOptions> = {
  footer: {
    style: {
      padding: "24px",
      display: "flex",
      flexFlow: "row nowrap",
    },
  },
};

function getDeskPos(desk: Desk) {
  return {
    left: desk.x + "px",
    top: desk.y + "px",
    transform: desk.orientation === "vertical" ? "rotate(90deg)" : "",
  };
}
</script>

<style scoped>
* {
  --dialog-content-gap-x: 24px;
  --dialog-content-gap-y: 12px;
  --input-container-amount-inputfield: 2;
}

.dialog-content-container {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  gap: 24px;
}

.dialog-content {
  flex: 1;
  display: flex;
  flex-flow: row wrap;
  gap: var(--dialog-content-gap-y) var(--dialog-content-gap-x);
}

.input-container {
  display: flex;
  flex-flow: column nowrap;
  gap: 6px;
  flex: 1 0 calc(100% / var(--input-container-amount-inputfield) - var(--dialog-content-gap-x));
}

.input-label {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: space-between;
  font-weight: bold;
  hyphens: auto;
}

.input-group-addon-icon {
  color: var(--grey);
  border-color: var(--borderColor);
}

.input-text {
  min-width: 10rem;
  border-color: var(--borderColor);
}

.dialog-button-container {
  display: flex;
  flex-flow: row wrap;
  gap: calc(var(--dialog-content-gap-x) / 2);

  & > * {
    flex: 1 0 calc(100% / 2 - var(--dialog-content-gap-x));
    margin: 0;
    display: flex;
    justify-content: center;
  }
}

.desk-button {
  height: 50px;
  width: 70px;
  font-size: 12px;
  color: var(--agnosticBlack);
  position: absolute;
  margin: 6px 6px 3rem;
}

.desk-button:disabled {
  opacity: 1;
}

.freeDesk {
  background: var(--free-desk);
}

.freeDesk:enabled:hover {
  background: var(--free-desk);
  color: var(--agnosticBlack);
  opacity: 0.8;
}

.deskBlockedByUserEmployee {
  background: var(--desk-blocked-by-user-employee);
}

.deskBlockedByUserEmployee:enabled:hover {
  background: var(--desk-blocked-by-user-employee);
  color: var(--agnosticBlack);
  cursor: default;
}

.deskUnavailable {
  background: var(--desk-unavailable);
}

.dialog-container {
  max-width: 95vw;
}

.office-img {
  user-select: none;
  position: absolute;
  width: 1300px;
  height: 491px;
}

.desk-selection-container {
  position: relative;
  width: 1300px;
  height: 491px;
  margin-top: 8px;
}
</style>
