import { Employee } from "@/data-types";
import erpnextApi from "@/rest/erpnext-api";

const employeeStore = {
  state: () => ({
    allEmployees: new Map<string, Employee>(),
    favoriteEmployees: new Map<string, string[]>(),
  }),
  getters: {
    getAllEmployeesAsMap(state: any) {
      return state.allEmployees;
    },
    getAllEmployees(state: any): Employee[] {
      return Array.from(state.allEmployees.values()).sort((x: any, y: any) => x.last_name.localeCompare(y.last_name)) as Employee[];
    },
    getFavoriteEmployees(state: any) {
      return state.favoriteEmployees;
    },
  },
  mutations: {
    setAllEmployees(state: any, employees: Employee[]) {
      for (const employee of employees) {
        employee.full_name = employee.first_name + " " + employee.last_name;
        state.allEmployees.set(employee.name, employee);
      }
    },
    setFavoriteEmployees(state: any, favoriteEmployees: Map<string, string[]>) {
      state.favoriteEmployees = favoriteEmployees;
    },
  },
  actions: {
    async fetchAllEmployees(context: any) {
      if (context.state.allEmployees.size === 0) {
        const allEmployees: Employee[] = await erpnextApi.getEmployees();
        context.commit("setAllEmployees", allEmployees);
      }
    },
    async fetchFavoriteEmployees(context: any) {
      if (context.state.favoriteEmployees.size === 0) {
        context.commit("setFavoriteEmployees", await erpnextApi.getFavoriteEmployeeGroups());
      }
    },
    async updateFavoriteEmployees(context: any, commit: any) {
      const favoriteEmployees = context.state.favoriteEmployees;
      favoriteEmployees.set(commit.group, commit.favorites);
      context.commit("setFavoriteEmployees", favoriteEmployees);
    },
  },
};

export default employeeStore;
