import { erpNextUserProfile } from "@/data-types";
import erpnext from "@/rest/erpnext-api";

const userProfileStore = {
  state: () => ({
    userProfile: <erpNextUserProfile>{},
  }),
  getters: {
    getUserProfile(state: any) {
      return state.userProfile;
    },
  },
  mutations: {
    setUserProfile(state: any, userProfile: erpNextUserProfile[]) {
      state.userProfile = userProfile;
    },
  },
  actions: {
    async fetchUserProfile(context: any) {
      if (context.state.userProfile == null) {
        context.commit("setUserProfile", await erpnext.getProfileSettings());
      }
    },
    async reloadUserProfile(context: any) {
      context.commit("setUserProfile", await erpnext.getProfileSettings());
    },
  },
};

export default userProfileStore;
