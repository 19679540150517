import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import PrimeVue from "primevue/config";
import Tooltip from "primevue/tooltip";
import ConfirmationService from "primevue/confirmationservice";
/* PrimeVue */
import "primevue/resources/themes/arya-green/theme.css";
import "primevue/resources/primevue.min.css";
import "@/resources/css/theme.css";
import "primeicons/primeicons.css"; //icons
/*Primeflex */
import "primeflex/primeflex.css";

// PrimeVue Components
import Panel from "primevue/panel";
import Button from "primevue/button";
import Card from "primevue/card";
import Password from "primevue/password";
import InputText from "primevue/inputtext";
import ProgressSpinner from "primevue/progressspinner";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Badge from "primevue/badge";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import ScrollPanel from "primevue/scrollpanel";
import Textarea from "primevue/textarea";
import FileUpload from "primevue/fileupload";
import TieredMenu from "primevue/tieredmenu";

import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";

import "./resources/css/themeDesign.css";
import "./resources/css/tableStyling.css";
import erpnext from "@/rest/erpnext-api";
import InputNumber from "primevue/inputnumber";

erpnext.getEmployee().then(async (user) => {
  await store.commit("setLoggedInUser", user);
  user && (await store.dispatch("reloadUserProfile"));

  const app = createApp(App)
    .use(store)
    .use(router)
    .use(PrimeVue, {
      locale: {
        startsWith: "Beginnt mit",
        contains: "enthält",
        notContains: "enthält nicht",
        endsWith: "endet mit",
        equals: "entspricht",
        notEquals: "entspricht nicht",
        noFilter: "Kein Filter",
        lt: "Weniger als",
        lte: "Weniger als oder gleich viel",
        gt: "Mehr als",
        gte: "Mehr als oder gleich viel",
        dateIs: "Datum ist",
        dateIsNot: "Datum ist nicht",
        dateBefore: "Datum liegt vor",
        dateAfter: "Datum liegt nach",
        clear: "Löschen",
        apply: "Anwenden",
        matchAll: "Alle abgleichen",
        matchAny: "Mit jedem abgleichen",
        addRule: "Regel hinzufügen",
        removeRule: "Regel entfernen",
        accept: "Ja",
        reject: "Nein",
        choose: "Auswählen",
        upload: "Hochladen",
        cancel: "Abbrechen",
        dayNames: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
        dayNamesShort: ["Son", "Mon", "Die", "Mit", "Don", "Fre", "Sam"],
        dayNamesMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
        monthNames: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
        monthNamesShort: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
        today: "Heute",
        weekHeader: "Wk",
        firstDayOfWeek: 1,
        dateFormat: "dd.mm.yy",
        weak: "Schwach",
        medium: "Medium",
        strong: "Stark",
        passwordPrompt: "Passwort eingeben",
        emptyFilterMessage: "Keine Ergebnisse gefunden",
        emptyMessage: "Keine verfügbaren Optionen",
      },
    })
    .use(ToastService)
    .use(ConfirmationService)
    .component("Button", Button)
    .component("Toast", Toast)
    .component("Panel", Panel)
    .component("Card", Card)
    .component("Password", Password)
    .component("InputText", InputText)
    .component("InputNumber", InputNumber)
    .component("Textarea", Textarea)
    .component("ProgressSpinner", ProgressSpinner)
    .component("DataTable", DataTable)
    .component("Column", Column)
    .component("Badge", Badge)
    .component("ScrollPanel", ScrollPanel)
    .component("Dropdown", Dropdown)
    .component("Calendar", Calendar)
    .component("FileUpload", FileUpload)
    .component("TieredMenu", TieredMenu)
    .directive("tooltip", Tooltip);

  router.isReady().then(() => {
    app.mount("#app");
  });
});
