import { Commit } from "vuex";

interface RootState {
  startTimeValue: string | null;
  breakStartTimeValue: string | null;
  breakEndTimeValue: string | null;
  breakTime: string | null;
  endTimeValue: string | null;
}

const startPageStore = {
  state: () => ({
    startTimeValue: null,
    breakStartingValue: null,
    breakEndTimeValue: null,
    breakTime: null,
    endTimeValue: null,
  }),
  getters: {
    getStartTimeValue: (state: RootState) => state.startTimeValue,
    getBreakStartTimeValue: (state: RootState) => state.breakStartTimeValue,
    getBreakEndTimeValue: (state: RootState) => state.breakEndTimeValue,
    getBreakTime: (state: RootState) => state.breakTime,
    getEndTimeValue: (state: RootState) => state.endTimeValue,
  },
  mutations: {
    SET_START_TIME_VALUE(state: RootState, value: string) {
      state.startTimeValue = value;
      localStorage.setItem("startTimeValue", value);
    },
    SET_END_TIME_VALUE(state: RootState, value: string) {
      state.endTimeValue = value;
      localStorage.setItem("endTimeValue", value);
    },
    SET_BREAK_START_TIME_VALUE(state: RootState, value: string) {
      state.breakStartTimeValue = value;
      localStorage.setItem("breakStartTimeValue", value);
    },
    SET_BREAK_END_TIME_VALUE(state: RootState, value: string) {
      state.breakEndTimeValue = value;
      localStorage.setItem("breakEndTimeValue", value);
    },
    SET_BREAK_TIME(state: RootState, value: string) {
      state.breakTime = value;
      localStorage.setItem("breakTime", value);
    },
  },
  actions: {
    setStartTimeValue({ commit }: { commit: Commit }, value: string) {
      commit("SET_START_TIME_VALUE", value);
    },
    setEndTimeValue({ commit }: { commit: Commit }, value: string) {
      commit("SET_END_TIME_VALUE", value);
    },
    setBreakStartTimeValue({ commit }: { commit: Commit }, value: string) {
      commit("SET_BREAK_START_TIME_VALUE", value);
    },
    setBreakEndTimeValue({ commit }: { commit: Commit }, value: string) {
      commit("SET_BREAK_END_TIME_VALUE", value);
    },
    setBreakTime({ commit }: { commit: Commit }, value: string) {
      commit("SET_BREAK_TIME", value);
    },
  },
};
export default startPageStore;
