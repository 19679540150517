<template>
  <div class="card containerWeekly">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseCardWeekly",
});
</script>
<style scoped>
.card {
  border-radius: 3px;
  padding: 0.5rem 0;
  border: 1px solid var(--cardBorderBolor);
  background-color: var(--dark);
  min-height: auto;
  width: min-content;
}

@media (min-width: 360px) {
  .card {
    width: 100%;
  }
}

@media (max-width: 460px) {
  .card {
    width: 100%;
  }
}

@media (min-width: 576px) {
  .card {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .card {
    width: 807px;
    padding: 0.5rem 1rem;
  }
}

@media (min-width: 992px) {
  .card {
    width: 807px;
  }
}

@media (min-width: 1120px) {
  .card {
    width: 807px;
  }
}

@media (min-width: 1200px) {
  .card {
    width: 807px;
  }
}

@media (min-width: 1400px) {
  .card {
    width: min-content;
  }
}
</style>
