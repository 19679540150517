<template>
  <div v-if="employees.length > 0" class="p-d-flex p-ai-center p-flex-wrap p-mr-2">
    <div v-for="employee in employees" :key="employee.name">
      <TooltipWrapper :content="employee.full_name">
        <EmployeeIcon :employee="employee" />
      </TooltipWrapper>
    </div>
  </div>
</template>
<script lang="ts">
import { Employee } from "@/data-types";
import { defineComponent, PropType } from "vue";
import TooltipWrapper from "@/ui/TooltipWrapper.vue";
import EmployeeIcon from "@/ui/EmployeeIcon.vue";

export default defineComponent({
  name: "EmployeeIcons",
  components: { TooltipWrapper, EmployeeIcon },
  props: {
    employees: { type: Object as PropType<Employee[]> },
  },
});
</script>

<style scoped>
.employee-avatar {
  font-size: small;
  margin: 2px;
}
</style>
