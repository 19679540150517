<template>
  <transition :name="transitionName">
    <BaseCard v-show="componentVisible" class="baseCard">
      <week-control
        title="Arbeitsort"
        :calendar-exists="false"
        :year="startOfCurrentWeek.year()"
        :week="startOfCurrentWeek.week()"
        :is-swipe-deactivated="isSwipeDeactivated"
        :disable-previous-week="loading"
        :disable-next-week="loading || isSameOrNextWeek(startOfCurrentWeek)"
        :is-forward-swipe-deactivated="loading || isSameOrNextWeek(startOfCurrentWeek)"
        @change-week-backward="changeWeek(-1)"
        @change-week-forward="changeWeek(1)"
      />
      <div v-if="!loading">
        <div class="p-my-2">
          <MultiSelect v-model="filter" :disabled="loading" :options="favorites" class="p-mr-2" style="width: 160px" />
        </div>
        <div v-for="booking in currentBookings" ref="dayLoopElement" :key="booking.date.toISOString()">
          <div>
            <WorkplaceDay
              :booking="booking"
              :desks="desks"
              :day-disabled="isDayDisabled(booking)"
              :public-holidays="publicHolidays"
              @workplace-change="onWorkplaceChange"
              @transportation-change="onTransportationChange"
              @parking-lot-change="onParkingLotChange"
            >
              <WorkplaceDesk
                v-if="isInOffice(booking) && !booking.isLoading"
                :booking="booking"
                :employees="employees"
                :desks="desks"
                :day-disabled="isDayDisabled(booking)"
                @deactivate-swipe="deactivateSwipe"
                @activate-swipe="activateSwipe"
                @desk-selected="submitBooking"
              />
            </WorkplaceDay>
          </div>

          <FavoritesHandler :disabled="isDayDisabled(booking)" :filter="filter" :booking="booking" :employees="employees" :desks="desks" />
        </div>
      </div>
      <progress-spinner v-else class="full-width" />
    </BaseCard>
  </transition>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import erpnextApi from "@/rest/erpnext-api";
import { erpNextTransportationProfile, WorkplaceBooking, workplaces } from "@/data-types";
import { useToast } from "primevue/usetoast";
import WeekControl from "@/ui/WeekControl.vue";
import moment from "moment";
import ProgressSpinner from "primevue/progressspinner";
import BaseCard from "@/ui/BaseCard.vue";
import { getMonthFromDate, getYearFromDate, isMobile } from "@/utils/Helper";
import store from "@/store";
import WorkplaceDay from "@/features/workspace/components/WorkplaceDay.vue";
import FavoritesHandler from "@/features/workspace/components/FavoritesHandler.vue";
import WorkplaceDesk from "@/features/workspace/components/WorkplaceDesk.vue";
import { useRouter } from "vue-router";

import MultiSelect from "primevue/multiselect";
import { employeesNoteIfPresent, getStartOfWeekFromProps } from "./utils/Workplace";
import { calculateWorkplaceRouterPath } from "@/utils/WorkplacePathCalculator";
import { showErrorToast, showSuccessToast } from "@/utils/Toast";

export default defineComponent({
  name: "WorkplaceView",
  components: { FavoritesHandler, WorkplaceDay, WorkplaceDesk, WeekControl, BaseCard, ProgressSpinner, MultiSelect },
  props: {
    week: { type: String, required: false, default: moment().weeks().toString() },
    day: { type: String, required: false },
  },
  setup(props) {
    const componentVisible = ref(true);
    const router = useRouter();
    const favorites = ref<string[]>([]);
    const filter = ref(["Standard"]);

    function updateRoute(initial?: boolean) {
      componentVisible.value = true;
      if (initial) {
        router.replace({ params: { week: startOfCurrentWeek.value.week() } });
      } else {
        router.replace(calculateWorkplaceRouterPath(startOfCurrentWeek.value.week()));
      }
    }

    const startOfCurrentWeek = ref(getStartOfWeekFromProps(props.week));
    updateRoute(true);
    const isSwipeLeft = ref(true);
    const transitionName = computed(() => {
      if (!isItMobile) {
        return "";
      } else {
        return isSwipeLeft.value ? "slide-left" : "slide-right";
      }
    });
    const currentBookings = ref<(WorkplaceBooking & { isLoading: boolean })[]>([]);
    const desks = computed(() => store.getters.getDesks);
    const employees = computed(() => store.getters.getAllEmployeesAsMap);
    const publicHolidays = computed(() => store.getters.getPublicHolidays);
    const selectedBooking = ref();
    const loading = ref(true);
    const displayShowAll = ref(false);
    const toast = useToast();
    const employee = computed(() => store.getters.getEmployeesName);
    const dayLoopElement = ref();
    const isSwipeDeactivated = ref(false);
    const isItMobile = isMobile();

    watch(dayLoopElement, () => {
      const pos = document.querySelector("#" + props.day);
      pos && pos.scrollIntoView({ behavior: "smooth" });
    });

    onMounted(async () => {
      await Promise.all([
        store.dispatch("fetchTransportationProfiles"),
        store.dispatch("fetchDesks"),
        store.dispatch("fetchFavoriteEmployees"),
        store.dispatch("fetchAllEmployees"),
        fetchCurrentPublicHolidays(),
        updateBookings(),
      ]);

      for (const key of store.getters.getFavoriteEmployees.keys()) {
        favorites.value.push(key);
        favorites.value.sort();
      }
      loading.value = false;
    });

    watch(
      filter,
      (newVal) => {
        sessionStorage.setItem("filterOptions", JSON.stringify(newVal));
      },
      { deep: true },
    );

    onMounted(() => {
      const storedFilters = sessionStorage.getItem("filterOptions");
      if (storedFilters) {
        const storedFiltersVal = JSON.parse(storedFilters);
        const filters = ref(storedFiltersVal);
        filter.value = filters.value;
      }
    });

    async function fetchCurrentPublicHolidays() {
      const date: Date = startOfCurrentWeek.value.toDate();
      const month = getMonthFromDate(date);
      const year = getYearFromDate(date);
      await store.dispatch("fetchPublicHolidays", { month, year });
    }

    function deactivateSwipe() {
      isSwipeDeactivated.value = true;
    }

    function activateSwipe() {
      isSwipeDeactivated.value = false;
    }

    function updateBookings() {
      return erpnextApi.getWorkplaceBookings(startOfCurrentWeek.value.toDate(), 5).then((workplaceBookings) => {
        currentBookings.value = workplaceBookings.map((booking) => ({ ...booking, isLoading: false }));
      });
    }

    function isInOffice(booking: WorkplaceBooking) {
      return booking.workplace === "office";
    }

    function onTransportationChange(booking: WorkplaceBooking) {
      booking.parking_lot_desired = store.getters.getTransportationProfiles.find(
        (x: erpNextTransportationProfile) => x.name === booking.transportation_profile,
      )?.parking_lot_desired;
      submitBooking(booking);
    }

    function onParkingLotChange(booking: WorkplaceBooking) {
      submitBooking(booking);
    }

    function submitBooking(booking: WorkplaceBooking) {
      if (!booking.parking_lot_desired) booking.has_parking_lot = false;
      erpnextApi
        .updateWorkplaceBooking(
          booking.date,
          booking.workplace,
          booking.desk,
          employeesNoteIfPresent(booking.notes, employee.value),
          store.getters.getEmployee?.is_external ? "" : booking.transportation_profile,
          booking.parking_lot_desired,
        )
        .then(() => {
          showSuccessToast(toast, "Arbeitsort bestätigt");
        })
        .catch(() => {
          showErrorToast(toast, "Arbeitsort konnte nicht geändert werden");
        });
    }

    function bookDeskSuggestedByBackend(booking: WorkplaceBooking & { isLoading: boolean }) {
      const default_transportation = store.getters.getUserProfile.default_transportation;
      booking.isLoading = true;
      booking.parking_lot_desired = store.getters.getTransportationProfiles.find(
        (x: erpNextTransportationProfile) => x.name === default_transportation,
      )?.parking_lot_desired;
      booking.transportation_profile = default_transportation;
      erpnextApi
        .getDeskStatus(booking.date)
        .then((data) => {
          booking.desk = data.find((x) => x.favorite)?.name;
          submitBooking(booking);
        })
        .finally(() => (booking.isLoading = false));
    }

    function cancelDeskBooking(booking: WorkplaceBooking) {
      booking.desk = "";
      submitBooking(booking);
    }

    const onWorkplaceChange = (booking: WorkplaceBooking) => {
      booking.transportation_profile = undefined;
      if (booking.workplace === "office") {
        bookDeskSuggestedByBackend(booking as any);
      } else {
        cancelDeskBooking(booking);
      }
    };

    const isSameOrNextWeek = (week: moment.Moment) => {
      return moment().add(1, "days").add(1, "week").isSameOrBefore(week, "week");
    };

    function changeWeek(week: number) {
      if (!isItMobile) {
        loading.value = true;
        startOfCurrentWeek.value = startOfCurrentWeek.value.add(week, "weeks");
        updateBookings().then(() => (loading.value = false));
        updateRoute();
      } else {
        if (week === 1) {
          isSwipeLeft.value = true;
        } else {
          isSwipeLeft.value = false;
        }
        componentVisible.value = false;
        setTimeout(() => {
          loading.value = true;
          startOfCurrentWeek.value = startOfCurrentWeek.value.add(week, "weeks");
          updateBookings().then(() => (loading.value = false));
          updateRoute();
        }, 500);
      }
    }

    function isDayDisabled(booking: WorkplaceBooking) {
      return !!booking.absence_reason || moment().isAfter(booking.date, "day");
    }

    return {
      dayLoopElement,
      startOfCurrentWeek,
      currentBookings,
      selectedBooking,
      desks,
      employees,
      loading,
      onWorkplaceChange,
      onTransportationChange,
      onParkingLotChange,
      submitBooking,
      changeWeek,
      displayShowAll,
      isSameOrNextWeek,
      workplaces,
      isDayDisabled,
      publicHolidays,
      isInOffice,
      deactivateSwipe,
      isSwipeDeactivated,
      activateSwipe,
      componentVisible,
      transitionName,
      isItMobile,
      favorites,
      filter,
    };
  },
});
</script>

<style scoped>
.slide-left-enter-active,
.slide-left-leave-active {
  transition: transform 0.5s ease;
}

.slide-left-enter-from,
.slide-left-leave-to {
  transform: translateX(-100%);
}

.slide-right-enter-active,
.slide-right-leave-active {
  transition: transform 0.5s ease;
}

.slide-right-enter-from,
.slide-right-leave-to {
  transform: translateX(100%);
}
</style>
