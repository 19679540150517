<template>
  <div class="p-d-flex p-ai-center p-mt-3">
    <div class="box p-mr-2">
      <CalendarDay :child-class="type" />
    </div>
    <div class="p-mr-3">
      {{ name }}
    </div>
  </div>
</template>

<script setup lang="ts">
import CalendarDay from "@/features/calendar/components/CalendarDay.vue";

interface Props {
  name: string;
  type: string;
}

const props = defineProps<Props>();
</script>

<style scoped>
.box {
  border: 0;
  height: 1rem;
  width: 1rem;
}
</style>
