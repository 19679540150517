<template>
  <div class="p-field p-grid data-entry" :class="{ 'total-subitem': isSubItem }">
    <label class="p-col-fixed data-entry-label" :class="{ 'total-subitem-label': isSubItem }" :v-tooltip="tooltip">{{ title }}</label>
    <span v-if="value" class="p-col-fixed data-entry-value" :class="{ 'total-subitem-value': isSubItem }" :v-tooltip="tooltip">
      {{ value }}
    </span>
  </div>
</template>

<script>
export default {
  name: "OverviewDataEntry",
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    isSubItem: {
      type: Boolean,
      required: false,
    },
    tooltip: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped>
.data-entry {
  font-size: 11pt;
}

.data-entry-label {
  width: 190px;
  margin-bottom: 4px;
}

.data-entry-value {
  width: 70px;
  text-align: right;
}

.total-subitem {
  font-size: 10pt;
}

.total-subitem-label {
  padding-left: 16px;
  color: #aaa;
}

.total-subitem-value {
  color: #aaa;
}

.p-field {
  text-align: left;
  margin-bottom: 0;
  color: var(--white);
}
</style>
