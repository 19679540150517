<template>
  <div class="p-mt-5">
    <ProfileForm :is-changed="hasChanges" @save="save(!v$.$invalid)">
      <ProfileFormField
        label="Softwareentwicklung seit"
        type="calendarYearPicker"
        width="6"
        :is-changed="hasChanges"
        identifier="experience_date"
        :submitted="submitted"
        @save="save(!v$.$invalid)"
      />
      <ProfileFormField label="Biografie" type="editor" width="12" identifier="bio" :submitted="submitted" @save="save(!v$.$invalid)" />
      <Education />
      <ExternalWorkHistory />
      <internal-work-history />
      <skill />
      <training />
    </ProfileForm>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, inject, provide, ref } from "vue";
import ProfileForm from "@/features/profile/components/ProfileForm.vue";
import ProfileFormField from "@/features/profile/components/ProfileFormField.vue";
import { helpers, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { loading, validate } from "@/keys";
import erpnextApi from "@/rest/erpnext-api";
import { erpNextWorkHistory } from "@/data-types";
import { useToast } from "primevue/usetoast";
import Education from "./Education.vue";
import Skill from "./Skill.vue";
import Training from "./Training.vue";
import clonedeep from "lodash.clonedeep";
import ExternalWorkHistory from "./ExternalWorkHistory.vue";
import InternalWorkHistory from "./InternalWorkHistory.vue";
import { showErrorToast, showSuccessToast } from "@/utils/Toast";
import { formatYearToERP } from "@/features/profile/utils/Profile";

export default defineComponent({
  name: "ProfileCareer",
  components: { ProfileForm, ProfileFormField, Education, ExternalWorkHistory, InternalWorkHistory, Skill, Training },
  setup() {
    const toast = useToast();
    const submitted = ref(false);
    const workHistory = ref<erpNextWorkHistory>({ bio: null, experience: null, experience_date: null });
    const isLoading = inject(loading, ref(true));
    const date = ref();
    const rules = {
      bio: { required: helpers.withMessage("*", required) },
      experience: {},
      experience_date: { required: helpers.withMessage("*", required) },
    };
    const v$ = useVuelidate(rules, workHistory);
    provide(validate, v$);

    const initialState = ref(clonedeep(workHistory.value));
    const hasChanges = computed(() => {
      return JSON.stringify(workHistory.value) !== JSON.stringify(initialState.value);
    });

    isLoading.value = true;
    erpnextApi
      .getProfileWorkHistory()
      .then((res) => {
        workHistory.value = res;
        workHistory.value.experience && (workHistory.value.experience_date = new Date(`${workHistory.value.experience}-01-01`));
        initialState.value = clonedeep(workHistory.value);
      })
      .finally(() => (isLoading.value = false));

    function save(isFormValid: boolean) {
      submitted.value = true;
      if (!isFormValid) {
        return;
      }
      isLoading.value = true;
      workHistory.value.experience_date && (workHistory.value.experience = formatYearToERP(workHistory.value.experience_date));
      erpnextApi
        .updateProfileWorkHistory(workHistory.value)
        .then(() => showSuccessToast(toast, erpnextApi.ProfileUpdatedMessage))
        .catch((err) => showErrorToast(toast, err))
        .finally(() => (isLoading.value = false));
      initialState.value = clonedeep(workHistory.value);
    }

    return {
      v$,
      submitted,
      save,
      date,
      isLoading,
      hasChanges,
    };
  },
});
</script>

<style scoped></style>
