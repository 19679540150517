import moment from "moment";
import "moment-duration-format";
import { CalendarPublicHoliday, PublicHoliday } from "@/data-types";

export function splitTimeString(timeText: string | undefined) {
  if (!timeText) return { hours: 0, minutes: 0 };
  const [hours, minutes] = timeText.split(":");
  return {
    hours: parseInt(hours),
    minutes: parseInt(minutes),
  };
}

export function buildTimeString(hours: number, minutes: number) {
  return buildTimeStringFromHours(hours + minutes / 60);
}

export function buildTimeStringFromHours(hours: number | null) {
  if (hours == null) return "--:--";
  return moment.duration(hours, "hours").format("*hh:mm");
}

export function roundTime(timeStr: string) {
  if (!timeStr) return timeStr;

  const segs = splitTimeString(timeStr);
  const off = segs.minutes % 3;
  if (off != 0) {
    if (off == 1) {
      segs.minutes--;
    } else {
      segs.minutes++;
    }
    return buildTimeString(segs.hours, segs.minutes);
  }
  return timeStr;
}

export function formatDateToERP(date: Date): string {
  return moment(date).format("YYYY-MM-DD");
}

export function formatDateStringToGerman(dateString: string): string {
  if (dateString === undefined) {
    return "";
  } else {
    const date = new Date(dateString);
    return moment(date).format("DD.MM.YYYY");
  }
}

export function getDayFromDate(date: Date): number {
  return parseInt(moment(date).format("D"));
}

export function getMonthFromDate(date: Date): string {
  return moment(date).format("MM");
}

export function getYearFromDate(date: Date): string {
  return moment(date).format("YYYY");
}

export function formatDateWithoutYear(date: Date | undefined, locales?: string) {
  return (
    date?.toLocaleDateString(locales, {
      month: "2-digit",
      day: "2-digit",
    }) ?? "xx.xx."
  );
}

export function getWorkdayForDate(date: Date) {
  switch (date?.getDay()) {
    case 1:
      return "Mo";
    case 2:
      return "Di";
    case 3:
      return "Mi";
    case 4:
      return "Do";
    case 5:
      return "Fr";
    case 6:
      return "Sa";
    case 0:
      return "So";
  }
  return "--";
}

export function startOfMonth(date: Date) {
  return moment(date).startOf("month").toDate();
}

export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export function convertFromErpDate(day: string | null) {
  const segs = day?.split("-") ?? ["--", "--", "--"];
  return segs[2] + "." + segs[1] + "." + segs[0];
}

// Absence.ts in utils
export function sortPublicHolidays(publicHolidays: PublicHoliday[]) {
  const result: CalendarPublicHoliday[] = [];
  publicHolidays.forEach((publicHoliday) => {
    const holidayDate = new Date(publicHoliday.date);
    result.push({
      name: publicHoliday.name,
      day: getDayFromDate(holidayDate),
    });
  });
  return result;
}
