import { Desk, erpNextTransportationProfile } from "@/data-types";
import erpnextApi from "@/rest/erpnext-api";

const workplaceStore = {
  state: () => ({
    desks: new Map<string, Desk>(),
    transportationProfiles: [],
    wasDeskWidgetClicked: false,
  }),
  getters: {
    getDesks(state: any) {
      return state.desks;
    },
    getTransportationProfiles(state: any) {
      return state.transportationProfiles;
    },
    getDeskWidgetClickedInfo(state: any) {
      return state.wasDeskWidgetClicked;
    },
  },
  mutations: {
    setDesks(state: any, desks: Desk[]) {
      for (const desk of desks) state.desks.set(desk.name, desk);
    },
    setTransportationProfiles(state: any, profiles: erpNextTransportationProfile[]) {
      state.transportationProfiles.length = 0;
      state.transportationProfiles.push(...profiles);
    },
    setDeskWidgetClickedInfo(state: any, wasDeskWidgetClicked: any) {
      state.wasDeskWidgetClicked = wasDeskWidgetClicked;
    },
  },
  actions: {
    async fetchTransportationProfiles(context: any) {
      if (context.state.transportationProfiles.length === 0) {
        context.commit("setTransportationProfiles", await erpnextApi.getTransportationProfiles());
      }
    },
    async reloadTransportationProfiles(context: any) {
      context.commit("setTransportationProfiles", await erpnextApi.getTransportationProfiles());
    },
    async fetchDesks(context: any) {
      if (context.state.desks.size === 0) {
        context.commit("setDesks", await erpnextApi.getDesks());
      }
    },
    setDeskWidgetClickedInfo(wasDeskWidgetClicked: any) {
      wasDeskWidgetClicked.value = wasDeskWidgetClicked;
    },
  },
};

export default workplaceStore;
