<template>
  <div class="p-field p-py-0 p-px-1 p-mb-1 p-col-12 p-col-align-start" :class="[type === 'switch' && 'p-d-flex p-ai-center', 'p-md-' + width]">
    <label v-if="label && type !== 'switch'" class="p-mb-1" :for="label">{{ label }}</label>
    <span v-if="v$[identifier].$error && submitted">
      <span v-for="(error, index) of v$[identifier].$errors" :key="index">
        <small class="p-error p-pl-2">{{ error.$message }}</small>
      </span>
    </span>
    <small v-else-if="v$[identifier].$invalid && submitted" class="p-error p-pl-2">{{ v$[identifier].required.$message }}</small>
    <InputText
      v-if="type === 'text'"
      :id="label"
      v-model="v$[identifier].$model"
      :class="{ 'p-invalid': v$[identifier].$invalid && submitted }"
      :disabled="isLoading"
      @keydown.enter="$emit('save')"
      @keydown.esc="v$[identifier].$model = ''"
    />
    <Calendar
      v-else-if="type === 'calendar'"
      :id="label"
      v-model="v$[identifier].$model"
      :class="{ 'p-invalid': v$[identifier].$invalid && submitted }"
      :disabled="isLoading"
      :show-icon="true"
      :manual-input="false"
      @keydown.enter="$emit('save')"
    />
    <Calendar
      v-else-if="type === 'calendarYearPicker'"
      :id="label"
      v-model="v$[identifier].$model"
      :class="{ 'p-invalid': v$[identifier].$invalid && submitted }"
      :disabled="isLoading"
      :show-icon="true"
      :manual-input="false"
      view="year"
      date-format="yy"
      @keydown.enter="$emit('save')"
    />
    <InputSwitch
      v-else-if="type === 'switch'"
      :id="label"
      v-model="v$[identifier].$model"
      :class="{ 'p-invalid': v$[identifier].$invalid && submitted }"
      :disabled="isLoading"
      :true-value="1"
      :false-value="0"
    />
    <Dropdown
      v-else-if="type === 'dropdown'"
      :id="label"
      v-model="v$[identifier].$model"
      :class="{ 'p-invalid': v$[identifier].$invalid && submitted }"
      :disabled="isLoading"
      :options="options"
      option-label="title"
      option-value="name"
    />
    <Editor
      v-else-if="type === 'editor'"
      :id="label"
      v-model="v$[identifier].$model"
      :class="{ 'p-invalid': v$[identifier].$invalid && submitted }"
      :disabled="isLoading"
      :auto-resize="true"
    />
    <Textarea
      v-else-if="type === 'textarea'"
      :id="label"
      v-model="v$[identifier].$model"
      :class="{ 'p-invalid': v$[identifier].$invalid && submitted }"
      :disabled="isLoading"
      :auto-resize="true"
    />
    <label v-if="label && type === 'switch'" class="p-ml-3 p-mt-1" :for="label">{{ label }}</label>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { loading, validate } from "@/keys";
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import InputSwitch from "primevue/inputswitch";
import Dropdown from "primevue/dropdown";
import Textarea from "primevue/textarea";
import Editor from "primevue/editor";

export default defineComponent({
  name: "ProfileFormField",
  components: { InputText, InputSwitch, Calendar, Dropdown, Textarea, Editor },
  props: {
    label: { type: String, required: true, default: "" },
    split: { type: Boolean, required: false, default: false },
    width: { type: String, required: false, default: "12" },
    submitted: { type: Boolean, required: true, default: false },
    identifier: { type: String, required: true, default: "" },
    type: { type: String, required: false, default: "text" },
    options: { type: Array, required: false, default: () => [] },
    isChanged: Boolean,
  },
  emits: ["save"],
  setup() {
    const v$ = inject(validate);
    const isLoading = inject(loading);
    return { v$, isLoading };
  },
});
</script>

<style scoped></style>
