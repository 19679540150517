<template>
  <div class="educationBox">
    <div class="educationHeader">
      <ProgressSpinner v-if="isLoading" class="p-d-flex p-jc-center" />
      <div v-if="!isLoading">
        <span v-if="!isLoading" class="caption">Ausbildung</span>
      </div>
      <div v-if="!isLoading">
        <Button v-if="!noEntries && openEducationPanel" style="margin-right: 1rem" icon="pi pi-plus" @click="toggleEmptyDialog" />
        <Button :icon="getButtonIcon()" @click="toggleEducationPanel" />
      </div>
    </div>

    <div v-if="openEducationPanel && !isLoading">
      <div v-for="(entry, index) in educationData" :key="index" class="educationContainer">
        <div class="info">
          <span v-if="noEntries">Keine Daten</span>
          <span v-if="!noEntries" class="text">{{ entry.school_univ }}</span>

          <span v-if="!noEntries" class="text">{{ entry.qualification }}</span>
          <span v-if="!noEntries" class="text">{{ entry.year_of_passing }}</span>
        </div>
        <div class="card flex justify-content-center buttons">
          <Button v-if="!noEntries" class="editButton" label="Bearbeiten" icon="pi pi-pencil" @click="toggleDialog(entry)" />
          <Button v-if="!noEntries" label="Löschen" icon="pi pi-trash" @click="(openDeleteValidation = true), setEntryToDelete(entry)" />
          <Button v-if="noEntries" label="Anlegen" icon="pi pi-plus" @click="toggleEmptyDialog" />
        </div>
        <base-pop-up v-if="openDeleteValidation" :pop-up-message="deleteValidationMessage">
          <Button label="Ja!" style="margin-right: 1rem" @click="deleteProfileEducation" />
          <Button label="Nein!" @click="openDeleteValidation = false" />
        </base-pop-up>
      </div>
      <education-dialog v-if="isOpen" :education-data="entryData" @data-changed="getProfileEducation" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, provide, ref } from "vue";
import { dialog } from "@/keys";
import erpnextApi from "@/rest/erpnext-api";
import { erpNextProfileEducation } from "@/data-types";
import { useToast } from "primevue/usetoast";
import EducationDialog from "./EducationDialog.vue";
import BasePopUp from "@/ui/BasePopUp.vue";
import ProgressSpinner from "primevue/progressspinner";
import Button from "primevue/button";
import { showErrorToast, showSuccessToast } from "@/utils/Toast";

export default defineComponent({
  name: "EducationComponent",
  components: { EducationDialog, BasePopUp, ProgressSpinner, Button },
  setup() {
    const toast = useToast();
    const isLoading = ref(true);
    const profileEducation = ref<erpNextProfileEducation[]>();
    const noEntries = ref(false);
    const isOpen = ref(false);
    provide(dialog, isOpen);
    const educationData = ref();
    const openEducationPanel = ref(false);
    const openDeleteValidation = ref(false);
    const deleteValidationMessage = "Bist du sicher dass du das löschen willst?";
    const width = window.innerWidth;
    const isMobile = ref(false);
    const entryData = ref();
    const entryToDelete = ref();

    function toggleEducationPanel() {
      openEducationPanel.value = !openEducationPanel.value;
    }

    function toggleDialog(entry: any) {
      entryData.value = entry;
      isOpen.value = !isOpen.value;
    }

    function toggleEmptyDialog() {
      entryData.value = {
        name: "",
        school_univ: "",
        qualification: "",
        level: "",
        year_of_passing: "",
        class_per: "",
        maj_opt_subj: "",
      };
      isOpen.value = !isOpen.value;
    }

    function setEntryToDelete(entry: any) {
      entryToDelete.value = entry;
    }

    onMounted(() => {
      if (width < 1400) {
        isMobile.value = true;
      }
      getProfileEducation();
    });

    function getButtonIcon() {
      const icon = openEducationPanel.value ? "pi pi-chevron-up" : "pi pi-chevron-down";
      return icon;
    }

    async function getProfileEducation() {
      isLoading.value = true;
      await erpnextApi.getProfileEducation().then((res) => {
        profileEducation.value = res;
        profileEducation.value.sort((a: any, b: any) => {
          return a.year_of_passing - b.year_of_passing;
        });
        if (profileEducation.value.length === 0) {
          noEntries.value = true;
          isLoading.value = false;
          educationData.value = [
            {
              school_univ: "",
              qualification: "",
              level: "",
              year_of_passing: "",
              class_per: "",
              maj_opt_subj: "",
            },
          ];
        } else {
          educationData.value = profileEducation.value;
          noEntries.value = false;
        }
        isLoading.value = false;
      });
    }

    async function deleteProfileEducation() {
      isLoading.value = true;
      openDeleteValidation.value = false;
      if (profileEducation.value)
        await erpnextApi
          .deleteProfileEducation(entryToDelete.value.name)
          .then(() => {
            showSuccessToast(toast, "Ausbildung erfolgreich gelöscht");
            getProfileEducation();
            noEntries.value = true;
          })
          .catch((err: Error) => showErrorToast(toast, err.message))
          .finally(() => (isLoading.value = false));
    }

    return {
      educationData,
      isLoading,
      deleteProfileEducation,
      noEntries,
      toggleDialog,
      getProfileEducation,
      toggleEducationPanel,
      openEducationPanel,
      getButtonIcon,
      deleteValidationMessage,
      openDeleteValidation,
      isOpen,
      profileEducation,
      entryData,
      toggleEmptyDialog,
      setEntryToDelete,
    };
  },
});
</script>

<style scoped>
.educationContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--greystep);
  background-color: var(--greystep);
  border-radius: 5px;
  margin-top: 0.5rem;
}

.text {
  margin-bottom: 10px;
  font-size: 18px;
  color: var(--white);
  border-bottom: 1px solid var(--grey);
}

.editButton {
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.buttons {
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
}

.info {
  width: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--grey);
}

.educationBox {
  width: 100%;
  margin-top: 1rem;
  padding: 0.5rem;
  border: 1px solid #969696;
  border-radius: 3px;
  margin-left: 3px;
}

.educationHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.caption {
  font-size: 18px;
}
</style>
