<template>
  <Dialog
    id="WorkplaceDeskBlockingDialog"
    v-model:visible="visible"
    :dismissable-mask="true"
    :modal="true"
    header="Tischreservierung"
    :closable="false"
    :close-on-escape="true"
  >
    <template #header>
      <div class="inline-flex align-items-center justify-content-center gap-2">
        <span class="font-bold white-space-nowrap">Blockierung von {{ selectedDesk.title }}.</span>
      </div>
    </template>
    <InputText id="reasonInput" v-model="reason" :disabled="isLoading" autofocus class="flex-auto" placeholder="Grund" @keydown.enter="onSave" />
    <template #footer>
      <Button :disabled="isLoading" label="Schließen" icon="pi pi-times" @click="closeAndClean" />
      <Button :disabled="isLoading || isInputEmpty()" label="Speichern" icon="pi pi-check" @click="onSave" />
    </template>
  </Dialog>
</template>
<script setup lang="ts">
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import { Desk, DeskBlocking } from "@/data-types.ts";
import { defineProps, PropType, ref } from "vue";
import erpnextApi from "@/rest/erpnext-api.ts";
import moment from "moment";
import { showErrorToast, showSuccessToast } from "@/utils/Toast.ts";
import { useToast } from "primevue/usetoast";

const props = defineProps({
  selectedDesk: { required: true, type: Object as PropType<Desk> },
  dateToBlockDesk: { required: true, type: Object as PropType<Date> },
});
const emit = defineEmits(["created-desk-blocking"]);

const toast = useToast();
const reason = ref("");
const visible = defineModel<boolean>("visible");
const isLoading = ref(false);

function closeAndClean() {
  visible.value = false;
  reason.value = "";
}

function isInputEmpty(): boolean {
  return reason.value.length <= 0;
}

async function onSave() {
  isLoading.value = true;
  const deskBlocking: DeskBlocking = {
    desk: props.selectedDesk?.name,
    reason: reason.value,
    date: moment(props.dateToBlockDesk).format("YYYY-MM-DD"),
  };

  erpnextApi
    .addDeskBlocking(deskBlocking)
    .then(() => {
      showSuccessToast(toast, props.selectedDesk?.title + " erfolgreich blockiert.");
      emit("created-desk-blocking", props.selectedDesk.name, reason.value);
    })
    .catch((err: Error) => {
      showErrorToast(toast, err.message);
    })
    .finally(() => {
      closeAndClean();
      isLoading.value = false;
    });
}
</script>
