<template>
  <div class="headerBox noBorder dayColumn">
    Gesamt <br />
    (BP)
  </div>

  <div v-for="index in 3" :key="index" class="box" />

  <!-- total work time -->
  <div class="box">
    <div class="p-text-center">
      {{ totalWorkTimeString }}
    </div>
  </div>

  <template v-if="isProjectShowing">
    <div v-for="(item, positionHours, index) in positionHours" :key="index">
      <div v-if="item.has_comment" class="box projectBox commentBox">
        <div class="p-text-center">
          {{ item.hours }}
        </div>
      </div>
      <div v-else>
        <div class="box projectBox">
          {{ item.hours }}
        </div>
      </div>
    </div>
  </template>
</template>

<script lang="ts">
import { BookingDay, BookingDayPosition } from "@/data-types";
import { buildTimeString } from "@/utils/Helper";

export default {
  name: "WeeklyTotal",
  components: {},
  props: {
    days: {
      type: Array,
      required: true,
    },
    isProjectShowing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props: any) {
    const daysArray = props.days as BookingDay[];
    // TODO: Why should we copy object via json?
    const positionHours: BookingDayPosition[] = JSON.parse(JSON.stringify(daysArray[0].positionHours));
    let totalWorkTime = [0, 0];

    positionHours.forEach((positionHour) => {
      positionHour.hours = "00:00";
    });

    //adding all bp-hours
    daysArray.forEach((day) => {
      if (day.state == "Present") {
        day.positionHours.forEach((positionHour: BookingDayPosition, y) => {
          const totalHoursSplitted = positionHours[y].hours.split(/[: \\s]+/).map((x) => +x);
          const positionHoursSplitted = positionHour.hours.split(/[: \\s]+/).map((x) => +x);

          totalHoursSplitted[0] += positionHoursSplitted[0];
          totalHoursSplitted[1] += positionHoursSplitted[1];
          positionHours[y].hours = buildTimeString(totalHoursSplitted[0], totalHoursSplitted[1]);
        });
      }
    });

    const checkMinuteOverfloat = (hours: number, minutes: number) => {
      if (minutes == 60) {
        hours += 1;
        minutes = 0;
      } else if (minutes > 60) {
        const extraHours = Math.trunc(minutes / 60);
        hours += extraHours;
        minutes = minutes - extraHours * 60;
      }

      return [hours, minutes];
    };

    //check if minutes over 60 to add an extra hour
    positionHours.forEach((positionHour: BookingDayPosition) => {
      const positionsHoursSplitted = positionHour.hours.split(/[: \\s]+/).map((x) => +x);

      const validTotalTime = checkMinuteOverfloat(positionsHoursSplitted[0], positionsHoursSplitted[1]);

      totalWorkTime[0] += validTotalTime[0];
      totalWorkTime[1] += validTotalTime[1];

      positionHour.hours = buildTimeString(validTotalTime[0], validTotalTime[1]);
    });

    totalWorkTime = checkMinuteOverfloat(totalWorkTime[0], totalWorkTime[1]);
    const totalWorkTimeString = buildTimeString(totalWorkTime[0], totalWorkTime[1]);

    return {
      positionHours,
      totalWorkTimeString,
    };
  },
};
</script>

<style scoped>
.box {
  border-left: 0.1rem solid var(--tableBackground);
}
</style>
