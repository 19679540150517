<template>
  <div class="extWorkBox">
    <div class="extWorkHeader">
      <ProgressSpinner v-if="isLoading" class="p-d-flex p-jc-center" />
      <div v-if="!isLoading">
        <span class="caption">Projekte bei pep.digital</span>
      </div>
      <div v-if="!isLoading">
        <Button v-if="openInternalWorkPanel" icon="pi pi-plus" style="margin-right: 1rem" @click="toggleEmptyDialog" />
        <Button :icon="openInternalWorkPanel ? 'pi pi-chevron-up' : 'pi pi-chevron-down'" @click="toggleInternalWorkPanel" />
      </div>
    </div>
    <div v-if="openInternalWorkPanel && !isLoading">
      <div v-if="internalWorkHistory.length == 0" class="extWorkContainer">
        <div class="info">
          <span>Keine Daten</span>
        </div>
      </div>
      <div v-for="(entry, index) in internalWorkHistory" :key="index" class="extWorkContainer">
        <div class="info">
          <span class="text">
            {{ knownProjects[entry.project].project_name }}
            ({{ entry.from_date.toLocaleDateString("de") }} - {{ entry.to_date.toLocaleDateString("de") }})
          </span>
          <span class="text">{{ entry.designation_desc }}</span>
          <span class="text">{{ entry.tasks }}</span>
        </div>
        <div class="card flex justify-content-center buttons">
          <Button class="editButton" icon="pi pi-pencil" label="Bearbeiten" @click="toggleDialog(entry)" />
          <Button icon="pi pi-trash" label="Löschen" @click="(openDeleteValidation = true), setEntryToDelete(entry)" />
        </div>
        <base-pop-up v-if="openDeleteValidation" :pop-up-message="deleteValidationMessage">
          <Button label="Ja!" style="margin-right: 1rem" @click="deleteInternalWorkHistory" />
          <Button label="Nein!" @click="openDeleteValidation = false" />
        </base-pop-up>
      </div>
      <internal-work-history-dialog
        v-if="isOpen"
        :is-new-entry="isNewEntry"
        :internal-work-history="entryData"
        :known-projects="knownProjects"
        @data-changed="getProfileInternalWorkHistory"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, provide, ref } from "vue";
import { dialog } from "@/keys";
import erpnextApi from "@/rest/erpnext-api";
import { erpNextProfileInternalWorkHistory, erpWorkHistoryProject } from "@/data-types";
import { useToast } from "primevue/usetoast";
import InternalWorkHistoryDialog from "./InternalWorkHistoryDialog.vue";
import BasePopUp from "@/ui/BasePopUp.vue";
import ProgressSpinner from "primevue/progressspinner";
import Button from "primevue/button";
import { showErrorToast, showSuccessToast } from "@/utils/Toast";

export default defineComponent({
  name: "ExternalWorkHistory",
  components: { InternalWorkHistoryDialog, BasePopUp, ProgressSpinner, Button },
  setup() {
    const toast = useToast();
    const isLoading = ref(true);
    const internalWorkHistory = ref<erpNextProfileInternalWorkHistory[]>();
    const knownProjects = ref<{ [key: string]: erpWorkHistoryProject }>();
    const isOpen = ref(false);
    provide(dialog, isOpen);
    const openInternalWorkPanel = ref(false);
    const openDeleteValidation = ref(false);
    const deleteValidationMessage = "Bist du sicher dass du das löschen willst?";
    const width = window.innerWidth;
    const isMobile = ref(false);
    const entryData = ref();
    const entryToDelete = ref();
    const isNewEntry = ref(false);

    function toggleInternalWorkPanel() {
      openInternalWorkPanel.value = !openInternalWorkPanel.value;
    }

    function toggleDialog(entry: any) {
      entryData.value = entry;
      isNewEntry.value = false;
      isOpen.value = !isOpen.value;
    }

    function toggleEmptyDialog() {
      entryData.value = {
        name: "",
        designation_desc: "",
        project: "",
        project_content: "",
        tasks: "",
        keywords: "",
        from_date: undefined,
        to_date: undefined,
        team_size: "",
        customer_category: "",
      };
      isNewEntry.value = true;
      isOpen.value = !isOpen.value;
    }

    function setEntryToDelete(entry: any) {
      entryToDelete.value = entry;
    }

    onMounted(() => {
      if (width < 1400) isMobile.value = true;
      receiveProfileInternalWorkHistory();
    });

    function receiveProfileInternalWorkHistory() {
      isLoading.value = true;
      erpnextApi
        .getAvailableWorkHistoryProjects()
        .then((res) => (knownProjects.value = Object.fromEntries(res.map((x) => [x.name, x]))))
        .then(() =>
          erpnextApi.getInternalWorkHistory().then((res) => {
            internalWorkHistory.value = res;
            internalWorkHistory.value.sort((a: any, b: any) => a.to_date.getTime() - b.to_date.getTime());
            isLoading.value = false;
          }),
        );
    }

    async function deleteInternalWorkHistory() {
      isLoading.value = true;
      openDeleteValidation.value = false;
      if (internalWorkHistory.value)
        await erpnextApi
          .deleteInternalWorkHistory(entryToDelete.value.name)
          .then(() => {
            showSuccessToast(toast, "Projekt erfolgreich gelöscht");
            receiveProfileInternalWorkHistory();
          })
          .catch((err: Error) => showErrorToast(toast, err.message))
          .finally(() => (isLoading.value = false));
    }

    return {
      internalWorkHistory,
      isLoading,
      getProfileInternalWorkHistory: receiveProfileInternalWorkHistory,
      deleteInternalWorkHistory,
      toggleDialog,
      toggleInternalWorkPanel,
      openInternalWorkPanel,
      deleteValidationMessage,
      openDeleteValidation,
      entryData,
      isOpen,
      setEntryToDelete,
      toggleEmptyDialog,
      isNewEntry,
      knownProjects,
    };
  },
});
</script>

<style scoped>
.extWorkContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--greystep);
  background-color: var(--greystep);
  border-radius: 5px;
  margin-top: 0.5rem;
}

.text {
  margin-bottom: 10px;
  font-size: 18px;
  color: var(--white);
  border-bottom: 1px solid var(--grey);
}

.editButton {
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.buttons {
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
}

.info {
  width: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--grey);
}

.extWorkBox {
  width: 100%;
  margin-top: 1rem;
  padding: 0.5rem;
  border: 1px solid #969696;
  border-radius: 3px;
  margin-left: 3px;
}

.extWorkHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.caption {
  font-size: 18px;
}
</style>
