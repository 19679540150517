import erpnext from "@/rest/erpnext-api";
import { Employee } from "@/data-types";

export interface Credentials {
  username: string;
  password: string;
}

export default {
  async doLogin(context: any, credentials: Credentials): Promise<boolean> {
    try {
      await erpnext.login(credentials.username, credentials.password);
      const employee: Employee | null = await erpnext.getEmployee();
      context.commit("setLoggedInUser", employee);
      return true;
    } catch (e) {
      context.commit("setLoggedInUser", null);
      return false;
    }
  },
};
