<template>
  <BaseCard>
    <MonthControl
      title="Kalender"
      :current-date-ro="myDates.currentDate"
      :disable-previous-month="isLoading"
      :disable-next-month="isLoading"
      @change-date="componentDateChanged"
      @change-month-backward="changeMonthEvent(-1)"
      @change-month-forward="changeMonthEvent(1)"
    />
    <div class="p-d-flex p-jc-between p-flex-wrap p-ai-center p-mt-2">
      <div class="p-d-flex p-flex-wrap p-ai-center">
        <div class="p-my-2">
          <Checkbox v-model="filter.showMyself" :disabled="isLoading" input-id="showMyself" class="p-mr-2" :binary="true" />
          <label for="showMyself" class="p-mr-3">Selbst</label>
        </div>
        <div class="p-my-2">
          <Dropdown v-model="filter.favorite" :disabled="isLoading" :options="favorites" class="p-mr-2" style="width: 160px" />
        </div>
      </div>
      <div class="searchField">
        <InputText
          v-model="filter.searchTerm"
          :disabled="isLoading"
          type="text"
          class="p-inputtext-sm p-my-2"
          placeholder="Suche"
          @keydown.esc="filter.searchTerm = ''"
        />
        <i v-if="filter.searchTerm" style="font-size: 0.5rem" class="pi pi-times clearIcon" @click="filter.searchTerm = ''" />
      </div>
    </div>
    <ProgressSpinner v-if="isLoading" class="p-d-flex p-jc-center" />
    <div v-else>
      <!--The days of the Month in form of numbers are displayed here-->
      <CalendarRow :show-days="true" :public-holidays="currentPublicHolidays" :amount-of-days="myDates.amountOfDays" />
      <!--The names of the week days are displayed with this-->
      <CalendarRow :show-names="true" :month="myDates.toMonth" :year="myDates.toYear" :amount-of-days="myDates.amountOfDays" />
      <!--This is for displaying the employees-->
      <CalendarRow :show-today-triangle="true" :today="today" :amount-of-days="myDates.amountOfDays" />
      <div v-if="filteredAbsence.length !== 0">
        <CalendarRow
          v-for="(absence, key) in filteredAbsence"
          :key="key"
          :today="today"
          :absence-dates="absence.dates"
          :public-holidays="currentPublicHolidays"
          :amount-of-days="myDates.amountOfDays"
          :developer-name="absence.fullname"
        />
      </div>
      <div v-else class="p-mt-2">Keine Einträge gefunden</div>
    </div>
    <CalendarLegend />
  </BaseCard>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from "vue";
import erpnextApi from "@/rest/erpnext-api";
import { AbsenceEvent, calendarFilter, CalendarPublicHoliday, EmployeeAbsenceEvent } from "@/data-types";
import { getMonthFromDate, getWorkdayForDate, getYearFromDate } from "@/utils/Helper";
import CalendarRow from "@/features/calendar/components/CalendarRow.vue";
import BaseCard from "@/ui/BaseCard.vue";
import ProgressSpinner from "primevue/progressspinner";
import MonthControl from "@/ui/MonthControl.vue";
import CalendarLegend from "@/features/calendar/components/CalendarLegend.vue";
import store from "@/store";
import Checkbox from "primevue/checkbox";
import {
  addAmountOfMonths,
  getAmountOfDays,
  getTodayDay,
  handleAbsenceEvents,
  holidaysBasedOnSelection,
  isCurrentMonth,
} from "@/features/calendar/utils/Calendar";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";

const isLoading = ref(true);
const favorites = ref<string[]>([]);
const employees = computed(() => store.getters.getAllEmployeesAsMap);
const myself = computed(() => store.getters.getEmployee);
const currentOffsites = ref<AbsenceEvent[]>([]);
const publicHolidays = computed(() => store.getters.getPublicHolidays);
const myDates = reactive({
  currentDate: new Date(),
  toMonth: "",
  toYear: "",
  amountOfDays: 0,
  dayNames: "",
});
const currentPublicHolidays = ref<CalendarPublicHoliday[]>([]);
const currentEmployeesAbsences = ref<EmployeeAbsenceEvent[]>([]);
const currentEmployeesOffsites = ref<EmployeeAbsenceEvent[]>([]);

function componentDateChanged(newDate: Date) {
  myDates.currentDate = newDate;
}

const today = computed(() => (isCurrentMonth(myDates.currentDate) ? getTodayDay() : 0));

const filter = reactive<calendarFilter>({
  showMyself: true,
  searchTerm: "",
  favorite: "Standard",
});

const filteredAbsence = computed(() => {
  const tmpHoliday: EmployeeAbsenceEvent[] = holidaysBasedOnSelection(currentEmployeesAbsences.value, filter, myself.value?.full_name);
  const tmpOffsites: EmployeeAbsenceEvent[] = holidaysBasedOnSelection(currentEmployeesOffsites.value, filter, myself.value?.full_name);

  return getHolidaysAndOffsitesCombined(tmpHoliday, tmpOffsites);
});

function getHolidaysAndOffsitesCombined(holidays: EmployeeAbsenceEvent[], offsites: EmployeeAbsenceEvent[]) {
  const tmpHoliday = addOffsiteToEmployeeAbsences(holidays, false);
  const tmpOffsites = addOffsiteToEmployeeAbsences(offsites, true);

  const mergedAbsenceArray = [...tmpHoliday, ...tmpOffsites];

  const distinctAbsenceMap: Map<string, EmployeeAbsenceEvent> = new Map();
  mergedAbsenceArray.forEach((employee) => {
    if (distinctAbsenceMap.has(employee.name)) {
      distinctAbsenceMap.get(employee.name)?.dates.push(...employee.dates);
    } else {
      distinctAbsenceMap.set(employee.name, {
        ...employee,
        dates: [...employee.dates],
      });
    }
  });
  return Array.from(distinctAbsenceMap.values());
}

function addOffsiteToEmployeeAbsences(employeesAbsences: EmployeeAbsenceEvent[], isOffsite: boolean): EmployeeAbsenceEvent[] {
  employeesAbsences.forEach((element) => {
    element.dates.forEach((date) => {
      date.isOffsite = isOffsite;
    });
  });
  return employeesAbsences;
}

onMounted(async () => {
  await store.dispatch("fetchFavoriteEmployees");
  favorites.value.push("Alle");
  favorites.value.push("Mit Urlaub");
  favorites.value.push("Ohne Urlaub");
  for (const key of store.getters.getFavoriteEmployees.keys()) {
    favorites.value.push(key);
    favorites.value.sort();
  }
  await store
    .dispatch("fetchAllEmployees")
    .then(async () => {
      setDates();
      await fetchAllDates();
    })
    .catch(() => (isLoading.value = true));
});

watch(
  () => myDates.currentDate,
  () => {
    setDates();
    fetchAllDates();
  },
);

function setDates() {
  myDates.toMonth = getMonthFromDate(myDates.currentDate);
  myDates.toYear = getYearFromDate(myDates.currentDate);
  myDates.amountOfDays = getAmountOfDays(myDates.currentDate);
  myDates.dayNames = getWorkdayForDate(myDates.currentDate);
}

function changeMonthEvent(amount: number) {
  myDates.currentDate = addAmountOfMonths(myDates.currentDate, amount);
}

async function fetchCurrentPublicHolidays() {
  await store.dispatch("fetchPublicHolidays", { month: myDates.toMonth, year: myDates.toYear });
  currentPublicHolidays.value = publicHolidays.value.get(myDates.toMonth + myDates.toYear);
}

async function fetchAllDates() {
  isLoading.value = true;

  fetchCurrentPublicHolidays().then(() => {
    erpnextApi.getAbsences(myDates.toMonth, myDates.toYear).then((data: AbsenceEvent[]) => {
      currentEmployeesAbsences.value = handleAbsenceEvents(data, myself.value ? myself.value.name : "", employees.value);
      isLoading.value = false;
    });
  });

  erpnextApi.getOffsites(myDates.toMonth, myDates.toYear).then((data) => {
    currentEmployeesOffsites.value = handleAbsenceEvents(data, myself.value ? myself.value.name : "", employees.value);
  });
}
</script>

<style scoped>
.searchField {
  position: relative;
}

.clearIcon {
  border-radius: 50%;
  padding: 0.2rem;
  background-color: var(--dark);
  position: absolute;
  top: 50%;
  left: 90%;
  transform: translate(-50%, -50%);
}

.clearIcon:hover {
  cursor: pointer;
}
</style>
