<template>
  <!--On desktop only text and on mobile onClick to open the desk plan Dialog-->
  <div
    v-if="(booking.workplace === 'office' && !dayDisabled && isOfficePlanActive) || (booking.workplace === 'office' && !dayDisabled && showToggleIcon)"
    class="p-link text-primary p-my-2 p-ml-sm-3"
    @click="changeDeskVisibility"
  >
    {{ tableMessage }}
  </div>
  <div
    v-if="booking.workplace === 'office' && !dayDisabled && !isOfficePlanActive && !showToggleIcon"
    class="p-link text-primary p-my-2 p-ml-sm-3"
    @click="onSwitchDesk"
  >
    {{ tableMessage }}
  </div>

  <!--This is the code for the default website in a desktop browser-->
  <!-- This is the workplace desk menu item -->
  <div v-if="!dayDisabled" class="mb-3 default-website-container">
    <div v-if="isOfficePlanActive" class="default-website-container">
      <DeskColorLegend />
    </div>
    <div v-if="isOfficePlanActive" class="default-website-container">
      <TooltipWrapper content="Tischbuchung löschen">
        <Button v-if="isOfficePlanActive" class="position: relative" icon="pi pi-trash" @click="onDeskChanged('')" />
      </TooltipWrapper>
    </div>
    <div>
      <TooltipWrapper content="Tischplan aufklappen">
        <Button
          v-if="showToggleIcon"
          :class="{ 'button-desk-set': isOfficePlanActive, 'button-desk-not-set': !isOfficePlanActive }"
          :icon="getButtonIcon()"
          @click="changeDeskVisibility"
        />
      </TooltipWrapper>
    </div>
  </div>

  <div v-if="isOfficePlanActive && !dayDisabled" class="desk-selection-container">
    <img class="office-img" src="/src/assets/img/office.png" alt="office_plan" />
    <ProgressSpinner v-if="isLoading" />
    <div v-for="deskState in deskStates" :key="deskState.name">
      <div v-if="deskState.available">
        <button
          id="freeDesk"
          class="desk-button freeDesk"
          :style="getDeskPos(deskState.name)"
          @contextmenu="onFreeTableRightClick($event, desks.get(deskState.name) || ({} as Desk))"
          @click="onDeskChanged(deskState.name)"
        >
          {{ getDeskTitle(deskState, desks) }}<br />{{ getDeskStatusText(deskState, employees) }}

          <div v-tooltip.top="getDeskTooltip(desks, deskState, employees)" class="deskTooltip" type="text" />
        </button>
      </div>
      <div v-else-if="isMyDesk(deskState)">
        <button id="myDesk" :disabled="true" class="desk-button myDesk" :style="getDeskPos(deskState.name)">
          {{ getDeskTitle(deskState, desks) }}<br />{{ getDeskStatusText(deskState, employees) }}

          <div v-tooltip.top="getDeskTooltip(desks, deskState, employees)" class="deskTooltip" type="text" />
        </button>
      </div>
      <div v-else-if="isDeskBlockedByMe(deskState)">
        <button
          id="deskBlockedByMe"
          class="desk-button deskBlockedByUserEmployee"
          :style="getDeskPos(deskState.name)"
          @contextmenu="onMyBlockedDeskRightClick($event, desks.get(deskState.name) || ({} as Desk))"
        >
          {{ getDeskTitle(deskState, desks) }}<br />{{ getDeskStatusText(deskState, employees) }}

          <div v-tooltip.top="getDeskTooltip(desks, deskState, employees)" class="deskTooltip" type="text" />
        </button>
      </div>
      <div v-else-if="isDeskBlockedByOther(deskState)">
        <button id="blockedByOther" :disabled="true" class="desk-button deskBlockedByOther" :style="getDeskPos(deskState.name)">
          {{ getDeskTitle(deskState, desks) }}<br />{{ getDeskStatusText(deskState, employees) }}

          <div v-tooltip.top="getDeskTooltip(desks, deskState, employees)" class="deskTooltip" type="text" />
        </button>
      </div>
      <div v-else-if="!deskState.available">
        <button id="unavailableDesk" :disabled="true" class="desk-button deskUnavailable" :style="getDeskPos(deskState.name)">
          {{ getDeskTitle(deskState, desks) }}<br />{{ getDeskStatusText(deskState, employees) }}

          <div v-tooltip.top="getDeskTooltip(desks, deskState, employees)" class="deskTooltip" type="text" />
        </button>
      </div>
    </div>
    <ContextMenu ref="menu" :model="items" :style="'background: var(--agnosticBlack)'" />
    <WorkplaceDeskBlockingDialog
      v-model:visible="isBlockingDialogActive"
      :selected-desk="selectedDesk"
      :date-to-block-desk="currentBooking.date"
      @created-desk-blocking="handleCreatedDeskBlocking"
    />
  </div>

  <!--This Dialog is only accessed on the mobile phone or on small websites-->
  <!-- don't change style of Dialog -->
  <Dialog v-model:visible="displayChooseDesk" :modal="true" :dismissable-mask="true" style="max-width: 90%">
    <template #header>
      <WorkplaceDate :booking="booking" />
    </template>
    <div style="flex-direction: column; align-items: normal">
      <div style="margin-bottom: 0.4rem">
        <Button icon="pi pi-trash" label="Tischbuchung löschen" @click="onDeskChanged('')" />
      </div>
      <div style="margin-bottom: 0.4rem">
        <DeskColorLegend />
      </div>
    </div>
    <div class="office-img-small-devices">
      <img class="office-img" src="/src/assets/img/office.png" alt="office_plan" />
      <ProgressSpinner v-if="isLoading" />
      <div v-for="deskState in deskStates" :key="deskState.name">
        <div v-if="deskState.available">
          <button
            id="freeDesk"
            class="desk-button freeDesk"
            :style="getDeskPos(deskState.name)"
            @contextmenu="onFreeTableRightClick($event, desks.get(deskState.name) || ({} as Desk))"
            @click="onDeskChanged(deskState.name)"
          >
            {{ getDeskTitle(deskState, desks) }}<br />{{ getDeskStatusText(deskState, employees) }}

            <div v-tooltip.top="getDeskTooltip(desks, deskState, employees)" class="deskTooltip" type="text" />
          </button>
        </div>
        <div v-else-if="isMyDesk(deskState)">
          <button id="myDesk" :disabled="true" class="desk-button myDesk" :style="getDeskPos(deskState.name)">
            {{ getDeskTitle(deskState, desks) }}<br />{{ getDeskStatusText(deskState, employees) }}

            <div v-tooltip.top="getDeskTooltip(desks, deskState, employees)" class="deskTooltip" type="text" />
          </button>
        </div>
        <div v-else-if="isDeskBlockedByMe(deskState)">
          <button
            id="deskBlockedByMe"
            class="desk-button deskBlockedByUserEmployee"
            :style="getDeskPos(deskState.name)"
            @contextmenu="onMyBlockedDeskRightClick($event, desks.get(deskState.name) || ({} as Desk))"
          >
            {{ getDeskTitle(deskState, desks) }}<br />{{ getDeskStatusText(deskState, employees) }}

            <div v-tooltip.top="getDeskTooltip(desks, deskState, employees)" class="deskTooltip" type="text" />
          </button>
        </div>
        <div v-else-if="isDeskBlockedByOther(deskState)">
          <button id="blockedByOther" :disabled="true" class="desk-button deskBlockedByOther" :style="getDeskPos(deskState.name)">
            {{ getDeskTitle(deskState, desks) }}<br />{{ getDeskStatusText(deskState, employees) }}

            <div v-tooltip.top="getDeskTooltip(desks, deskState, employees)" class="deskTooltip" type="text" />
          </button>
        </div>
        <div v-else-if="!deskState.available">
          <button id="unavailableDesk" :disabled="true" class="desk-button deskUnavailable" :style="getDeskPos(deskState.name)">
            {{ getDeskTitle(deskState, desks) }}<br />{{ getDeskStatusText(deskState, employees) }}

            <div v-tooltip.top="getDeskTooltip(desks, deskState, employees)" class="deskTooltip" type="text" />
          </button>
        </div>
      </div>
      <ContextMenu ref="menu" :model="items" :style="'background: var(--agnosticBlack)'" />
      <WorkplaceDeskBlockingDialog
        v-model:visible="isBlockingDialogActive"
        :selected-desk="selectedDesk"
        :date-to-block-desk="currentBooking.date"
        @created-desk-blocking="handleCreatedDeskBlocking"
      />
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { computed, onMounted, PropType, ref, watch } from "vue";
import { Desk, DeskState, Employee, WorkplaceBooking } from "@/data-types";
import WorkplaceDate from "@/features/workspace/components/WorkplaceDate.vue";
import TooltipWrapper from "@/ui/TooltipWrapper.vue";
import erpnextApi from "@/rest/erpnext-api";
import Button from "primevue/button";
import { useStore } from "vuex";
import moment from "moment";
import Dialog from "primevue/dialog";
import ProgressSpinner from "primevue/progressspinner";
import ContextMenu from "primevue/contextmenu";
import { getDeskStatusText, getDeskTitle, getDeskTooltip, getWorkplaceTable } from "../utils/Workplace";
import { showErrorToast, showSuccessToast } from "@/utils/Toast.ts";
import { useToast } from "primevue/usetoast";
import WorkplaceDeskBlockingDialog from "@/features/workspace/components/WorkplaceDeskBlockingDialog.vue";
import DeskColorLegend from "@/features/workspace/components/DeskColorLegend.vue";

const props = defineProps({
  dayDisabled: { required: true, type: Boolean },
  booking: { required: true, type: Object as PropType<WorkplaceBooking> },
  desks: { required: true, type: Map as PropType<Map<string, Desk>> },
  employees: { required: true, type: Map as PropType<Map<string, Employee>> },
});
const emit = defineEmits(["deskSelected", "deactivateSwipe", "activateSwipe"]);
const store = useStore();
const toast = useToast();

const showToggleIcon = ref(true);
const width = window.innerWidth;
const isOfficePlanActive = ref(true);
const displayChooseDesk = ref(false);
const deskStates = ref([] as DeskState[]);
const isLoading = ref(false);
const selectedDesk = ref({} as Desk);
const isBlockingDialogActive = ref(false);
const isCancelAllowed = ref(false);
const isBookingAndBlockingAllowed = ref(false);
const menu = ref();
const items = ref([
  {
    label: "Buchen",
    icon: "pi pi-user",
    visible: isBookingAndBlockingAllowed,
    command: () => {
      onDeskChanged(selectedDesk.value.name);
    },
  },
  {
    label: "Blockieren",
    icon: "pi pi-user-edit",
    visible: isBookingAndBlockingAllowed,
    command: () => {
      isBlockingDialogActive.value = true;
    },
  },
  {
    label: "Stornieren",
    icon: "pi pi-trash",
    visible: isCancelAllowed,
    command: () => {
      onCancellation(selectedDesk.value);
    },
  },
]);
const tableMessage = computed(() => getWorkplaceTable(props.booking, props.desks));
const wasDeskWidgetClicked = computed(() => store.getters.getDeskWidgetClickedInfo);
const userEmployee = computed(() => store.getters.getEmployee);
const currentBooking = computed(() => props.booking);

watch(displayChooseDesk, (currentValue) => {
  if (!currentValue) {
    emit("activateSwipe");
  }
});

// checks if window is wide enough to show the desk plan and if not hide the desk plan and hide the toggle button
onMounted(() => {
  if (width < 1400) {
    showToggleIcon.value = false;
    isOfficePlanActive.value = false;
  }

  erpnextApi
    .getDeskStatus(currentBooking.value.date)
    .then((data) => {
      deskStates.value = data;
      syncBookingWithDeskState();
    })
    .finally(() => {
      isLoading.value = false;
    });

  openDeskPlanOnDeskWidgetClick();
});

function syncBookingWithDeskState() {
  const isADeskBookedForMe = currentBooking.value.desk != "";
  if (isADeskBookedForMe) {
    updateMyDesk();
  }
}

function updateMyDesk() {
  const myDesk = deskStates.value.find((x) => x.name === currentBooking.value.desk);
  if (myDesk) {
    myDesk.available = false;
    myDesk.block_type = "booked";
    myDesk.blocked_by = userEmployee.value.name;
  }
}

function isMyDesk(deskState: DeskState): boolean {
  const isABookedDesk = deskState.block_type === "booked";
  return !deskState.available && deskState.name === currentBooking.value.desk && isABookedDesk;
}

function isDeskBlockedByMe(deskState: DeskState): boolean {
  const isDeskBlocked = deskState.block_type === "blocked";
  const hasUserEmployeeBlockedADesk = props.employees.get(deskState.blocked_by)?.name === userEmployee.value.name;

  return !deskState.available && isDeskBlocked && hasUserEmployeeBlockedADesk;
}

function isDeskBlockedByOther(deskState: DeskState): boolean {
  const isDeskBlocked = deskState.block_type === "blocked";
  const isDeskBlockedByOther = props.employees.get(deskState.blocked_by)?.name != userEmployee.value.name;

  return !deskState.available && isDeskBlocked && isDeskBlockedByOther;
}

function getDeskPos(deskName: string) {
  const desk = props.desks.get(deskName) || { x: 0, y: 0, orientation: null };
  return {
    left: desk.x + "px",
    top: desk.y + "px",
    transform: desk.orientation === "vertical" ? "rotate(90deg)" : "",
  };
}

function openDeskPlanOnDeskWidgetClick() {
  if (width < 1400) {
    const date1 = moment(currentBooking.value.date).format("YYYY-MM-DD");
    const date2 = moment(new Date()).format("YYYY-MM-DD");
    if (date1 === date2 && wasDeskWidgetClicked.value) {
      displayChooseDesk.value = true;
      store.commit("setDeskWidgetClickedInfo", false);
      emit("deactivateSwipe");
    }
  }
}

function openContextMenuForSelectedDesk(event: Event, desk: Desk): void {
  selectedDesk.value = desk;
  menu.value.show(event);
}

function enableDeskBlocking(): void {
  isBookingAndBlockingAllowed.value = true;
  isCancelAllowed.value = !isBookingAndBlockingAllowed.value;
}

function enableDeskCancellation(): void {
  isCancelAllowed.value = true;
  isBookingAndBlockingAllowed.value = !isCancelAllowed.value;
}

function onMyBlockedDeskRightClick(event: Event, desk: Desk): void {
  enableDeskCancellation();
  openContextMenuForSelectedDesk(event, desk);
}

function onFreeTableRightClick(event: Event, desk: Desk): void {
  enableDeskBlocking();
  openContextMenuForSelectedDesk(event, desk);
}

function onSwitchDesk() {
  isLoading.value = true;
  displayChooseDesk.value = true;
  emit("deactivateSwipe");
  erpnextApi
    .getDeskStatus(currentBooking.value.date)
    .then((states) => {
      deskStates.value.length = 0;
      deskStates.value.push(...states);
    })
    .finally(() => (isLoading.value = false));
}

async function onDeskChanged(deskName: string) {
  if (deskName === currentBooking.value.desk) return;

  const booking: WorkplaceBooking = currentBooking.value;
  const oldDesk = deskStates.value.find((deskState) => deskState.name === booking.desk);
  if (oldDesk) {
    oldDesk.blocked_by = "";
    oldDesk.available = true;
    oldDesk.block_type = "";
    oldDesk.reason = "";
  }
  const newDesk = deskStates.value.find((deskState) => deskState.name === deskName);
  if (newDesk) {
    newDesk.blocked_by = userEmployee.value.name;
    newDesk.available = false;
    newDesk.block_type = "booked";
  }
  booking.desk = deskName;
  emit("deskSelected", booking);
  displayChooseDesk.value = false;
}

function checkToOpenDeskPlanToday() {
  isOfficePlanActive.value = moment(currentBooking.value.date).isSame(new Date(), "day");
}

checkToOpenDeskPlanToday();

function changeDeskVisibility() {
  isOfficePlanActive.value = !isOfficePlanActive.value;
}

function getButtonIcon() {
  return isOfficePlanActive.value ? "pi pi-chevron-up" : "pi pi-chevron-down";
}

function handleCreatedDeskBlocking(deskName: string, reason: string) {
  const reservedDeskState = deskStates.value.find((deskState) => deskState.name === deskName);
  if (reservedDeskState) {
    reservedDeskState.available = false;
    reservedDeskState.blocked_by = userEmployee.value.name;
    reservedDeskState.block_type = "blocked";
    reservedDeskState.reason = reason;
  }
}

async function onCancellation(deskToCancel: Desk) {
  await erpnextApi
    .deleteDeskBlocking(deskToCancel.name, moment(currentBooking.value.date).format("YYYY-MM-DD"))
    .then(() => {
      showSuccessToast(toast, deskToCancel.title + " erfolgreich storniert.");
      const cancelledDeskState = deskStates.value.find((deskState) => deskState.name === deskToCancel.name);
      if (cancelledDeskState) {
        cancelledDeskState.blocked_by = "";
        cancelledDeskState.available = true;
        cancelledDeskState.block_type = null;
        cancelledDeskState.reason = "";
      }
    })
    .catch((err: Error) => showErrorToast(toast, err.message));
}
</script>

<style scoped>
.desk-button {
  height: 50px;
  width: 70px;
  font-size: 12px;
  color: var(--agnosticBlack);
  position: absolute;
  margin: 6px 6px 3rem;
}

.desk-button:hover {
  opacity: 0.8;
}

.desk-button:disabled {
  opacity: 1;
}

.myDesk {
  background: var(--my-desk);
}

.freeDesk {
  background: var(--free-desk);
}

.deskBlockedByOther {
  background: var(--desk-blocked-by-other);
}

.deskUnavailable {
  background: var(--desk-unavailable);
}

.deskBlockedByUserEmployee {
  background: var(--desk-blocked-by-user-employee);
}

.deskTooltip {
  position: absolute;
  width: 70px;
  height: 50px;
  top: -2px;
  left: -3px;
}

.button-desk-set {
  margin-left: 1rem;
  position: relative;
}

.button-desk-not-set {
  margin-left: 1rem;
}

.text-primary {
  color: var(--primary);
}

.default-website-container {
  height: 39px;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: auto;
  display: flex;
  flex-direction: row;
}

.office-plan-tooltip-wrapper-container {
  display: flex;
  flex-direction: row;
}

.office-img {
  user-select: none;
  position: absolute;
  width: 1300px;
  height: 491px;
}

.office-img-small-devices {
  position: relative;
  width: 1300px;
  height: 491px;
}

.desk-selection-container {
  position: relative;
  width: 1300px;
  height: 491px;
  margin-top: 8px;
}

.smartphone {
  max-width: 90%;
}
</style>
