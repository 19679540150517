<template>
  <div class="p-d-flex p-flex-row row p-ai-center">
    <div v-if="developerName" class="name">
      {{ developerName }}
    </div>
    <CalendarDay
      v-for="(day, index) in amountOfDays"
      :key="index"
      v-tooltip.top="getDescription(day, publicHolidays)"
      :today-line="day === today && !showTodayTriangle"
      :child-class="getChildClassFromDay(day, today, showDays, showTodayTriangle, absenceDates, publicHolidays, showNames)"
    >
      <!--to display the date numbers within the displayed month-->
      {{ getDay(day, showDays, windowWidth, publicHolidays) }}

      <!--to display the names of the days within the displayed month-->
      {{ getDayNames(day, month, year, showNames, windowWidth) }}
    </CalendarDay>
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from "vue";
import CalendarDay from "@/features/calendar/components/CalendarDay.vue";
import { getChildClassFromDay, getDay, getDayNames, getDescription } from "@/features/calendar/utils/Calendar";
import { CalendarPublicHoliday, EmployeeDateAndLeaveType } from "@/data-types.ts";

interface Props {
  showDays?: boolean;
  showNames?: boolean;
  showTodayTriangle?: boolean;
  absenceDates?: EmployeeDateAndLeaveType[];
  publicHolidays?: CalendarPublicHoliday[];
  amountOfDays: number;
  developerName?: string;
  month?: string;
  year?: string;
  today?: number;
}

const props = withDefaults(defineProps<Props>(), {
  showDays: false,
  showNames: false,
  showTodayTriangle: false,
  absenceDates: () => [] as EmployeeDateAndLeaveType[],
  publicHolidays: () => [] as CalendarPublicHoliday[],
  developerName: "",
  month: "",
  year: "",
  today: 0,
});

const windowWidth = ref(window.innerWidth);
const onWidthChange = () => (windowWidth.value = window.innerWidth);
onMounted(() => window.addEventListener("resize", onWidthChange));
onUnmounted(() => window.removeEventListener("resize", onWidthChange));
</script>

<style scoped>
.row {
  position: relative;
  margin-bottom: 0.5rem;
}

.name {
  background-color: var(--darkTransparency);
  padding: 0.1rem 0.3rem;
  border-radius: 0.5rem;
  position: absolute;
  margin-left: 0.5rem;
  text-shadow: 0 0 20px var(--dark);
  color: var(--white);
  font-weight: 600;
}
</style>
