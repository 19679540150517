<template>
  <div class="p-mt-5">
    <div v-if="!isLoading">
      <Dropdown v-model="selectedGroup" :options="favoriteGroups" placeholder="Wähle eine Gruppe aus" style="min-width: 240px" />
      <Button icon="pi pi-plus" style="margin-left: 1rem" @click="showDialog" />
      <Button :disabled="selectedGroup === 'Standard'" icon="pi pi-minus" style="margin-left: 1rem" @click="deleteGroup" />
      <DataTable :value="favoritesToRender" responsive-layout="scroll" class="p-datatable-sm">
        <Column field="favorite" header="Favorit">
          <template #body="slotProps">
            <InputSwitch v-model="slotProps.data.favorite" :disabled="isLoading" @change="onFavoriteChange()" />
          </template>
        </Column>
        <Column field="full_name" header="Name" />
      </DataTable>
      <Button class="addAllFavorite" @click="toggleFavorites"> Toggle alle</Button>
      <Button
        :disabled="isSaveDisabled"
        :icon="isSaving ? 'pi pi-spin pi-spinner' : 'pi pi-save'"
        label="Speichern"
        style="float: right; margin-top: 5px"
        @click="saveGroup"
      />
      <Dialog v-model:visible="displayDialog" :dismissable-mask="true" :modal="true" :style="{ width: '20vw' }" header="Neue Favoriten-Gruppe">
        <div>
          <InputText v-model="newGroupName" class="full-width" />
        </div>
        <div>
          <Button class="p-mt-4" icon="pi pi-check" label="Hinzufügen" style="float: right" @click="addGroup" />
        </div>
      </Dialog>
    </div>
    <progress-spinner v-else />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useToast } from "primevue/usetoast";
import InputSwitch from "primevue/inputswitch";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import erpnextApi from "@/rest/erpnext-api";
import { showErrorToast, showSuccessToast } from "@/utils/Toast";
import { Employee } from "@/data-types.ts";
import { useStore } from "vuex";

const selectedGroup = ref("Standard");
const newGroupName = ref("");
const isLoading = ref(true);
const isSaving = ref(false);
const displayDialog = ref(false);
const favoriteGroups = reactive<string[]>([]);
const favoritesToRender = ref();
const isSaveDisabled = ref(true);
const toast = useToast();
const store = useStore();

const allFavorites = computed(() => store.getters.getFavoriteEmployees);

watch(selectedGroup, () => updateFavorites());

onMounted(async () => {
  await store.dispatch("fetchAllEmployees");
  await store.dispatch("fetchFavoriteEmployees");

  const groups: string[] = Array.from(store.getters.getFavoriteEmployees.keys());
  for (const group of groups) {
    favoriteGroups.push(group);
  }

  updateFavorites();
  isLoading.value = false;
});

function updateFavorites() {
  const myself = store.getters.getEmployee;
  const favoritesFromSelectedGroup = allFavorites.value.get(selectedGroup.value) ?? [];
  favoritesToRender.value = [];
  for (const employee of store.getters.getAllEmployees as Employee[]) {
    if (!employee.is_external && employee.name !== myself.name) {
      const isFavorite = favoritesFromSelectedGroup.includes(employee.name);
      favoritesToRender.value.push({
        name: employee.name,
        full_name: employee.full_name,
        favorite: isFavorite,
      });
    }
  }
  isSaveDisabled.value = true;
}

function showDialog() {
  displayDialog.value = true;
}

function doesGroupExist(groupName: string) {
  return favoriteGroups.includes(groupName);
}

function addGroup() {
  if (doesGroupExist(newGroupName.value)) {
    showErrorToast(toast, "Favoritengruppe existiert bereits.");
    return;
  }

  selectedGroup.value = newGroupName.value;
  favoriteGroups.push(newGroupName.value);
  newGroupName.value = "";
  displayDialog.value = false;
}

async function deleteGroup() {
  if (selectedGroup.value === "Standard") return;
  const groupToDelete = selectedGroup.value;
  isSaveDisabled.value = true;
  try {
    await erpnextApi.updateFavoriteEmployeeGroup(groupToDelete, []);

    const index = favoriteGroups.indexOf(groupToDelete);
    favoriteGroups.splice(index, 1);

    await store.dispatch("updateFavoriteEmployees", { group: groupToDelete, favorites: [] });

    selectedGroup.value = "Standard";
    updateFavorites();

    showSuccessToast(toast, "Favoriten geändert");
  } catch (err: any) {
    showErrorToast(toast, err.message);
  }
}

function toggleFavorites() {
  const newValue = !favoritesToRender.value[0].favorite;
  for (const entry of favoritesToRender.value) {
    entry.favorite = newValue;
  }
  isSaveDisabled.value = false;
}

async function saveGroup() {
  isSaveDisabled.value = true;
  isSaving.value = true;
  const group = selectedGroup.value;
  const names = favoritesToRender.value.filter((x: any) => x.favorite).map((x: any) => x.name);
  try {
    await erpnextApi.updateFavoriteEmployeeGroup(group, names);
    await store.dispatch("updateFavoriteEmployees", { group: group, favorites: names });
    updateFavorites();
    showSuccessToast(toast, "Favoriten geändert");
  } catch (err: any) {
    isSaveDisabled.value = false;
    showErrorToast(toast, err.message);
  } finally {
    isSaving.value = false;
  }
}

function onFavoriteChange() {
  isSaveDisabled.value = false;
}
</script>

<style scoped>
.text-primary {
  color: var(--primary);
}

.state {
  color: var(--primary);
}

.addAllFavorite {
  margin-top: 0.5rem;
  padding: 3px;
  font-size: 14px;
}
</style>
