<template>
  <Dialog
    v-model:visible="displayDialog"
    header="Berufserfahrung for pep.digital"
    :dismissable-mask="true"
    :modal="true"
    :style="{ width: isMobile ? '100vw' : '55vw' }"
  >
    <div>
      <div class="dialogContainer">
        <div class="card flex justify-content-center educationInput">
          <span>Unternehmen</span>
          <InputText v-model="extWorkHistory.company_name" type="text" />
        </div>
        <div class="card flex justify-content-center educationInput">
          <span>Position</span>
          <InputText v-model="extWorkHistory.designation" :style="{ width: isMobile ? '80vw' : '29vw' }" type="text" />
        </div>
        <div class="card flex justify-content-center educationInput">
          <span>Projektinhalt *</span>
          <Editor
            v-model="v$.project_content.$model"
            :style="{ width: isMobile ? '80vw' : '42vw' }"
            auto-resize
            rows="5"
            cols="30"
            :class="{ 'p-invalid': v$.project_content.$invalid && submitted }"
          />
          <small v-if="v$.project_content.$invalid && submitted" class="p-error">
            {{ v$.project_content.required.$message }}
          </small>
        </div>
        <div class="card flex justify-content-center educationInput">
          <span>Aufgaben *</span>
          <Editor
            v-model="v$.tasks.$model"
            :style="{ width: isMobile ? '80vw' : '42vw' }"
            auto-resize
            rows="5"
            cols="30"
            :class="{ 'p-invalid': v$.tasks.$invalid && submitted }"
          />
          <small v-if="v$.tasks.$invalid && submitted" class="p-error">
            {{ v$.tasks.required.$message }}
          </small>
        </div>
        <div class="card flex justify-content-center educationInput">
          <span>Schlüsselwörter *</span>
          <InputText
            v-model="v$.keywords.$model"
            :style="{ width: isMobile ? '80vw' : '42vw' }"
            type="text"
            :class="{ 'p-invalid': v$.keywords.$invalid && submitted }"
          />
          <small v-if="v$.keywords.$invalid && submitted" class="p-error">
            {{ v$.keywords.required.$message }}
          </small>
        </div>
        <div class="card flex justify-content-center educationInput endDate">
          <span>Zeitpunkt Projektende *</span>
          <!--Validation dafür funktioniert noch nicht-->
          <Calendar v-model="v$.end_date.$model" type="text" date-format="dd.mm.yy" :class="{ 'p-invalid': v$.end_date.$invalid && submitted }" />
          <small v-if="v$.end_date.$invalid && submitted" class="p-error">
            {{ v$.end_date.required.$message }}
          </small>
        </div>
        <div class="card flex justify-content-center educationInput">
          <span>Teamgröße *</span>
          <InputText v-model="v$.team_size.$model" type="text" :class="{ 'p-invalid': v$.team_size.$invalid && submitted }" />
          <small v-if="v$.team_size.$invalid && submitted" class="p-error">
            {{ v$.team_size.required.$message }}
          </small>
        </div>
        <div class="card flex justify-content-center educationInput">
          <span>Gesamterfahrung</span>
          <InputText v-model="extWorkHistory.total_experience" type="text" />
        </div>
        <div class="card flex justify-content-center educationInput">
          <span>Firmenkategorie *</span>
          <InputText v-model="v$.customer_category.$model" type="text" :class="{ 'p-invalid': v$.customer_category.$invalid && submitted }" />
          <small v-if="v$.customer_category.$invalid && submitted" class="p-error">
            {{ v$.customer_category.required.$message }}
          </small>
        </div>
      </div>
      <div class="editButton">
        <ProgressSpinner v-if="isLoading" class="p-d-flex p-jc-center" />
        <Button v-if="extWorkHistory.name && !isLoading" @click="updateProfileExternalWorkHistory(!v$.$invalid)"> Aktualisieren </Button>
        <Button v-if="!extWorkHistory.name && !isLoading" @click="addProfileExternalWorkHistory(!v$.$invalid)"> Hinzufügen </Button>
      </div>
    </div>
  </Dialog>
</template>
<script lang="ts">
import { defineComponent, inject, onMounted, ref, watch } from "vue";
import erpnextApi from "@/rest/erpnext-api";
import Dialog from "primevue/dialog";
import { useToast } from "primevue/usetoast";
import { dialog } from "@/keys";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import Calendar from "primevue/calendar";
import Editor from "primevue/editor";
import ProgressSpinner from "primevue/progressspinner";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import { showErrorToast, showSuccessToast } from "@/utils/Toast";
import { formatDateStringToDate, formatDateStringToERP } from "@/features/profile/utils/Profile";

export default defineComponent({
  name: "EducationDialog",
  components: { Dialog, Editor, Calendar, ProgressSpinner, Button, InputText },
  props: {
    externalWorkHistory: {
      type: Object,
      required: false,
    },
  },
  emits: ["DataChanged"],
  setup(props: any, { emit }: any) {
    const extWorkHistory = ref(props.externalWorkHistory);
    const toast = useToast();
    const isLoading = ref(false);
    const displayDialog = inject(dialog, ref(false));
    const width = window.innerWidth;
    const isMobile = ref(false);
    extWorkHistory.value.end_date = ref(formatDateStringToDate(props.externalWorkHistory.end_date));

    const rules = {
      name: {},
      designation: {},
      project_content: { required: helpers.withMessage("Bitte Projektinhalt angeben", required) },
      tasks: { required: helpers.withMessage("Bitte Aufgaben angeben", required) },
      keywords: { required: helpers.withMessage("Bitte Schlüsselwörter angeben", required) },
      total_experience: {},
      end_date: { required: helpers.withMessage("Bitte Projetende angeben", required) },
      team_size: { required: helpers.withMessage("Bitte Teamgröße angeben", required) },
      customer_category: { required: helpers.withMessage("Bitte Firmenkategorie angeben", required) },
      company_name: {},
    };

    const v$ = useVuelidate(rules, extWorkHistory);
    const submitted = ref(false);

    watch(
      () => props.externalWorkHistory,
      (newVal) => {
        extWorkHistory.value = newVal;
      },
    );

    onMounted(() => {
      if (width < 1400) {
        isMobile.value = true;
      }
    });

    async function updateProfileExternalWorkHistory(isFormValid: boolean) {
      submitted.value = true;
      if (!isFormValid) {
        showErrorToast(toast, "Bitte die Fehler in den Eingabefeldern beheben");
        return;
      }
      isLoading.value = true;
      await erpnextApi
        .updateExternalWorkHistory(
          extWorkHistory.value.name,
          extWorkHistory.value.designation,
          extWorkHistory.value.project_content,
          extWorkHistory.value.tasks,
          extWorkHistory.value.keywords,
          extWorkHistory.value.total_experience,
          formatDateStringToERP(extWorkHistory.value.end_date),
          extWorkHistory.value.team_size,
          extWorkHistory.value.customer_category,
          extWorkHistory.value.company_name,
        )
        .then(() => {
          showSuccessToast(toast, "Ausbildung erfolgreich aktualisiert");
          emit("DataChanged");
          displayDialog.value = false;
        })
        .catch((err: Error) => showErrorToast(toast, err.message))
        .finally(() => (isLoading.value = false));
    }

    async function addProfileExternalWorkHistory(isFormValid: boolean) {
      submitted.value = true;
      if (!isFormValid) {
        showErrorToast(toast, "Bitte die Fehler in den Eingabefeldern beheben");
        return;
      }
      isLoading.value = true;
      extWorkHistory.value.level = extWorkHistory.value?.name || "";
      await erpnextApi
        .addExternalWorkHistory(
          extWorkHistory.value.designation,
          extWorkHistory.value.project_content,
          extWorkHistory.value.tasks,
          extWorkHistory.value.keywords,
          extWorkHistory.value.total_experience,
          formatDateStringToERP(extWorkHistory.value.end_date),
          extWorkHistory.value.team_size,
          extWorkHistory.value.customer_category,
          extWorkHistory.value.company_name,
        )
        .then(() => {
          showSuccessToast(toast, "Ausbildung erfolgreich hinzugefügt");
          emit("DataChanged");
          displayDialog.value = false;
        })
        .catch((err: Error) => showErrorToast(toast, err.message))
        .finally(() => (isLoading.value = false));
    }

    return {
      extWorkHistory,
      isLoading,
      displayDialog,
      isMobile,
      updateProfileExternalWorkHistory,
      addProfileExternalWorkHistory,
      v$,
      submitted,
    };
  },
});
</script>

<style scoped>
.dialogContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px;
  padding: 10px;
  border: 1px solid var(--greystep);
  border-radius: 5px;
  background-color: var(--greystep);
  flex-wrap: wrap;
}

.educationInput {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

.endDate {
  margin-top: 24px;
}

span {
  margin-bottom: 5px;
  color: var(--white);
}

.editButton {
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
  margin-top: 1rem;
}

@media (max-width: 700px) {
  .dialogContainer {
    margin: 0;
    padding: 0;
  }

  .educationInput {
    margin: 0.5rem;
  }
}
</style>
