<template>
  <div class="trainingBox">
    <div class="trainingHeader">
      <ProgressSpinner v-if="isLoading" class="p-d-flex p-jc-center" />
      <div v-if="!isLoading">
        <span v-if="!isLoading" class="caption">Kurse und Zertifizierungen</span>
      </div>
      <div v-if="!isLoading">
        <Button v-if="!noEntries && openTrainingPanel" style="margin-right: 1rem" icon="pi pi-plus" @click="toggleEmptyDialog" />
        <Button :icon="getButtonIcon()" @click="toggleTrainingPanel" />
      </div>
    </div>

    <div v-if="openTrainingPanel && !isLoading">
      <div v-for="(entry, index) in trainingData" :key="index" class="trainingContainer">
        <div class="info">
          <span v-if="noEntries">Keine Daten</span>
          <span v-if="!noEntries" class="text">{{ entry.designation }}</span>

          <span v-if="!noEntries" class="text">{{ entry.duration }}</span>
          <span v-if="!noEntries" class="text">{{ formatDateStringToGerman(entry.training_date) }}</span>
        </div>
        <div class="card flex justify-content-center buttons">
          <Button v-if="!noEntries" class="editButton" label="Bearbeiten" icon="pi pi-pencil" @click="toggleDialog(entry)" />
          <Button v-if="!noEntries" label="Löschen" icon="pi pi-trash" @click="(openDeleteValidation = true), setEntryToDelete(entry)" />
          <Button v-if="noEntries" label="Anlegen" icon="pi pi-plus" @click="toggleEmptyDialog" />
        </div>
        <base-pop-up v-if="openDeleteValidation" :pop-up-message="deleteValidationMessage">
          <Button label="Ja!" style="margin-right: 1rem" @click="deleteProfileTraining" />
          <Button label="Nein!" @click="openDeleteValidation = false" />
        </base-pop-up>
      </div>
      <training-dialog v-if="isOpen" :training-data="entryData" @data-changed="getProfileTraining" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, provide, ref } from "vue";
import { dialog } from "@/keys";
import erpnextApi from "@/rest/erpnext-api";
import { erpNextProfileTraining } from "@/data-types";
import { formatDateStringToGerman } from "@/utils/Helper";
import { useToast } from "primevue/usetoast";
import TrainingDialog from "./TrainingDialog.vue";
import BasePopUp from "@/ui/BasePopUp.vue";
import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";
import { showErrorToast, showSuccessToast } from "@/utils/Toast";

export default defineComponent({
  name: "TrainingComponent",
  components: { TrainingDialog, BasePopUp, Button, ProgressSpinner },
  setup() {
    const toast = useToast();
    const isLoading = ref(true);
    const profileTraining = ref<erpNextProfileTraining[]>();
    const noEntries = ref(false);
    const isOpen = ref(false);
    provide(dialog, isOpen);
    const trainingData = ref();
    const openTrainingPanel = ref(false);
    const openDeleteValidation = ref(false);
    const deleteValidationMessage = "Bist du sicher dass du das löschen willst?";
    const width = window.innerWidth;
    const isMobile = ref(false);
    const entryData = ref();
    const entryToDelete = ref();

    function toggleTrainingPanel() {
      openTrainingPanel.value = !openTrainingPanel.value;
    }

    function toggleDialog(entry: any) {
      entryData.value = entry;
      isOpen.value = !isOpen.value;
    }

    function toggleEmptyDialog() {
      entryData.value = {
        name: "",
        school_univ: "",
        qualification: "",
        level: "",
        year_of_passing: "",
        class_per: "",
        maj_opt_subj: "",
      };
      isOpen.value = !isOpen.value;
    }

    function setEntryToDelete(entry: any) {
      entryToDelete.value = entry;
    }

    onMounted(() => {
      if (width < 1400) {
        isMobile.value = true;
      }
      getProfileTraining();
    });

    function getButtonIcon() {
      const icon = openTrainingPanel.value ? "pi pi-chevron-up" : "pi pi-chevron-down";
      return icon;
    }

    function getProfileTraining() {
      isLoading.value = true;
      erpnextApi
        .getProfileTraining()
        .then((res) => {
          profileTraining.value = res;
          profileTraining.value.sort((a: any, b: any) => {
            a.training_date = new Date(a.training_date);
            b.training_date = new Date(b.training_date);
            return a.training_date.getTime() - b.training_date.getTime();
          });
          if (profileTraining.value.length === 0) {
            noEntries.value = true;
            isLoading.value = false;
            trainingData.value = [
              {
                designation: "",
                training_date: "",
                training_type: "",
                duration: "",
              },
            ];
          } else {
            trainingData.value = profileTraining.value;
            noEntries.value = false;
          }
        })
        .catch((err: Error) => showErrorToast(toast, err.message))
        .finally(() => (isLoading.value = false));
    }

    async function deleteProfileTraining() {
      isLoading.value = true;
      openDeleteValidation.value = false;
      if (profileTraining.value)
        await erpnextApi
          .deleteProfileTraining(entryToDelete.value.name)
          .then(() => {
            showSuccessToast(toast, "Kurs oder Zertifizierung erfolgreich gelöscht");
            getProfileTraining();
            noEntries.value = true;
          })
          .catch((err: Error) => showErrorToast(toast, err.message))
          .finally(() => (isLoading.value = false));
    }

    return {
      trainingData,
      isLoading,
      deleteProfileTraining,
      noEntries,
      toggleDialog,
      getProfileTraining,
      toggleTrainingPanel,
      openTrainingPanel,
      getButtonIcon,
      deleteValidationMessage,
      openDeleteValidation,
      isOpen,
      profileTraining,
      entryData,
      toggleEmptyDialog,
      setEntryToDelete,
      formatDateStringToGerman,
    };
  },
});
</script>

<style scoped>
.trainingContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--greystep);
  background-color: var(--greystep);
  border-radius: 5px;
  margin-top: 0.5rem;
}

.text {
  margin-bottom: 10px;
  font-size: 18px;
  color: var(--white);
  border-bottom: 1px solid var(--grey);
}

.editButton {
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.buttons {
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
}

.info {
  width: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--grey);
}

.trainingBox {
  width: 100%;
  margin-top: 1rem;
  padding: 0.5rem;
  border: 1px solid #969696;
  border-radius: 3px;
  margin-left: 3px;
}

.trainingHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.caption {
  font-size: 18px;
}
</style>
