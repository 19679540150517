<template>
  <BaseCard class="maxWidth">
    <span>
      <TabMenu :model="items" :active-index="activeIndex">
        <template #item="{ item, props }">
          <router-link v-slot="{ href, navigate }" :to="item.route">
            <a :href="href" v-bind="props.action" @click="navigate">
              <span v-bind="props.icon" />
              <span v-bind="props.label">{{ item.label }}</span>
            </a>
          </router-link>
        </template>
      </TabMenu>
      <router-view v-slot="{ Component }">
        <component :is="Component" />
      </router-view>
    </span>
  </BaseCard>
</template>

<script setup lang="ts">
import BaseCard from "@/ui/BaseCard.vue";
import { useRouter } from "vue-router";
import TabMenu from "primevue/tabmenu";
import { ref } from "vue";
import { MenuItem } from "primevue/menuitem";

const router = useRouter();
const activeIndex = ref(router.currentRoute.value.path === "/visitor-overview" ? 0 : 1);
const items: MenuItem[] = [
  {
    label: "Besucher",
    icon: "pi pi-fw pi-building",
    route: "/visitor-overview",
  },
  {
    label: "Tisch Blockierung",
    icon: "pi pi-user-edit",
    route: "/visitor-overview/deskblocking",
  },
];
</script>
