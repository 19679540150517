import { AbsenceEvent, calendarFilter, CalendarPublicHoliday, Employee, EmployeeAbsenceEvent, EmployeeDateAndLeaveType, weekend } from "@/data-types";
import { getDayFromDate, getWorkdayForDate, startOfMonth } from "@/utils/Helper";
import store from "@/store";
import { getHolidayName, isPublicHoliday, isWeekend } from "@/utils/HolidayNameGrabber";
import moment from "moment";

export function isSunday(holidays: CalendarPublicHoliday[], day: number): boolean {
  return !!holidays.find((holiday) => holiday.day === day && weekend.indexOf(holiday.name) === 1);
}

//function to display the numbers within a month in the calendar
export function getDay(day: number, showDays: boolean, width: number, publicHolidays: CalendarPublicHoliday[]): string {
  if (!showDays) return "";
  if (width >= 1200) {
    return day.toString();
  } else {
    return isSunday(publicHolidays, day) ? day.toString() : "";
  }
}

//function to display the names of the week days in the calendar
export function getDayNames(day: number, month: string, year: string, showNames: boolean, width: number): string {
  if (!showNames) return "";
  if (width >= 1200) {
    const yearNum = parseInt(year);
    const monthNum = parseInt(month) - 1;
    const date = new Date(yearNum, monthNum, day);
    const name = getWorkdayForDate(date);
    return name.toString();
  } else {
    return "";
  }
}

export function getChildClassFromDay(
  day: number,
  today: number,
  showDays: boolean,
  showTodayTriangle: boolean,
  absenceDates: EmployeeDateAndLeaveType[],
  publicHolidays: CalendarPublicHoliday[],
  showNames: boolean,
): string {
  let className: string;

  if (showTodayTriangle) {
    if (day === today) {
      className = "today pi pi-caret-down";
    } else if (Math.abs(day - today) === 1) {
      className = "transparent";
    } else {
      className = "transparent";
    }
  } else if (showDays) {
    className = "numbersOnly";
  } else {
    if (isDayOff(absenceDates, day)) {
      className = selectClassFromHolidayAndWeekend(day, publicHolidays) || selectClassFromAbsenceDate(absenceDates, day) || "absenceDates";
    } else {
      const holidayNamePublicHoliday = getHolidayName(day, isPublicHoliday, publicHolidays);
      const holidayNameWeekend = getHolidayName(day, isWeekend, publicHolidays);

      if (holidayNamePublicHoliday) {
        className = "publicHoliday";
      } else if (holidayNameWeekend) {
        className = "weekend";
      } else if (showNames) {
        className = "dateNames";
      } else {
        className = "weekday";
      }
    }
  }

  return className;
}

function selectClassFromHolidayAndWeekend(day: number, publicHolidays: CalendarPublicHoliday[]) {
  if (getHolidayName(day, isPublicHoliday, publicHolidays) !== "") {
    return "absenceSplitPublicHoliday";
  } else if (getHolidayName(day, isWeekend, publicHolidays) !== "") {
    return "absenceSplitWeekend";
  }
}

function selectClassFromAbsenceDate(absenceDates: EmployeeDateAndLeaveType[], day: number) {
  const found = absenceDates.find((d) => d.date === day);
  if (found?.day_off) {
    return "dayOff";
  } else if (found?.isOffsite) {
    return "offSites";
  } else {
    return "absenceDates";
  }
}

export function isDayOff(absenceDates: EmployeeDateAndLeaveType[], day: number): boolean {
  const found = absenceDates.find((d) => d.date === day);
  return found != undefined;
}

export function holidaysBasedOnSelection(currentEmployeesAbsences: EmployeeAbsenceEvent[], filter: calendarFilter, myselfFullName: string) {
  let group: string[] | null = filter.favorite === "Alle" ? null : [];
  if (filter.favorite !== "Alle" && filter.favorite !== "Mit Urlaub" && filter.favorite !== "Ohne Urlaub") {
    group = store.getters.getFavoriteEmployees.get(filter.favorite) ?? [];
  }

  let result = currentEmployeesAbsences.filter((absence) => {
    return (
      (filter.showMyself && absence.fullname === myselfFullName) ||
      group == null ||
      group.includes(absence.name) ||
      (filter.favorite === "Mit Urlaub" && absence.dates.filter((x) => !x.day_off && !x.isOffsite).length > 0) ||
      (filter.favorite === "Ohne Urlaub" && absence.dates.filter((x) => !x.day_off && !x.isOffsite).length === 0)
    );
  });

  if (!filter.showMyself) result = result.filter((absence) => absence.fullname !== myselfFullName);
  if (filter.searchTerm) result = result.filter((absence) => absence.fullname.toLowerCase().includes(filter.searchTerm.toLowerCase()));

  return result;
}

// Absence Utils
export function handleAbsenceEvents(events: AbsenceEvent[], myself: string, employeeMapAll: Map<string, Employee>): EmployeeAbsenceEvent[] {
  const unsortedEmployeesIdArray = extractAllEmployeesWithMyselfTop(myself, employeeMapAll);
  const unsortedEmployeesIdCalendarEvents = createEmployeesCalendarEvents(events, unsortedEmployeesIdArray);
  const unsortedEmployeesCalendarEvents = replaceEmployeesIdWithFullName(unsortedEmployeesIdCalendarEvents, employeeMapAll);
  // extract myself before array is sorted -> myself always at top
  const myselfCalendarEvent = unsortedEmployeesCalendarEvents.shift();
  const sortedOthersCalendarEvents = sortAbsenceEventsByName(unsortedEmployeesCalendarEvents);
  // put myself back at the top of the array
  myselfCalendarEvent && sortedOthersCalendarEvents.unshift(myselfCalendarEvent);
  return sortedOthersCalendarEvents;
}

function createEmployeesCalendarEvents(events: AbsenceEvent[], employees: string[]): EmployeeAbsenceEvent[] {
  const employeeAbsenceEvents: EmployeeAbsenceEvent[] = [];
  employees.forEach((employee) => {
    const employeeCalenderEvent: EmployeeAbsenceEvent = { name: employee, dates: [], fullname: "" };
    events.forEach((event) => {
      const eventDate = new Date(event.date);
      event.employee === employee &&
        employeeCalenderEvent.dates.push({
          date: getDayFromDate(eventDate),
          day_off: event.day_off,
        });
    });
    employeeAbsenceEvents.push(employeeCalenderEvent);
  });
  return employeeAbsenceEvents;
}

function extractAllEmployeesWithMyselfTop(myself: string, employeeMapAll: Map<string, Employee>): string[] {
  const employees: string[] = [];
  employeeMapAll.forEach((employee) => {
    if (!employee.is_external && !employees.includes(employee.name)) {
      if (employee.name == myself) employees.unshift(employee.name);
      else employees.push(employee.name);
    }
  });
  if (employees.length === 0 || !employees.includes(myself)) employees.unshift(myself);
  return employees;
}

function replaceEmployeesIdWithFullName(unsortedEmployeesIdAndEventsArray: EmployeeAbsenceEvent[], employeeMapAll: Map<string, Employee>) {
  unsortedEmployeesIdAndEventsArray.map((employeeAbsenceEvent: EmployeeAbsenceEvent) => {
    const employee: Employee | undefined = employeeMapAll.get(employeeAbsenceEvent.name);
    if (employee !== undefined) {
      return (employeeAbsenceEvent.fullname = employee.full_name);
    }
  });
  return unsortedEmployeesIdAndEventsArray;
}

function sortAbsenceEventsByName(e: EmployeeAbsenceEvent[]): EmployeeAbsenceEvent[] {
  return e.sort((eventA, eventB) => {
    const eventANameLower = eventA.fullname.toLowerCase(),
      eventBNameLower = eventB.fullname.toLowerCase();
    if (eventANameLower < eventBNameLower) {
      return -1;
    }
    if (eventANameLower > eventBNameLower) {
      return 1;
    }
    return 0;
  });
}

export function getDescription(day: number, publicHolidays: CalendarPublicHoliday[]): string | undefined {
  const name = getHolidayName(day, isPublicHoliday, publicHolidays);
  return name !== "" ? name : undefined;
}

// Helper.ts
export function addAmountOfMonths(date: Date, amount: number): Date {
  return moment(startOfMonth(date)).add(amount, "months").toDate();
}

export function getAmountOfDays(date: Date) {
  return parseInt(moment(date).clone().endOf("month").format("D"));
}

export function isCurrentMonth(currentDate: Date): boolean {
  const formattedToday = getDateFormat(new Date(), "M-YYYY");
  const formattedCurrentDate = getDateFormat(currentDate, "M-YYYY");
  return formattedToday === formattedCurrentDate;
}

function getDateFormat(date: Date, format: string) {
  return moment(date).format(format);
}

export function getTodayDay(date?: Date): number {
  return date ? date.getDate() : new Date().getDate();
}
