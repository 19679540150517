<template>
  <div>
    <div class="p-fluid p-formgrid p-grid p-col-12 p-m-0 p-p-0">
      <slot />
    </div>
    <div class="p-d-flex p-jc-end full-width p-mt-5">
      <Button icon="pi pi-save" label="Speichern" :disabled="!isChanged || isLoading" @click="$emit('save')" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import Button from "primevue/button";
import { loading } from "@/keys";

export default defineComponent({
  name: "ProfileForm",
  components: { Button },
  props: {
    isChanged: Boolean,
  },
  emits: ["save"],
  setup() {
    const isLoading = inject(loading);
    return {
      isLoading,
    };
  },
});
</script>

<style scoped></style>
