<template>
  <div class="p-d-flex p-flex-column p-flex-md-row p-jc-center">
    <div class="p-d-flex p-ai-center p-jc-center">
      <h2>{{ title }}</h2>
    </div>
    <div class="p-d-flex p-ai-center p-jc-center p-pl-0 p-pl-md-5">
      <Button icon="pi pi-angle-double-left" class="primaryButton" style="width: 4rem" :disabled="disablePreviousMonth" @click="onChangeMonthBackward" />
      <Calendar id="calendarDate" v-model="currentDate" class="p-mx-3 myCalendar" view="month" input-class="p-text-center" date-format="mm/yy" />
      <Button icon="pi pi-angle-double-right" class="primaryButton" style="width: 4rem" :disabled="disableNextMonth" @click="onChangeMonthForward" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, toRef, watch } from "vue";
import Button from "primevue/button";
import Calendar from "primevue/calendar";

export default defineComponent({
  name: "MonthControl",
  components: { Button, Calendar },
  props: {
    disablePreviousMonth: { type: Boolean, default: true },
    disableNextMonth: { type: Boolean, default: true },
    title: { type: String, default: "" },
    currentDateRo: { type: Date, required: true },
  },
  setup(props, { emit }) {
    const currentDateRo = toRef(props, "currentDateRo");
    const currentDate = ref(new Date());
    watch(currentDate, (value) => {
      emit("changeDate", value);
    });
    watch(currentDateRo, () => {
      currentDate.value = currentDateRo.value;
    });

    function onChangeMonthBackward() {
      emit("changeMonthBackward");
    }

    function onChangeMonthForward() {
      emit("changeMonthForward");
    }

    return {
      onChangeMonthForward,
      onChangeMonthBackward,
      currentDate,
    };
  },
});
</script>

<style scoped></style>
