import { createStore } from "vuex";
import actions from "@/store/actions";
import holidayStore from "@/store/modules/holiday";
import workplaceStore from "@/store/modules/workplace";
import employeeStore from "@/store/modules/employees";
import publicHolidayStore from "@/store/modules/publicHoliday";
import { Employee } from "@/data-types";
import userProfileStore from "@/store/modules/userProfile";
import startPageStore from "@/store/modules/startPage";

export interface AppStateModel {
  loggedInUser: Employee | null;
}

export const mutations = {
  setLoggedInUser(state: AppStateModel, user: Employee | null) {
    if (user) {
      user.full_name = user.first_name + " " + user.last_name;
    }
    state.loggedInUser = user;
  },
};

export const getters = {
  isLoggedIn(state: AppStateModel) {
    return state.loggedInUser !== null;
  },
  isExternal(state: AppStateModel) {
    return state.loggedInUser?.is_external;
  },
  getEmployeesName(state: AppStateModel): string {
    if (state.loggedInUser) {
      return state.loggedInUser.name;
    } else {
      return "";
    }
  },
  getEmployee(state: AppStateModel): Employee | null {
    return state.loggedInUser;
  },
};

export default createStore({
  modules: {
    holidayStore,
    workplaceStore,
    employeeStore,
    publicHolidayStore,
    userProfileStore,
    startPageStore,
  },
  state(): AppStateModel {
    return {
      loggedInUser: null,
    };
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
});
