<template>
  <div class="p-d-flex p-flex-wrap p-ai-center p-mt-3">
    <WorkplaceDate :booking="booking" />
    <WorkplacePublicHoliday :public-holiday="getHolidayName(booking.date.getDate(), isPublicHoliday, currentPublicHolidays)" class="p-mr-2" />
    <WorkplaceComment :current-booking="booking" :day-disabled="dayDisabled" />
  </div>
  <div style="display: flex; flex-direction: row; align-items: center; flex-wrap: wrap">
    <SelectButton
      v-model="currentBooking.workplace"
      class="workplace-select"
      :options="workplaces"
      :unselectable="false"
      option-label="name"
      option-value="code"
      :disabled="dayDisabled"
      @change="onWorkplaceChange"
    />
    <Dropdown
      v-if="currentBooking.workplace === 'office' && !isExternal"
      v-model="currentBooking.transportation_profile"
      class="transportation-select"
      :options="transportations"
      :unselectable="false"
      option-label="title"
      option-value="name"
      :disabled="dayDisabled"
      panel-class="transportation-dropdown"
      @change="$emit('transportationChange', currentBooking)"
    >
      <template #value="slotProps">
        <span style="font-size: 28px">{{ transportations.find((x) => x.name === slotProps.value)?.icon ?? "⁉️" }}</span>
      </template>

      <template #option="slotProps">
        <span style="font-size: 28px">{{ slotProps.option.icon }}</span>
        <div>{{ slotProps.option.title }}</div>
      </template>
    </Dropdown>
    <Dropdown
      v-if="!dayDisabled && transportations?.find((x) => x.name === currentBooking.transportation_profile)?.primary_vehicle?.endsWith('car')"
      v-model="currentBooking.parking_lot_desired"
      class="transportation-select parking-lot-select"
      :options="parkingOptions"
      :unselectable="false"
      option-label="title"
      option-value="name"
      :disabled="dayDisabled"
      panel-class="transportation-dropdown"
      @change="$emit('parkingLotChange', currentBooking)"
    >
      <template #value="slotProps">
        <TooltipWrapper :content="parkingLotStateText()">
          <span style="font-size: 28px">🅿️</span>
          <span v-if="slotProps.value && currentBooking.has_parking_lot" style="position: absolute; right: -5px; bottom: 0; font-size: 18px">🟢</span>
          <span
            v-if="slotProps.value && !currentBooking.has_parking_lot && currentBooking.parking_lot_available"
            style="position: absolute; right: -5px; bottom: 0; font-size: 18px"
            >⏳</span
          >
          <span
            v-if="!slotProps.value || (!currentBooking.parking_lot_available && !currentBooking.has_parking_lot)"
            style="position: absolute; right: -5px; bottom: 0; font-size: 18px"
            >⛔</span
          >
        </TooltipWrapper>
      </template>
    </Dropdown>

    <slot />
    <span v-if="booking.absence_reason" :class="{ 'p-disabled': dayDisabled }" style="padding-left: 10px; align-self: center; font-weight: bold">
      {{ booking.absence_reason }}
    </span>
  </div>
  <p :class="{ 'p-disabled': dayDisabled }">
    {{ amountInOffice }}
  </p>
  <ProgressBar
    class="blocked-bar"
    :class="getProgressBarClass(dayDisabled, booking.blocked_desks)"
    :value="(booking.blocked_desks * 100) / desks.size"
    :show-value="false"
  />

  <Dialog
    v-model:visible="displayMissingTransportation"
    header="Kein Verkehrsmittel festgelegt"
    :dismissable-mask="false"
    :closable="false"
    :modal="true"
    :style="{ minWidth: '20vw', maxWidth: '400px' }"
  >
    <div>
      <span class="color-white"
        >Der Arbeitsort <span style="color: darkorange">Büro</span> kann erst ausgewählt werden, wenn ein Standard Verkehrsmittel festgelegt wurde.</span
      >
    </div>
    <div>
      <Button label="Zum Profil" icon="pi pi-arrow-right" class="p-mt-4" style="float: right" @click="router.replace('/profile/transportation')" />
    </div>
  </Dialog>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, reactive, ref } from "vue";
import { CalendarPublicHoliday, Desk, WorkplaceBooking, workplaces } from "@/data-types";
import WorkplaceDate from "@/features/workspace/components/WorkplaceDate.vue";
import SelectButton from "primevue/selectbutton";
import ProgressBar from "primevue/progressbar";
import WorkplaceComment from "./WorkplaceComment.vue";
import WorkplacePublicHoliday from "./WorkplacePublicHoliday.vue";
import { getMonthFromDate, getYearFromDate } from "@/utils/Helper";
import Dropdown from "primevue/dropdown";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Dialog from "primevue/dialog";
import TooltipWrapper from "@/ui/TooltipWrapper.vue";
import { getProgressBarClass, getWorkplaceStatusMessage } from "../utils/Workplace";
import { getHolidayName, isPublicHoliday } from "@/utils/HolidayNameGrabber";

export default defineComponent({
  name: "WorkplaceDay",
  components: {
    TooltipWrapper,
    WorkplaceDate,
    SelectButton,
    ProgressBar,
    Dropdown,
    WorkplaceComment,
    WorkplacePublicHoliday,
    Dialog,
  },
  props: {
    dayDisabled: { required: true, type: Boolean },
    booking: { required: true, type: Object as PropType<WorkplaceBooking> },
    desks: { required: true, type: Map as PropType<Map<string, Desk>> },
    publicHolidays: { required: true, type: Map as PropType<Map<string, CalendarPublicHoliday[]>> },
  },
  emits: ["workplaceChange", "transportationChange", "parkingLotChange", "deskChange"],
  setup(props, { emit }: any) {
    const store = useStore();
    const currentBooking = reactive(props.booking);
    const currentPublicHolidays = computed(() => {
      const month = getMonthFromDate(props.booking.date);
      const year = getYearFromDate(props.booking.date);
      return props.publicHolidays.get(month + year) || [];
    });
    const displayMissingTransportation = ref(false);
    const amountInOffice = computed(() => getWorkplaceStatusMessage(props.booking));
    const router = useRouter();

    const onWorkplaceChange = async () => {
      if (currentBooking.workplace !== "office" || store.getters.getEmployee?.is_external || store.getters.getUserProfile.default_transportation) {
        emit("workplaceChange", currentBooking);
      } else {
        displayMissingTransportation.value = true;
      }
    };

    function parkingLotStateText() {
      if (currentBooking.has_parking_lot) return "Dir wurde ein Parkplatz für den Tag zugewiesen";
      if (currentBooking.parking_lot_available && currentBooking.parking_lot_desired) return "Entscheidung über Parkplatz-Anfrage noch offen";
      return "Dir wurde leider kein Parkplatz zugewiesen";
    }

    return {
      currentBooking,
      workplaces,
      parkingOptions: computed(() =>
        currentBooking.has_parking_lot
          ? [
              { name: false, title: "Parkplatz freigeben" },
              {
                name: true,
                title: "Parkplatz zugewiesen",
              },
            ]
          : [
              { name: false, title: "Parkplatz nicht benötigt" },
              {
                name: true,
                title: "Parkplatz gewünscht",
              },
            ],
      ),
      transportations: computed(() => store.getters.getTransportationProfiles),
      isExternal: computed(() => store.getters.getEmployee.is_external),
      getProgressBarClass,
      getHolidayName,
      isPublicHoliday,
      currentPublicHolidays,
      amountInOffice,
      onWorkplaceChange,
      displayMissingTransportation,
      parkingLotStateText,
      router,
    };
  },
});
</script>

<style>
.yellowBar.p-progressbar .p-progressbar-value {
  background: #ffcc00;
}

.redBar.p-progressbar .p-progressbar-value {
  background: red;
}

.transportation-select {
  margin-left: 16px;
  width: 110px;
}

.transportation-dropdown .p-dropdown-items-wrapper .p-dropdown-items .p-dropdown-item {
  min-height: 0;
  padding: 6px;
}

.transportation-dropdown .p-dropdown-items-wrapper .p-dropdown-items {
  padding: 0;
}

.transportation-select .p-dropdown-trigger {
  display: none;
}

.transportation-select .p-dropdown-label {
  padding: 0;
  margin: 0;
}

.transportation-select.p-dropdown {
  border: none;
  padding: 0;
  margin-left: 8px;
  width: 40px;
}

.transportation-select.p-focus {
  box-shadow: none !important;
}

.workplace-select .p-button {
  width: 104px;
}

.transportation-select.parking-lot-select.p-dropdown {
  margin-left: 0;
}

@media (max-width: 456px) {
  .transportation-select.p-dropdown {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 768px) {
  .workplace-select .p-button {
    width: 50%;
  }

  .workplace-select .p-button {
    border: 1px solid #383838 !important;
  }
}
</style>

<style scoped>
.blocked-bar {
  height: 6px;
  margin-bottom: 3px;
  background: var(--tableBackground);
}
</style>
