import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Login from "@/features/login/Login.vue";
import Weekly from "@/features/weekly/Weekly.vue";
import Yearly from "@/features/yearly/Yearly.vue";
import store from "@/store/index";
import Calendar from "@/features/calendar/Calendar.vue";
import Workplace from "@/features/workspace/Workplace.vue";
import Profile from "@/features/profile/Profile.vue";
import Visitor from "@/features/visitor/Visitor.vue";
import ProfileOptions from "@/features/profile/components/ProfileOptions.vue";
import ProfileUserData from "@/features/profile/components/ProfileUserData.vue";
import ProfileMore from "@/features/profile/components/ProfileMore.vue";
import ProfileTransportation from "@/features/profile/components/ProfileTransportation.vue";
import YearlyTable from "@/features/yearly/components/YearlyTable.vue";
import YearlyChart from "@/features/yearly/components/YearlyChart.vue";
import ProfileCareer from "@/features/profile/components/ProfileCareer.vue";
import StartPage from "@/features/startpage/StartPage.vue";
import BirthdayList from "@/features/birthday/BirthdayList.vue";
import Inventory from "@/features/inventory/Inventory.vue";
import ProfileFavorites from "@/features/profile/components/ProfileFavorites.vue";
import YearlyAnnualStatement from "@/features/yearly/components/YearlyAnnualStatement.vue";
import UserAbsence from "@/features/absence/UserAbsence.vue";
import HolidayAndIllnessAbsence from "@/features/absence/components/absence/HolidayAndIllnessAbsence.vue";
import Organization from "@/features/organisation/Organization.vue";
import OrganigramPage from "@/features/organigram/OrganigramPage.vue";
import VisitorOverview from "@/features/VisitorOverview/VisitorOverview.vue";
import DeskBlocking from "@/features/deskBlocking/DeskBlocking.vue";

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/start",
    meta: { needsAuth: true },
  },
  {
    path: "/login",
    name: "Login",
    meta: { needsAuth: false },
    component: Login,
  },
  {
    path: "/start",
    name: "Start",
    meta: { needsAuth: true },
    component: StartPage,
  },
  {
    path: "/weekly",
    name: "Weekly",
    meta: { needsAuth: true },
    component: Weekly,
  },
  {
    path: "/yearly/:year",
    name: "/Yearly",
    meta: { needsAuth: true },
    props: true,
    component: Yearly,
    children: [
      { path: "", name: "YearlyTable", component: YearlyTable },
      { path: "chart", name: "YearlyChart", component: YearlyChart },
      { path: "statement", name: "YearlyAnnualStatement", component: YearlyAnnualStatement },
    ],
  },
  {
    path: "/absence",
    name: "UserAbsence",
    children: [
      { path: "", name: "Absence", component: HolidayAndIllnessAbsence },
      { path: "calendar", name: "Calendar", component: Calendar },
    ],
    component: UserAbsence,
  },
  {
    path: "/workplace/:week",
    name: "Arbeitsort",
    meta: { needsAuth: true },
    props: true,
    children: [{ path: "/workplace/:week/:day", component: Workplace }],
    component: Workplace,
  },
  {
    path: "/organization",
    name: "Organisation",
    meta: { needsAuth: true },
    props: true,
    children: [
      { path: "", name: "Organigram", component: OrganigramPage },
      { path: "inventory", name: "Inventar", component: Inventory },
      { path: "birthday", name: "Geburtstagsliste", component: BirthdayList },
    ],
    component: Organization,
  },
  {
    path: "/visitor-overview",
    name: "Visitor Overview",
    meta: { needsAuth: true },
    props: true,
    children: [
      { path: "", name: "Visitor", component: Visitor },
      { path: "deskblocking", name: "Tisch Blockierung", component: DeskBlocking },
    ] as RouteRecordRaw[],
    component: VisitorOverview,
  },
  {
    path: "/profile",
    name: "Profil",
    meta: { needsAuth: true },
    children: [
      { path: "", name: "ProfilChild", component: ProfileOptions },
      { path: "settings", name: "Settings", component: ProfileOptions },
      { path: "user", name: "User", component: ProfileUserData },
      { path: "more", name: "More", component: ProfileMore },
      { path: "transportation", name: "Transportation", component: ProfileTransportation },
      { path: "career", name: "Career", component: ProfileCareer },
      { path: "favorites", name: "Favorites", component: ProfileFavorites },
    ],
    component: Profile,
  },
  {
    path: "/logout",
    name: "Logout",
    meta: { needsAuth: true },
    component: Login,
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.needsAuth && !store.getters.isLoggedIn) {
    next("/login");
  } else if (to.name === "Login" && store.getters.isLoggedIn) {
    next("/");
  } else {
    next();
  }
});

export default router;
