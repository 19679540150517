<template>
  <div class="p-d-flex">
    <div class="labelBox p-jc-center p-ac-center">
      <Button
        v-for="sat in satisfactionOptions"
        :key="sat.value"
        :icon="sat.icon"
        class="p-button-text p-p-1"
        :style="{ color: getColorOfActiveButton(sat.active, sat.value) }"
        :disabled="isDisabled"
        @click="toggleButtons(sat.value, $event)"
        @keydown.enter.prevent="toggleButtons(sat.value, $event)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from "vue";
import Button from "primevue/button";
import confetti from "canvas-confetti";
import { getColorOfActiveButton, randomInRange } from "@/features/weekly/utils/Weekly";
import { BookingDay } from "@/data-types";

export default defineComponent({
  name: "SatisfactionMeter",
  emit: ["moodChanged"],
  components: {
    Button,
  },
  props: {
    day: {
      type: Object as PropType<BookingDay>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const satisfactionOptions = ref([
      {
        value: 1,
        icon: "pi pi-thumbs-up",
        active: false,
      },
      {
        value: 5,
        icon: "pi pi-thumbs-down",
        active: false,
      },
    ]);

    satisfactionOptions.value.filter((x) => x.value === props.day.mood).map((x) => (x.active = true));

    const isDisabled = computed(() => {
      return props.day.start === "" || props.day.break === "" || props.day.end === "";
    });

    function toggleButtons(value: number, element: any) {
      satisfactionOptions.value.map((options) => {
        if (options.value === value) {
          options.active = !options.active;
          if (options.active) emit("moodChanged", value);
          else emit("moodChanged", 0);
          if (options.active && value === 1) {
            element.type === "click" &&
              confetti({
                particleCount: 40,
                spread: 70,
                origin: { x: element.x / window.innerWidth, y: element.y / window.innerHeight },
                colors: ["#C8D20A", "#D23291", "#5F5F5F", "#B3B3B3"],
                angle: randomInRange(55, 125),
              });
          }
        } else {
          options.active = false;
        }
      });
    }

    return {
      satisfactionOptions,
      isDisabled,
      toggleButtons,
      getColorOfActiveButton,
    };
  },
});
</script>
