import erpnextApi from "@/rest/erpnext-api";
import { LeaveType } from "@/data-types";

export interface leaveType {
  name: string;
  title: string;
  code: number;
}

const holidayStore = {
  state: () => ({
    holidayOptions: <leaveType[]>[],
  }),
  getters: {
    getHolidayOptions(state: any): leaveType[] {
      return state.holidayOptions;
    },
  },
  mutations: {
    setHolidayOptions(state: any, holidayOptions: leaveType[]) {
      state.holidayOptions = holidayOptions;
    },
  },
  actions: {
    async fetchHolidayOptions(context: any, { forceFetch = false } = {}) {
      if (context.state.holidayOptions.length === 0 || forceFetch) {
        const holidayOptions = await erpnextApi.getHolidayOptions();
        const prettyHolidays = <leaveType[]>[];
        holidayOptions.forEach((holiday: LeaveType, index: number) => {
          let title = holiday.name;
          if (holiday.allocated ?? 0 > 0) {
            const currentYear = new Date().getFullYear();
            const remainingCurrentYear = (holiday.allocated ?? 0) - (holiday.taken ?? 0);
            title = `${holiday.name} (${currentYear}: ${remainingCurrentYear} von ${holiday.allocated ?? 0}`;
            if ((holiday.allocated_next_year ?? 0) < 1) {
              title += `, ${currentYear + 1}: nicht verfügbar)`;
            } else {
              const remainingNextYear = (holiday.allocated_next_year ?? 0) - (holiday.taken_next_year ?? 0);
              title += `, ${currentYear + 1}: ${remainingNextYear} von ${holiday.allocated_next_year} übrig)`;
            }
          }
          prettyHolidays.push({ name: holiday.name, title, code: index });
        });
        context.commit("setHolidayOptions", prettyHolidays);
      }
    },
  },
};

export default holidayStore;
