<template>
  <div class="centered">
    <Card class="p-col-12 p-sm-8 p-md-6 p-lg-5 p-xl-3">
      <template #content>
        <div class="p-d-flex p-jc-center p-mb-5">
          <img src="/assets/icon/logo.png" height="30" alt="logo pep-digital" />
        </div>
        <div v-if="microsoft365LoginUrl">
          <a :href="microsoft365LoginUrl" target="_self" class="fullWidth">
            <Button class="fullWidth p-button-info" icon="pi pi-microsoft" label="Microsoft 365" icon-pos="right" :disabled="loading" />
          </a>
          <Divider align="center" type="dashed" class="p-mt-5">
            <b>OR</b>
          </Divider>
        </div>
        <div class="form-control">
          <label for="email">E-Mail</label>
          <InputText
            id="email"
            ref="emailInputRef"
            v-model="v$.email.$model"
            type="email"
            :disabled="loading"
            :class="{ 'p-invalid': v$.email.$invalid && submitted }"
            @keydown.enter="focusPasswordInput"
          />
          <small v-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response" class="p-error">
            {{ "Bitte geben Sie eine E-Mail-Addresse ein." }}
          </small>
        </div>
        <div class="form-control">
          <label for="password">Passwort</label>
          <InputText
            id="password"
            ref="passwordInputRef"
            v-model="v$.password.$model"
            type="password"
            :disabled="loading"
            :class="{ 'p-invalid': v$.password.$invalid && submitted }"
            @keydown.enter="login(!v$.$invalid)"
          />
          <small v-if="(v$.password.$invalid && submitted) || v$.password.$pending.$response" class="p-error">
            {{ "Bitte geben Sie ein Passwort ein." }}
          </small>
        </div>
        <div v-if="isDevelopment">
          <label for="dropdown">Zielsystem</label>
          <Dropdown id="dropdown" v-model="userData.apiTarget" :disabled="loading" :options="availableApiTargets" option-label="text" class="full-width" />
        </div>
        <div class="form-control">
          <div class="p-d-flex p-ai-center p-py-2">
            <Checkbox v-model="userData.rememberMe" input-id="rememberMe" :binary="true" />
            <label class="p-ml-2" for="rememberMe">Meine E-Mail speichern</label>
          </div>
        </div>
        <div v-if="errorIndicator" class="p-pb-4">
          <label class="p-error">{{ errorIndicator }}</label>
        </div>
        <Button :label="loginLabel" :icon="loginIcon" icon-pos="right" class="primaryButton fullWidth" :disabled="loading" @click="login(!v$.$invalid)" />
      </template>
    </Card>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, nextTick, onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import Checkbox from "primevue/checkbox";
import Card from "primevue/card";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import ProgressSpinner from "primevue/progressspinner";
import { useStore } from "vuex";
import { Credentials } from "@/store/actions";
import httpApi from "@/rest/http-api";
import erpnextApi from "@/rest/erpnext-api";
import Divider from "primevue/divider";
import { email, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default defineComponent({
  name: "LoginView",
  components: { Card, InputText, Button, Dropdown, Checkbox, Divider },
  setup() {
    const emailInputRef = ref();
    const passwordInputRef = ref();
    const submitted = ref(false);
    const errorIndicator = ref("");
    const loading = ref(false);
    const prompt = ref("Set a password");
    const router = useRouter();
    const store = useStore();
    const microsoft365LoginUrl = ref("");
    const isDevelopment = computed(() => !httpApi.isProduction);

    const loginLabel = computed(() => (loading.value ? "Einen Moment" : "Anmelden"));
    const loginIcon = computed(() => (loading.value ? "pi pi-spin pi-spinner" : "pi pi-check"));

    const userData = reactive({
      email: "",
      password: "",
      apiTarget: {} as any,
      rememberMe: false,
    });
    let rules;
    if (isDevelopment.value) rules = { email: { required }, password: { required } };
    else rules = { email: { required, email }, password: { required } };
    const v$ = useVuelidate(rules, userData);

    const focusPasswordInput = () => {
      passwordInputRef.value.$el.focus();
    };

    const focusEmailInput = () => {
      emailInputRef.value.$el.focus();
    };

    const availableApiTargets = httpApi.getAvailableApiTargets();
    onMounted(() => {
      if (localStorage.email) {
        userData.email = localStorage.email;
        userData.rememberMe = true;
      }

      let apiTarget = "test";
      if (localStorage.apiTarget) {
        apiTarget = localStorage.apiTarget;
      }
      userData.apiTarget = availableApiTargets.find((x) => x.name === apiTarget) ?? availableApiTargets.find((x) => x.name === "test");

      nextTick(() => {
        if (userData.email) {
          focusPasswordInput();
        } else {
          focusEmailInput();
        }
      });
    });

    const login = (isFormValid: boolean) => {
      submitted.value = true;
      if (!isFormValid) return;
      loading.value = true;
      if (userData.rememberMe) localStorage.email = userData.email;
      else localStorage.removeItem("email");
      if (isDevelopment.value) {
        localStorage.apiTarget = userData.apiTarget.name;
        httpApi.setApiTarget(userData.apiTarget.url);
      }

      store
        .dispatch("doLogin", {
          username: userData.email,
          password: userData.password,
        } as Credentials)
        .then((success: boolean) => {
          if (success) {
            store.dispatch("reloadUserProfile");
            errorIndicator.value = "";
            router.replace("/");
          } else {
            errorIndicator.value = "Falsche Anmeldedaten.";
            setTimeout(hideElement, 1500);
          }
        })
        .catch((error) => (errorIndicator.value = error))
        .finally(() => (loading.value = false));
    };

    function hideElement() {
      errorIndicator.value = "";
    }

    if (!isDevelopment.value) {
      erpnextApi.getMicrosoft365Login().then((x) => (microsoft365LoginUrl.value = x));
    }

    return {
      userData,
      login,
      loading,
      errorIndicator,
      prompt,
      passwordInputRef,
      emailInputRef,
      focusPasswordInput,
      availableApiTargets,
      Card,
      InputText,
      Button,
      ProgressSpinner,
      isDevelopment,
      microsoft365LoginUrl,
      loginLabel,
      loginIcon,
      v$,
      submitted,
    };
  },
});
</script>

<style scoped>
.centered {
  height: calc(100vh - 8px);
  display: flex;
}

@media screen and (min-width: 1200px) {
  .p-xl-3 {
    width: 500px;
  }
}

input {
  width: 100%;
}

.form-control {
  margin: 1rem 0;
}

label {
  font-weight: bold;
}

.fullWidth {
  width: 100%;
}
</style>

<style></style>
