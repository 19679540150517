<template>
  <Dialog v-model:visible="displayDialog" header="Kurse und Zertifikate" :dismissable-mask="true" :modal="true" :style="{ width: isMobile ? '100vw' : '50vw' }">
    <div>
      <div class="trainingContainer">
        <div class="card flex justify-content-center trainingInput">
          <span>Art des Trainings</span>
          <Dropdown
            v-model="v$.training_type.$model"
            :options="types"
            placeholder="Training auswählen"
            option-label="displayName"
            class="w-full md:w-14rem"
            :class="{ 'p-invalid': v$.training_type.$invalid && submitted }"
          />
          <small v-if="v$.training_type.$invalid && submitted" class="p-error">
            {{ v$.training_type.required.$message }}
          </small>
        </div>
        <div class="card flex justify-content-center trainingInput">
          <span>Bezeichnung</span>
          <InputText
            v-model="v$.designation.$model"
            :style="{ width: isMobile ? '80vw' : '20vw' }"
            type="text"
            :class="{ 'p-invalid': v$.designation.$invalid && submitted }"
          />
          <small v-if="v$.designation.$invalid && submitted" class="p-error">
            {{ v$.designation.required.$message }}
          </small>
        </div>
      </div>
      <div class="trainingContainer">
        <div class="card flex justify-content-center trainingInput">
          <span>Dauer des Trainings</span>
          <InputText v-model="v$.duration.$model" :class="{ 'p-invalid': v$.duration.$invalid && submitted }" />
          <small v-if="v$.duration.$invalid && submitted" class="p-error">
            {{ v$.duration.required.$message }}
          </small>
        </div>
        <div class="card flex justify-content-center trainingInput calendar">
          <span>End Datum</span>
          <Calendar v-model="v$.training_date.$model" type="text" date-format="dd.mm.yy" :class="{ 'p-invalid': v$.training_date.$invalid && submitted }" />
          <small v-if="v$.training_date.$invalid && submitted" class="p-error">
            {{ v$.training_date.required.$message }}
          </small>
        </div>
      </div>
      <div class="editButton">
        <ProgressSpinner v-if="isLoading" class="p-d-flex p-jc-center" />
        <Button v-if="trainingDataObj.name && !isLoading" @click="updateProfileTraining(!v$.$invalid)"> Aktualisieren </Button>
        <Button v-if="!trainingDataObj.name && !isLoading" @click="addProfileTraining(!v$.$invalid)"> Hinzufügen </Button>
      </div>
    </div>
  </Dialog>
</template>
<script lang="ts">
import { defineComponent, inject, onMounted, Ref, ref, watch } from "vue";
import erpnextApi from "@/rest/erpnext-api";
import Dialog from "primevue/dialog";
import { useToast } from "primevue/usetoast";
import { dialog } from "@/keys";
import { helpers, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import ProgressSpinner from "primevue/progressspinner";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import { showErrorToast, showSuccessToast } from "@/utils/Toast";
import { formatDateStringToDate, formatDateStringToERP } from "@/features/profile/utils/Profile";

interface Type {
  name: string;
  displayName: string;
}

const types: Ref<Type[]> = ref([
  { name: "certification", displayName: "Zertifizierung" },
  { name: "training", displayName: "Schulung" },
]);

export default defineComponent({
  name: "TrainingDialog",
  components: { Dialog, InputText, Calendar, ProgressSpinner, Button, Dropdown },
  props: {
    trainingData: {
      type: Object,
      required: false,
    },
  },
  emits: ["DataChanged"],
  setup(props: any, { emit }: any) {
    const trainingDataObj = ref(props.trainingData);
    const toast = useToast();
    const isLoading = ref(false);
    const displayDialog = inject(dialog, ref(false));
    const width = window.innerWidth;
    const isMobile = ref(false);
    trainingDataObj.value.training_date = ref(formatDateStringToDate(props.trainingData.training_date));
    const selectedLevel = types.value.find((type) => type.name === trainingDataObj.value.training_type);
    if (selectedLevel) {
      trainingDataObj.value.training_type = selectedLevel;
    }

    const rules = {
      name: {},
      designation: { required: helpers.withMessage("Bitte Bezeichnung angeben", required) },
      training_date: { required: helpers.withMessage("Bitte End Datum angeben", required) },
      training_type: { required: helpers.withMessage("Bitte Trainingstyp angeben", required) },
      duration: { required: helpers.withMessage("Bitte Dauer der Trainings angeben", required) },
    };

    const v$ = useVuelidate(rules, trainingDataObj);
    const submitted = ref(false);

    watch(
      () => props.trainingData[0],
      (newVal) => {
        trainingDataObj.value = newVal;
      },
    );

    onMounted(() => {
      if (width < 1400) {
        isMobile.value = true;
      }
    });

    async function updateProfileTraining(isFormValid: boolean) {
      submitted.value = true;
      if (!isFormValid) {
        showErrorToast(toast, "Bitte die Fehler in den Eingabefeldern beheben");
        return;
      }
      isLoading.value = true;
      await erpnextApi
        .updateProfileTraining(
          trainingDataObj.value.name,
          formatDateStringToERP(trainingDataObj.value.training_date),
          trainingDataObj.value.training_type.name,
          trainingDataObj.value.designation,
          trainingDataObj.value.duration,
        )
        .then(() => {
          showSuccessToast(toast, "Schulungen und Zertifikate erfolgreich aktualisiert");
          emit("DataChanged");
          displayDialog.value = false;
        })
        .catch((err: Error) => showErrorToast(toast, err.message))
        .finally(() => (isLoading.value = false));
    }

    async function addProfileTraining(isFormValid: boolean) {
      submitted.value = true;
      if (!isFormValid) {
        showErrorToast(toast, "Bitte die Fehler in den Eingabefeldern beheben");
        return;
      }
      isLoading.value = true;
      await erpnextApi
        .addProfileTraining(
          formatDateStringToERP(trainingDataObj.value.training_date),
          trainingDataObj.value.training_type.name,
          trainingDataObj.value.designation,
          trainingDataObj.value.duration,
        )
        .then(() => {
          showSuccessToast(toast, "Schulungen und Zertifikate erfolgreich hinzugefügt");
          emit("DataChanged");
          displayDialog.value = false;
          submitted.value = false;
        })
        .catch((err: Error) => showErrorToast(toast, err.message))
        .finally(() => (isLoading.value = false));
    }

    return {
      trainingDataObj,
      updateProfileTraining,
      addProfileTraining,
      isLoading,
      displayDialog,
      types,
      isMobile,
      submitted,
      v$,
    };
  },
});
</script>

<style scoped>
.trainingContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px;
  padding: 10px;
  border: 1px solid var(--greystep);
  border-radius: 5px;
  background-color: var(--greystep);
  flex-wrap: wrap;
}

.trainingInput {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

span {
  margin-bottom: 5px;
  color: var(--white);
}

.calendar {
  margin-top: 21px;
}

.editButton {
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
  margin-top: 1rem;
}

@media (max-width: 700px) {
  .trainingContainer {
    margin: 0;
    padding: 0;
  }

  .trainingInput {
    margin: 0.5rem;
  }
}
</style>
