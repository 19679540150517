<template>
  <BaseCard class="maxWidth">
    <ProfileInfo :employee="myself" />
    <div>
      <TabMenu :model="itemsAll" :active-index="activeIndex">
        <template #item="{ item, props }">
          <a v-if="item.showExternal || !isExternal" :href="item.url" :target="item.target" v-bind="props.action">
            <span v-bind="props.icon" /><span v-bind="props.label">{{ item.label }}</span>
          </a>
        </template>
      </TabMenu>
      <router-view v-slot="{ Component }">
        <transition mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
  </BaseCard>
</template>

<script lang="ts">
import { computed, defineComponent, provide, ref } from "vue";
import BaseCard from "@/ui/BaseCard.vue";
import TabMenu from "primevue/tabmenu";
import ProfileInfo from "@/features/profile/components/ProfileInfo.vue";
import { Employee } from "@/data-types";
import { loading } from "@/keys";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "ProfileView",
  components: { BaseCard, TabMenu, ProfileInfo },
  setup() {
    const store = useStore();
    const router = useRouter();
    const myself = computed((): Employee => store.getters.getEmployee);
    const isExternal = computed(() => store.getters.isExternal);

    const activeIndex = ref(
      router.currentRoute.value.path.endsWith("more")
        ? 1
        : router.currentRoute.value.path.endsWith("career")
          ? 2
          : router.currentRoute.value.path.endsWith("transportation")
            ? 3
            : router.currentRoute.value.path.endsWith("favorites")
              ? 4
              : router.currentRoute.value.path.endsWith("settings")
                ? 5
                : 0,
    );
    const isLoading = ref(true);
    provide(loading, isLoading);
    const itemsAll = [
      {
        showExternal: false,
        label: "Stammdaten",
        icon: "pi pi-fw pi-user",
        command: () => router.push("/profile/user"),
      },
      {
        showExternal: false,
        label: "Weitere",
        icon: "pi pi-fw pi-wallet",
        command: () => router.push("/profile/more"),
      },
      {
        showExternal: false,
        label: "Karriere",
        icon: "pi pi-fw pi-briefcase",
        command: () => router.push("/profile/career"),
      },
      {
        showExternal: false,
        label: "Verkehrsmittel",
        icon: "pi pi-fw pi-car",
        command: () => router.push("/profile/transportation"),
      },
      {
        showExternal: false,
        label: "Favoriten",
        icon: "pi pi-fw pi-star",
        command: () => router.push("/profile/favorites"),
      },
      {
        showExternal: true,
        label: "Einstellungen",
        icon: "pi pi-fw pi-list",
        command: () => router.push("/profile/settings"),
      },
    ];

    return {
      myself,
      isLoading,
      itemsAll,
      isExternal,
      activeIndex,
    };
  },
});
</script>

<style scoped>
.maxWidth {
  max-width: 800px;
}

.v-enter-active {
  transition: opacity 0.2s ease-in;
}

.v-leave-active {
  transition: opacity 0.1s ease-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
