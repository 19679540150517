<template>
  <Dialog v-model:visible="displayDialog" header="Zeit auf andere Buchungsposition buchen" :dismissable-mask="true" :modal="true">
    <div>Name: {{ activeBPFullName }}</div>
    <div>Stunden: {{ BPData.hours }}</div>
    <div>Kommentar: {{ BPData.comment }}</div>
    <br />
    <div>Tauschen mit:</div>
    <br />
    <div />
    <div class="card flex justify-content-center educationInput">
      <span>Buchungsposition</span>
      <!--Validation dafür funktioniert noch nicht-->
      <Dropdown v-model="selectedBp" :options="BpList" placeholder="Buchungsposition auswählen" option-label="fullname" class="w-full md:w-14rem" />
    </div>
    <Button @click="getBPforChange"> Speichern</Button>
  </Dialog>
</template>

<script lang="ts">
import { defineComponent, inject, onMounted, provide, ref } from "vue";
import { dialog } from "@/keys";
import Dialog from "primevue/dialog";

export default defineComponent({
  name: "EducationComponent",
  components: { Dialog },
  props: {
    bpData: {
      type: Object,
      required: false,
    },
    bookingPositions: {
      type: Map,
    },
  },
  emits: ["ChangeHoursOnBp"],
  setup(props: any, { emit }: any) {
    const BPData = ref(props.bpData);
    const BPosition = ref(props.bookingPositions);
    const BpList = ref();
    const selectedBp = ref();
    const activeBPFullName = ref();
    const displayDialog = inject(dialog, ref(false));
    const isOpen = ref(false);
    provide(dialog, isOpen);

    function getBPforChange() {
      const data = selectedBp.value;
      emit("ChangeHoursOnBp", { 0: data, 1: BPData.value.name });
    }

    const iterateMap = () => {
      const newArray = [];
      for (const [key, entry] of BPosition.value) {
        if (BPData.value.name != entry.name) {
          newArray.push({ fullname: entry.fullname });
          BpList.value = newArray;
        }
        if (BPData.value.name === entry.name) {
          activeBPFullName.value = entry.fullname;
        }
      }
      return newArray;
    };
    onMounted(iterateMap);

    return {
      BPData,
      displayDialog,
      activeBPFullName,
      BpList,
      selectedBp,
      getBPforChange,
    };
  },
});
</script>

<style scoped>
.educationContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--greystep);
  background-color: var(--greystep);
  border-radius: 5px;
  margin-top: 0.5rem;
}

.text {
  margin-bottom: 10px;
  font-size: 18px;
  color: var(--white);
  border-bottom: 1px solid var(--grey);
}

.editButton {
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.buttons {
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
}

.info {
  width: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--grey);
}

.educationBox {
  width: 100%;
  margin-top: 1rem;
  padding: 0.5rem;
  border: 1px solid #969696;
  border-radius: 3px;
  margin-left: 3px;
}

.educationHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.caption {
  font-size: 18px;
}
</style>
