<template>
  <div class="radio-group">
    <label for="holidayOption">Art der Abwesenheit</label>
    <div class="form-control">
      <div class="radio-option">
        <input id="vacation" v-model="selectedAbsenceType" type="radio" value="Urlaub" />
        <label for="vacation">Urlaub</label>
      </div>
      <div class="radio-option">
        <input id="sickness" v-model="selectedAbsenceType" type="radio" value="Krankheitsbedingte Abwesenheit" />
        <label for="sickness">Krankheitsbedingte Abwesenheit</label>
      </div>
    </div>
  </div>
  <CalendarInput :selected-absence-type="selectedAbsenceType" :is-loading="isLoading" :submitted="submitted" />
  <div v-if="selectedAbsenceType === 'Urlaub'">
    <div class="form-control">
      <label for="holidayOption">Urlaubstyp</label>
      <Dropdown
        v-model="selectedOption"
        :class="{ 'p-invalid': v$.option.$invalid && submitted }"
        :disabled="isLoading"
        :options="holidayOptions"
        class="full-width"
        option-label="title"
      />
      <small v-if="v$.option.$invalid && submitted" class="p-error">
        {{ v$.option.required.$message }}
      </small>
    </div>
    <div class="form-control">
      <label for="reason">Kommentar</label>
      <Textarea
        v-model="v$.reason.$model"
        :auto-resize="true"
        :class="{ 'p-invalid': v$.reason.$invalid && submitted }"
        :disabled="isLoading"
        class="full-width"
      />
      <small v-if="v$.reason.$invalid && submitted" class="p-error">
        {{ v$.reason.maxLengthValue.$message }}
      </small>
    </div>
    <div>
      <div class="checkboxdiv">
        <div>
          <Checkbox v-model="teamChecked" :binary="true" input-id="AckTeam" name="AckTeam" />
        </div>

        <label class="holiday-cb-label" for="AckTeam">Ich habe den Urlaub mit dem Projekt(team) und anderen relevanten Personen abgestimmt</label>
      </div>
    </div>
    <div class="p-d-flex p-jc-center p-mt-4">
      <Button
        :disabled="isLoading || !teamChecked.valueOf()"
        :icon="isLoading ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
        :label="isLoading ? 'Einen Moment' : 'Absenden'"
        class="primaryButton button-placeholder-width"
        icon-pos="right"
        @click="submitForm(!v$.$invalid)"
      />
    </div>
    <div class="p-d-flex p-jc-center">
      <p class="color-grey">Status wechselt spätestens nach 48 Stunden automatisch auf eingetragen.</p>
    </div>
  </div>

  <div v-else>
    <div class="form-control">
      <label for="reason">Kommentar</label>
      <Textarea
        v-model="illnessv$.reason.$model"
        :auto-resize="true"
        :class="{ 'p-invalid': illnessv$.reason.$invalid && submitted }"
        :disabled="isLoading"
        class="full-width"
      />
      <small v-if="illnessv$.reason.$invalid && submitted" class="p-error">
        {{ illnessv$.reason.maxLengthValue.$message }}
      </small>
    </div>
    <div class="form-control">
      <label for="file">Bescheinigung</label>
      <div class="file-upload">
        <Textarea v-model="fileSelected" :readonly="'true'" :auto-resize="true" class="height"></Textarea>
        <FileUpload
          :custom-upload="true"
          :disabled="fileUploading || isLoading"
          :max-file-size="1000000"
          :auto="true"
          accept=".pdf"
          choose-label="PDF anhängen "
          mode="basic"
          @select="onFileSelect"
        />
        <Button v-if="fileSelected" type="button" class="p-button-text p-button-danger" @click="clearFileSelect">
          <i class="pi pi-trash"></i>
        </Button>
      </div>
    </div>
    <div class="p-d-flex p-jc-center">
      <Button
        :disabled="isLoading"
        :icon="isLoading ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
        :label="isLoading ? 'Einen Moment' : 'Absenden'"
        class="primaryButton button-placeholder-width"
        icon-pos="right"
        @click="submitIllnessForm(!illnessv$.$invalid)"
      />
    </div>
    <div class="p-d-flex p-jc-center">
      <p class="color-grey">Status wechselt nach 48 Stunden automatisch auf eingetragen.</p>
    </div>
  </div>
  <BasePopUp v-if="openPopUp" :pop-up-message="popUpMessage">
    <Button @click="hidePopUp()">Okay</Button>
  </BasePopUp>
</template>

<script lang="ts">
import { Employee, fileUploadResponse } from "@/data-types";
import { calculateDurationBetweenDates } from "@/features/absence/utils/Absence";
import { validate } from "@/keys";
import erpnextApi from "@/rest/erpnext-api";
import BasePopUp from "@/ui/BasePopUp.vue";
import CalendarInput from "@/ui/CalendarInput.vue";
import { formatDateToERP } from "@/utils/Helper";
import { showErrorToast, showSuccessToast } from "@/utils/Toast";
import { computed, defineComponent, nextTick, onMounted, PropType, provide, reactive, ref, watch } from "vue";
import useVuelidate from "@vuelidate/core";
import { helpers, maxLength, required } from "@vuelidate/validators";
import moment from "moment";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import Dropdown from "primevue/dropdown";
import FileUpload, { FileUploadSelectEvent } from "primevue/fileupload";
import Textarea from "primevue/textarea";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";

export default defineComponent({
  name: "HolidayForm",
  components: { CalendarInput, Button, Dropdown, Textarea, Checkbox, BasePopUp, FileUpload },
  props: {
    employee: {
      type: Object as PropType<Employee | null>,
      default: null,
    },
  },
  emits: ["submitted"],
  setup(props, ctx) {
    const store = useStore();
    const toast = useToast();
    store.dispatch("fetchHolidayOptions").then(() => (isLoading.value = false));
    const holidayOptions = computed(() => store.getters.getHolidayOptions);
    const isLoading = ref(true);
    const teamChecked = ref(false);
    const openPopUp = ref(false);
    const popUpMessage = "Die Buchung wurde nur bis zum Ende des Jahres durchgeführt. Bitte die Buchung für das neue Jahr seperat buchen";
    const selectedOption = ref();
    const holidayDate = reactive({
      dateRange: [],
      reason: "",
      option: holidayOptions.value[0],
    });
    const rules = {
      dateRange: { required: helpers.withMessage("Bitte einen Zeitraum angeben", required) },
      reason: { maxLengthValue: helpers.withMessage("Bitte nutzen Sie maximum 100 Zeichen.", maxLength(100)) },
      option: { required: helpers.withMessage("Bitte einen Urlaubstyp angeben", required) },
    };
    const submitted = ref(false);
    const v$ = useVuelidate(rules, holidayDate);
    provide(validate, v$);

    const selectedAbsenceType = ref(localStorage.getItem("selectedAbsenceType") || "Urlaub");
    let file: File;
    const fileSelected = ref("");
    const files = ref<fileUploadResponse[]>([]);
    const fileUploaded = ref(false);
    const fileUploading = ref(false);
    const sickDayName = ref("");
    const appliedSickDayRange = ref("");
    const employeeName = props.employee?.first_name + "-" + props.employee?.last_name;

    const illness = reactive({
      dateRange: [] as (Date | undefined)[],
      reason: "",
    });

    const illnessRules = {
      dateRange: { required: helpers.withMessage("Bitte einen Zeitraum angeben", required) },
      reason: { maxLengthValue: helpers.withMessage("Bitte nutzen Sie maximum 100 Zeichen.", maxLength(100)) },
    };

    const illnessv$ = useVuelidate(illnessRules, illness);
    provide("illnessValidate", illnessv$);

    watch(
      illness,
      (newValue) => {
        localStorage.setItem("illnessReason", newValue.reason);
      },
      { deep: true },
    );

    //Watches if the value of dateRange changes and updates it in the localStorage
    watch(
      illnessv$,
      (newValue) => {
        const dateRange = {
          start: newValue.dateRange.$model[0],
          end: newValue.dateRange.$model[1],
        };
        if (dateRange.start && dateRange.end) {
          localStorage.setItem("illnessDateRange", JSON.stringify(dateRange));
        }
      },
      { deep: true },
    );
    //Gets the value for reason from localStorage and displays it
    onMounted(() => {
      const illnessReason = localStorage.getItem("illnessReason");
      if (illnessReason) {
        illness.reason = illnessReason;
      }
    });
    //Gets the value for dateRange from localStorage and displays it
    onMounted(() => {
      const storedDateRange = localStorage.getItem("illnessDateRange");
      if (storedDateRange) {
        const dateRangeVal = JSON.parse(storedDateRange);
        dateRangeVal.$model = [new Date(dateRangeVal.start), new Date(dateRangeVal.end)];
        illness.dateRange = dateRangeVal.$model;
      }
    });

    onMounted(async () => {
      await store.dispatch("fetchHolidayOptions", { forceFetch: false });
      if (holidayOptions.value.length > 0) {
        selectedOption.value = holidayOptions.value[0];
      }
      isLoading.value = false;
    });

    watch(
      () => holidayDate.dateRange,
      async () => {
        await store.dispatch("fetchHolidayOptions", { forceFetch: true });
      },
      { deep: true },
    );

    //Watches if the value of  changes and updates it in the localStorage
    watch(
      holidayDate,
      (newValue) => {
        localStorage.setItem("holidayReason", newValue.reason);
      },
      { deep: true },
    );

    watch(
      holidayOptions,
      (newOptions) => {
        if (newOptions && newOptions.length > 0) {
          selectedOption.value = { ...newOptions[0] }; // Create a new object to trigger reactivity
        }
      },
      { immediate: true },
    );

    //Watches if the value of Zeitraum changes and updates it in the localStorage
    watch(
      v$,
      (newValue) => {
        const dateRange = {
          start: newValue.dateRange.$model[0],
          end: newValue.dateRange.$model[1],
        };
        if (dateRange.start && dateRange.end) {
          localStorage.setItem("holidayDateRange", JSON.stringify(dateRange));
        }
      },
      { deep: true },
    );

    //Gets the value for Kommentar from localStorage and displays it
    onMounted(() => {
      const holidayReason = localStorage.getItem("holidayReason");
      if (holidayReason) {
        holidayDate.reason = holidayReason;
      }
    });

    //Gets the value for Zeitraum from localStorage and displays it
    onMounted(() => {
      const storedDateRange = localStorage.getItem("holidayDateRange");
      if (storedDateRange) {
        const dateRangeVal = JSON.parse(storedDateRange);
        dateRangeVal.$model = [new Date(dateRangeVal.start), new Date(dateRangeVal.end)];
        holidayDate.dateRange = dateRangeVal.$model;
      }
    });

    watch(selectedAbsenceType, (newValue) => {
      localStorage.setItem("selectedAbsenceType", newValue);
    });

    onMounted(() => {
      const storedAbsenceType = localStorage.getItem("selectedAbsenceType");
      if (storedAbsenceType) {
        selectedAbsenceType.value = storedAbsenceType;
      }
    });

    function selectFirstHolidayOption() {
      holidayDate.option = holidayOptions.value[0];
    }

    watch(holidayOptions, () => {
      selectFirstHolidayOption();
      isLoading.value = false;
    });

    function resetValues() {
      holidayDate.dateRange = [];
      holidayDate.reason = "";
      selectFirstHolidayOption();
    }

    function hidePopUp() {
      openPopUp.value = false;
    }

    function submitForm(isFormValid: boolean) {
      submitted.value = true;
      if (!isFormValid) {
        return;
      }

      isLoading.value = true;
      if (!holidayDate.dateRange || !holidayDate.dateRange[0]) return;
      const startDate = new Date(holidayDate.dateRange[0]);
      let endDate = holidayDate.dateRange[1] ? new Date(holidayDate.dateRange[1]) : new Date(startDate);
      if (startDate.getFullYear() !== endDate.getFullYear()) {
        openPopUp.value = true;
        endDate = new Date(startDate.getFullYear(), 11, 31);
      }
      erpnextApi
        .postHolidayRequest(formatDateToERP(startDate), calculateDurationBetweenDates(startDate, endDate), selectedOption.value.name, holidayDate.reason)
        .then(() => {
          clearTemporaryValuesFromLocalStorage();
          showSuccessToast(toast, "Urlaub erfolgreich eingereicht");
          resetValues();
          ctx.emit("submitted");
          submitted.value = false;
          store.dispatch("fetchHolidayOptions", { forceFetch: true }).then(() => {
            nextTick(() => {
              selectedOption.value = holidayOptions.value[0];
            });
          });
        })
        .catch((err: Error) => showErrorToast(toast, err.message))
        .finally(() => (isLoading.value = false));
      teamChecked.value = false;
    }

    function submitIllnessForm(isFormValid: boolean) {
      submitted.value = true;
      if (!isFormValid) {
        return;
      }
      isLoading.value = true;

      if (!illness.dateRange || !illness.dateRange[0]) return;
      const startDate = new Date(illness.dateRange[0]);
      let endDate = new Date(illness.dateRange[1] ?? startDate);
      if (startDate.getFullYear() !== endDate.getFullYear()) {
        openPopUp.value = true;
        endDate = new Date(startDate.getFullYear(), 11, 31);
      }
      illness.dateRange &&
        erpnextApi
          .postHolidayRequest(
            formatDateToERP(illness.dateRange[0]),
            calculateDurationBetweenDates(illness.dateRange[0], endDate),
            "Krankheitsbedingte Abwesenheit",
            illness.reason,
          )
          .then((name: any) => {
            appliedSickDayRange.value =
              "( " + moment(illness.dateRange[0]).format("DD.MM.YYYY") + " - " + moment(illness.dateRange[1]).format("DD.MM.YYYY") + " )";
            clearTemporaryIllnessValuesFromLocalStorage();
            resetIllnessValues();
            ctx.emit("submitted");
            submitted.value = false;
            sickDayName.value = name;
            if (fileSelected.value) {
              attachReport();
            }
            showSuccessToast(toast, "Krankmeldung erfolgreich eingereicht");
          })
          .catch((err: Error) => showErrorToast(toast, err.message))
          .finally(() => (isLoading.value = false));
    }

    function resetIllnessValues() {
      illness.dateRange = [];
      illness.reason = "";
    }

    function clearTemporaryIllnessValuesFromLocalStorage() {
      localStorage.removeItem("illnessReason");
      localStorage.removeItem("illnessDateRange");
    }

    function clearTemporaryValuesFromLocalStorage() {
      localStorage.removeItem("holidayReason");
      localStorage.removeItem("holidayDateRange");
    }

    function onFileSelect(event: FileUploadSelectEvent) {
      if (Array.isArray(event.files)) {
        file = event.files[0];
        fileSelected.value = file.name;
      }
    }

    function clearFileSelect() {
      fileSelected.value = "";
    }

    async function attachReport() {
      if (sickDayName.value === "") return;
      fileUploading.value = true;
      const startDateString = appliedSickDayRange.value.replace(/[()]/g, "").replace(/\s/g, "").slice(0, 10);

      const startDate = moment(startDateString, "DD.MM.YYYY").format("YYYYMMDD");
      const formData = new FormData();
      formData.append("file", file, "Krankmeldung_" + startDate + "_" + employeeName + ".pdf");
      formData.append("doctype", "Leave Application");
      formData.append("docname", sickDayName.value);
      formData.append("is_private", "1");
      erpnextApi
        .postIllnessFile(formData)
        .then((response: fileUploadResponse) => {
          showSuccessToast(toast, "Datei erfolgreich hochgeladen.");
          files.value.push(response);
          fileUploaded.value = true;
          appliedSickDayRange.value = "";
        })
        .catch((err: Error) => {
          showErrorToast(toast, err.message);
          fileUploaded.value = false;
        })
        .finally(() => {
          fileUploading.value = false;
          clearFileSelect();
        });
    }

    return {
      holidayOptions,
      submitForm,
      isLoading,
      submitted,
      v$,
      teamChecked,
      popUpMessage,
      hidePopUp,
      openPopUp,
      selectedOption,
      illnessv$,
      selectedAbsenceType,
      onFileSelect,
      clearFileSelect,
      sickDayName,
      illness,
      appliedSickDayRange,
      fileUploaded,
      fileUploading,
      submitIllnessForm,
      fileSelected,
    };
  },
});
</script>

<style scoped>
h2 {
  text-align: center;
}

.form-control {
  margin: 1rem 0;
}

label {
  font-weight: bold;
  display: block;
  width: 100%;
  margin-bottom: 0.25rem;
}

select {
  background: transparent;
  color: var(--white);
  cursor: pointer;
}

.checkboxdiv {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.holiday-cb-label {
  display: table-cell;
  padding-left: 0.7rem;
  user-select: none;
  font-weight: normal;
}

.radio-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem; /* Adjust this value to increase or decrease the space */
}

.radio-option {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.radio-option input[type="radio"] {
  margin-right: 0.5rem;
}

.file-upload {
  display: flex;
  align-items: center;
  max-width: 100%;
}

.height {
  margin-right: 1rem;
  max-height: 2.4rem;
  flex: 1;
  white-space: nowrap;
}
</style>
