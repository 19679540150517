<template>
  <BaseCard class="maxWidth">
    <YearControl class="p-mb-3" title="Jahresübersicht" :disable-previous-year="isLoading" :disable-next-year="isLoading" />
    <ProgressSpinner v-if="isLoading" class="full-width p-text-center" />
    <span v-else>
      <TabMenu :active-index="activeIndex" :model="items" />
      <router-view v-slot="{ Component }">
        <transition mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </span>
  </BaseCard>
</template>
<script setup lang="ts">
import { computed, provide, readonly, ref, watch } from "vue";
import BaseCard from "@/ui/BaseCard.vue";
import YearControl from "@/ui/YearControl.vue";
import erpnextApi from "@/rest/erpnext-api";
import { AnnualStatement, emptyAnnualStatement, emptyYearReport, YearReport } from "@/data-types";
import moment from "moment";
import { useToast } from "primevue/usetoast";
import { annualStatementKey, date, yearReportKey } from "@/keys";
import TabMenu from "primevue/tabmenu";
import { RouterView, useRouter } from "vue-router";
import ProgressSpinner from "primevue/progressspinner";
import { showErrorToast } from "@/utils/Toast";

interface Description {
  [name: string]: {
    project: string;
    description: string;
  };
}

const props = defineProps({ year: { type: String, default: new Date().getFullYear().toString() } });

moment.locale("de");
const toast = useToast();
const router = useRouter();
const activeIndex = ref(router.currentRoute.value.path.endsWith("chart") ? 1 : router.currentRoute.value.path.endsWith("statement") ? 2 : 0);
const currentDate = ref(new Date(parseInt(props.year), 0, 1));
const currentYear = computed(() => currentDate.value.getFullYear());
provide(date, currentDate);
const isLoading = ref(true);
const yearReport = ref<YearReport>(emptyYearReport);
provide(yearReportKey, {
  yearReport: readonly(yearReport),
  addBookingPosition: (sum) => yearReport.value.booking_positions.push(sum),
});
const annualStatement = ref<AnnualStatement>(emptyAnnualStatement);
provide(annualStatementKey, annualStatement);
const items = computed(() => [
  { label: "Tabelle", icon: "pi pi-fw pi-table", command: () => router.push("/yearly/" + currentYear.value) },
  {
    label: "Diagramm",
    icon: "pi pi-fw pi-chart-bar",
    command: () => router.push("/yearly/" + currentYear.value + "/chart"),
  },
  {
    label: "Jahresabrechnung",
    icon: "pi pi-fw pi-file",
    command: () => router.push("/yearly/" + currentYear.value + "/statement"),
  },
]);

Promise.all([loadData(), loadAnnualStatement()]).finally(() => (isLoading.value = false));
watch(currentDate, () => {
  router.replace({ params: { year: currentYear.value } });
  isLoading.value = true;
  Promise.all([loadData(), loadAnnualStatement()]).finally(() => (isLoading.value = false));
});

async function loadData() {
  // The booking position "Krankheitsbedingte Abwesenheit" has no project key, so it must be
  // filtered out before sending to the backend. Else you will get a 404.
  const absence = "Krankheitsbedingte Abwesenheit";
  try {
    yearReport.value = await erpnextApi.getYearlyStatistics(currentYear.value);
    if (yearReport.value.booking_positions.length) {
      const descriptions = await loadMissingBookingDescriptions(
        yearReport.value.booking_positions.filter((pos) => pos.name !== absence).map(({ name }) => name),
      );
      yearReport.value.booking_positions = yearReport.value.booking_positions.map((position) => ({
        ...position,
        project: position.name !== absence ? descriptions[position.name].project : "",
        name: position.name !== absence ? descriptions[position.name].description : position.name,
      }));
    }
  } catch {
    showErrorToast(toast, "Jahresübersicht kann nicht geladen werden");
  }
}

async function loadAnnualStatement() {
  try {
    annualStatement.value = await erpnextApi.getAnnualStatement(currentYear.value);
  } catch {
    annualStatement.value = emptyAnnualStatement;
  }
}

async function loadMissingBookingDescriptions(names: string[]): Promise<Description> {
  const descriptions = await erpnextApi.getBookingPositionDescriptions(names);
  return descriptions.reduce(
    (acc, { name, project, description }) => ({
      ...acc,
      [name]: { project, description },
    }),
    {},
  );
}
</script>

<style scoped></style>
