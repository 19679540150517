<template>
  <Avatar class="employee-avatar" :size="avatarSize" :style="{ background, color }" :label="employee.abbreviation" />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import Avatar from "primevue/avatar";
import { Employee } from "@/data-types";
import { foregroundForBackground } from "@/utils/UIUtils";
import { colorFromString } from "@/utils/ColorFromString";

export default defineComponent({
  name: "EmployeeIcon",
  components: { Avatar },
  props: {
    employee: { type: Object as PropType<Employee>, required: true },
    avatarSize: { type: String, required: false, default: "" },
  },
  setup(props) {
    const employeeName = props.employee.first_name + props.employee.name + props.employee.last_name;
    const background = colorFromString(employeeName);
    const color = foregroundForBackground(background);
    return { color, background };
  },
});
</script>

<style scoped></style>
