<template>
  <div class="custom-config-dialog">
    <ConfirmDialog :unstyled="false" :group="confirmType"></ConfirmDialog>
  </div>
  <div v-for="(value, key) in values" :key="key" class="row p-py-2 p-px-1 p-py-md-3 p-px-md-2">
    <div class="p-button-rounded p-d-flex p-ai-center p-jc-between">
      <div style="font-size: 1.1rem" class="p-text-bold">
        {{ value.leave_type }}{{ value.total_leave_days > 0 ? ` / ${value.total_leave_days} Tag(e)` : "" }}
      </div>
      <Button v-if="value.can_delete" type="button" class="p-button-text p-button-danger" :loading="isLoading" @click="handleDelete(value.name!, confirmType)">
        <i class="pi pi-trash"></i>
      </Button>
    </div>
    <div class="p-button-rounded p-pt-1 p-d-flex p-ai-center p-jc-between">
      <div>{{ dateFormatter(value.from_date) }} - {{ dateFormatter(value.to_date) }}</div>
      <div>
        <Badge :severity="statusColor(value.status)">{{ statusText(value.status) }}</Badge>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from "vue";
import { HolidayRequest } from "@/data-types";
import { formatDateStringToGerman } from "@/utils/Helper";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";

import erpnextApi from "@/rest/erpnext-api";
import Button from "primevue/button";
import Badge from "primevue/badge";
import ConfirmDialog from "primevue/confirmdialog";
import { showErrorToast, showSuccessToast } from "@/utils/Toast";

export default defineComponent({
  name: "TableRow",
  components: {
    Button,
    Badge,
    ConfirmDialog,
  },
  props: {
    values: { type: Array as PropType<HolidayRequest[]>, default: () => [] },
    confirmType: { type: String, required: true },
  },
  emits: ["holidayDeleted"],
  setup(_, ctx) {
    const confirm = useConfirm();
    const toast = useToast();

    function handleDelete(name: string, group: string) {
      confirm.require({
        header: "Löschen",
        message: "Bist du sicher?",
        icon: "pi pi-exclamation-triangle",
        group: group,
        accept: () => {
          deleteApplication(name);
        },
        reject: () => {
          return;
        },
      });
    }

    const isLoading = ref(false);

    function statusColor(status: string): "info" | "success" | "warning" | "danger" | undefined {
      if (status === "Approved") return "success";
      else if (status === "Open") return "warning";
      return undefined;
    }

    function statusText(status: string): string {
      if (status === "Approved") return "Eingetragen";
      else if (status === "Open") return "Offen";
      return "";
    }

    function dateFormatter(date: string) {
      return formatDateStringToGerman(date);
    }

    function deleteApplication(name: string) {
      isLoading.value = true;
      erpnextApi
        .deleteLeaveApplication(name)
        .then(() => {
          showSuccessToast(toast, "Antrag erfolgreich gelöscht.");
          ctx.emit("holidayDeleted");
          isLoading.value = false;
        })
        .catch((err) => {
          showErrorToast(toast, err);
          isLoading.value = false;
        });
    }

    return { statusColor, statusText, dateFormatter, isLoading, handleDelete };
  },
});
</script>

<style scoped>
.row {
  border-bottom: 1px solid var(--borderColor);
}

.row:last-child {
  border-bottom: 0;
}
</style>
