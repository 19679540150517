<template>
  <Dialog v-model:visible="displayDialog" header="Skills" :dismissable-mask="true" :modal="true" :style="{ width: isMobile ? '100vw' : '50vw' }">
    <div>
      <div class="dialogContainer">
        <div class="card flex justify-content-center skillInput">
          <span>Skill</span>
          <Dropdown
            v-model="v$.skill.$model"
            :options="skillListData"
            placeholder="Level auswählen"
            option-label="name"
            :style="{ width: isMobile ? '75vw' : '25vw' }"
            class="w-full md:w-14rem"
            :class="{ 'p-invalid': v$.skill.$invalid && submitted }"
          />
          <small v-if="v$.skill.$invalid && submitted" class="p-error">
            {{ v$.skill.required.$message }}
          </small>
        </div>
        <div class="card flex justify-content-center skillInput">
          <span>Erfahrung</span>
          <Dropdown
            v-model="v$.proficiency.$model"
            :options="proficiencys"
            placeholder="Level auswählen"
            option-label="displayName"
            :style="{ width: isMobile ? '75vw' : '25vw' }"
            class="w-full md:w-14rem"
            :class="{ 'p-invalid': v$.proficiency.$invalid && submitted }"
          />
          <small v-if="v$.proficiency.$invalid && submitted" class="p-error">
            {{ v$.proficiency.required.$message }}
          </small>
        </div>
      </div>
      <div class="editButton">
        <ProgressSpinner v-if="isLoading" class="p-d-flex p-jc-center" />
        <Button v-if="skillDataObj.name && !isLoading" @click="updateProfileSkill(!v$.$invalid)"> Aktualisieren</Button>
        <Button v-if="!skillDataObj.name && !isLoading" @click="addProfileSkill(!v$.$invalid)"> Hinzufügen</Button>
      </div>
    </div>
  </Dialog>
</template>
<script lang="ts">
import { defineComponent, inject, onMounted, Ref, ref } from "vue";
import erpnextApi from "@/rest/erpnext-api";
import Dialog from "primevue/dialog";
import { useToast } from "primevue/usetoast";
import { dialog } from "@/keys";
import { formatDateToERP } from "@/utils/Helper";
import { helpers, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import Dropdown from "primevue/dropdown";
import ProgressSpinner from "primevue/progressspinner";
import Button from "primevue/button";
import { showErrorToast, showSuccessToast } from "@/utils/Toast";

interface Proficiency {
  proficiency: number;
  displayName: string;
}

const proficiencys: Ref<Proficiency[]> = ref([
  { proficiency: 0, displayName: "Wenig Erfahrung" },
  { proficiency: 1, displayName: "<1 Jahr Professionell" },
  { proficiency: 2, displayName: "1-2 Jahre Professionell" },
  { proficiency: 3, displayName: "3-4 Jahre Professionell" },
  { proficiency: 4, displayName: "5-10 Jahre Professionell" },
  { proficiency: 5, displayName: "<10 Jahre Professionell" },
]);

export default defineComponent({
  name: "SkillDialog",
  components: { Dialog, Dropdown, ProgressSpinner, Button },
  props: {
    skillData: {
      type: Object,
      required: true,
    },
    skillList: {
      type: Object,
      required: false,
    },
  },
  emits: ["DataChanged"],
  setup(props: any, { emit }: any) {
    const skillDataObj = ref(props.skillData);
    const toast = useToast();
    const isLoading = ref(false);
    const displayDialog = inject(dialog, ref(false));
    const width = window.innerWidth;
    const isMobile = ref(false);
    const skillListData = ref(props.skillList);
    const todayDate = new Date();
    const selectedProficiency = proficiencys.value.find((level) => level.proficiency === skillDataObj.value.proficiency);
    if (selectedProficiency) {
      skillDataObj.value.proficiency = selectedProficiency;
    }
    const selectedSkill = skillListData.value.find((skill: any) => skill.name === skillDataObj.value.skill);
    if (selectedSkill) {
      skillDataObj.value.skill = selectedSkill;
    }

    const rules = {
      name: {},
      skill: { required: helpers.withMessage("Bitte Uni angeben", required) },
      proficiency: { required: helpers.withMessage("Bitte Abschluss angeben", required) },
    };

    const v$ = useVuelidate(rules, skillDataObj);
    const submitted = ref(false);

    onMounted(() => {
      if (width < 1400) {
        isMobile.value = true;
      }
    });

    async function updateProfileSkill(isFormValid: boolean) {
      submitted.value = true;
      if (!isFormValid) {
        showErrorToast(toast, "Bitte die Fehler in den Eingabefeldern beheben");
        return;
      }
      isLoading.value = true;
      await erpnextApi
        .updateProfileSkill(skillDataObj.value.name, skillDataObj.value.skill.name, skillDataObj.value.proficiency.proficiency, formatDateToERP(todayDate))
        .then(() => {
          showSuccessToast(toast, "Ausbildung erfolgreich aktualisiert");
          emit("DataChanged");
          displayDialog.value = false;
        })
        .catch((err: Error) => showErrorToast(toast, err.message))
        .finally(() => (isLoading.value = false));
    }

    async function addProfileSkill(isFormValid: boolean) {
      submitted.value = true;
      if (!isFormValid) {
        showErrorToast(toast, "Bitte die Fehler in den Eingabefeldern beheben");
        return;
      }
      isLoading.value = true;
      await erpnextApi
        .addProfileSkill(skillDataObj.value.skill.name, skillDataObj.value.proficiency.proficiency, formatDateToERP(todayDate))
        .then(() => {
          showSuccessToast(toast, "Ausbildung erfolgreich hinzugefügt");
          emit("DataChanged");
          displayDialog.value = false;
          submitted.value = false;
        })
        .catch((err: Error) => showErrorToast(toast, err.message))
        .finally(() => (isLoading.value = false));
    }

    return {
      skillDataObj,
      updateProfileSkill,
      addProfileSkill,
      isLoading,
      displayDialog,
      proficiencys,
      isMobile,
      skillListData,
      submitted,
      v$,
    };
  },
});
</script>

<style scoped>
.dialogContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px;
  padding: 10px;
  border: 1px solid var(--greystep);
  border-radius: 5px;
  background-color: var(--greystep);
  flex-wrap: wrap;
}

.skillInput {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

span {
  margin-bottom: 5px;
  color: var(--white);
}

.editButton {
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
  margin-top: 1rem;
}

@media (max-width: 700px) {
  .skillContainer {
    margin: 0;
    padding: 0;
  }

  .skillInput {
    margin: 0.5rem;
  }
}
</style>
